import React from 'react';
import img1 from '../../../assets/frontendassets/first-img1.png';
import img2 from '../../../assets/frontendassets/second-img2.png';
import img3 from '../../../assets/frontendassets/third-img3.png';

const WebsiteSeo = () => (
  <div className="flex flex-col items-center text-center py-8 px-4">
    <header className="mb-8">
      <h1 className="text-3xl font-bold">
        Get your <span className="text-yellow">Business Website with SEO</span> in just 5 minutes
      </h1>
      <p className="mt-2 text-gray-600">
        With Net4Surge easily connect all aspects of your online content through a beautifully created website.
      </p>
    </header>
    <div className="flex flex-col md:flex-row justify-center items-stretch gap-6">
      <div className="feature bg-yellow-200 p-6 rounded-lg shadow-lg flex-1 max-w-xs flex-grow">
        <img src={img1} alt="Website of your own" className="mb-4 mx-auto h-32 object-contain" />
        <h3 className="text-xl font-bold">Website of your own</h3>
        <p className="mt-2 text-gray-600">
          Get the perfect online home for your courses, digital products, webinars on onboarding.
        </p>
      </div>
      <div className="feature bg-yellow-200 p-6 rounded-lg shadow-lg flex-1 max-w-xs flex-grow">
        <img src={img2} alt="Customise based on your brand" className="mb-4 mx-auto h-32 object-contain" />
        <h3 className="text-xl font-bold">Customise based on your brand</h3>
        <p className="mt-2 text-gray-600">
          Personalize your website without any technical or design skills with our no-code editor.
        </p>
      </div>
      <div className="feature bg-yellow-200 p-6 rounded-lg shadow-lg flex-1 max-w-xs flex-grow">
        <img src={img3} alt="Your online digital store" className="mb-4 mx-auto h-32 object-contain" />
        <h3 className="text-xl font-bold">Your online digital store</h3>
        <p className="mt-2 text-gray-600">
          Focus on content, while we consolidate your online business under one website.
        </p>
      </div>
    </div>
  </div> 
);

export default WebsiteSeo;
