import React, { useEffect, useState } from "react";
import {  Carousel, Col, Form, Input, message, Modal, Row } from "antd";
import {  FaStar, FaStarHalf } from "react-icons/fa";
import { newsletter, testimonial, Testimonials } from "../../../utils/API";
import image1 from "../../../assets/frontendassets/Review/1.jpg";
import image2 from "../../../assets/frontendassets/Review/2.jpg";



const Reviews = () => {
  const [slidesToShow, setSlidesToShow] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedReview, setSelectedReview] = useState<any>(null);
  const [reviews, setReviews] = useState<any>([]);
  const imageUrl = process.env.REACT_APP_IMAGE_URL + "/testimonial/";
  const [form] = Form.useForm();
  useEffect(() => {
    const handleResize = () => {
      // Update slidesToShow based on window width
      if (window.innerWidth > 1200) {
        setSlidesToShow(2);
      } else if (window.innerWidth <= 1170 && window.innerWidth >= 1060) {
        setSlidesToShow(1);
      } else if (window.innerWidth >= 768) {
        setSlidesToShow(1);
      } else {
        setSlidesToShow(1);
      }
    };
    window.addEventListener('resize', handleResize);

    // Call handleResize once to set initial state
    handleResize();

    // Cleanup function to remove event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchTestimonialData = () => {
    Testimonials().then((res) => {
      setReviews(res.data);
    });
  };

  useEffect(() => {
    fetchTestimonialData();
  }, []);

  const renderStars = (rating : any) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;
    return (
      <div className="flex justify-content-center pt-3">
        {[...Array(fullStars)].map((_, index) => (
          <FaStar key={index} size={15} className="pr-0.5 text-yellow" />
        ))}
        {halfStar && <FaStarHalf size={15} className="pr-0.5 text-yellow" />}
      </div>
    );
  };

  const showModal = (review : any) => {
    setSelectedReview(review);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedReview(null);
  };

  const handleSubscribe = async (values: any) => {
    setIsSubmitting(true);
    try {
      const response = await newsletter(values);
      if (response.status === 200) {
        form.resetFields();
        message.success("Subscribed successfully!");
        
      }
    } catch (error) {
      message.error("Subscription failed. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
        <Modal
        title={selectedReview ? (
          <div className="font-bold">
{          selectedReview.name }
          </div>
        ) : null}
        visible={isModalVisible}
        
        onCancel={handleCancel}
        footer={null}
      >
        {selectedReview && (
          <div>
            <Row gutter={16}>
              {/* <Col span={6}>
                <img
                  src={imageUrl + selectedReview.image}
                  className="mx-auto border-2 p-1 border-orange-500 w-16 h-16 rounded-full"
                  alt="Profile"
                />
              </Col> */}
              <Col span={24}>
                {/* <p className="font-extrabold" style={{ fontSize: "20px" }}>
                  {selectedReview.name}
                </p> */}
                <p className="text-gray-500 pt-2" style={{ fontSize: "16px" , textAlign: "center" }}>
                  {selectedReview.description}
                </p>
              </Col>
            </Row>
          </div>
        )}
      </Modal>
      <div
        style={{
          backgroundImage:
            "url('https://gaviaspreview.com/wp/lestin/wp-content/uploads/2023/08/bg-10.png')",
          backgroundColor: "#f5f5f5",
          height: "auto",
          width: "100%",
        }}
      >
        <Row className="md:pt-8 md:ps-8 md:px-8 xl:pt-12 xl:ps-48 xl:px-12 p-6 sm:pt-8 sm:ps-8 sm:px-8">
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={10} className="md:pt-8 md:ps-8 md:px-8 sm:pt-8 sm:ps-8 sm:px-8">
            <p
              className="font-bold text-md tracking-widest mb-3 text-yellow"
            >
              OUR FEEDBACKS
            </p>
            <p className="text-5xl font-extrabold tracking-tight">
              What they’re say about us
            </p>
            <div className="d-flex pt-4">
              <div
                className="inline-block p-2 border-2 bg-yellow-700"
              >
                <FaStar
                  size={20}
                  style={{ color: "#ffffff" }}
                  className="text-white"
                />
              </div>
              <div
                className="inline-block p-2 border-2 bg-yellow-700"
              >
                <FaStar
                  size={20}
                  style={{ color: "#ffffff" }}
                  className="text-white"
                />
              </div>{" "}
              <div
                className="inline-block p-2 border-2 bg-yellow-700"
              >
                <FaStar
                  size={20}
                  style={{ color: "#ffffff" }}
                  className="text-white"
                />
              </div>{" "}
              <div
                className="inline-block p-2 border-2 bg-yellow-700"
              >
                <FaStar
                  size={20}
                  style={{ color: "#ffffff" }}
                  className="text-white"
                />
              </div>{" "}
              <div
                className="inline-block p-2 border-2 bg-yellow-700"
              >
                <FaStar
                  size={20}
                  style={{ color: "#ffffff" }}
                  className="text-white"
                />
              </div>
            </div>
            <div className="font-bold text-gray-500 pt-4 " style={{ fontSize: "14px" }}>
              Trust score 4.5 (Based on 2,500 reviews)
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={16} xxl={14} className="md:pl-12 md:pb-12">
            <Carousel autoplay autoplaySpeed={3000} slidesToShow={slidesToShow} draggable dots={false}>
            {reviews.map((review : any , index : any) => (
                <div key={index} className="bg-white p-5 mr-24 ">
                  <Row gutter={16} justify="center" style={{ height: "200px" }}>
                    <Col span={6}>
                      <img
                        src={imageUrl + review.image}
                        className="mx-auto border-2 p-1 border-orange-500 w-16 h-16 rounded-full"
                        alt="Profile"
                      />
                    </Col>
                    <Col span={18} className="overflow-y-auto">
                      <p className="font-extrabold" style={{ fontSize: "20px" }}>
                        {review.name}
                      </p>
                      <p className="text-gray-500 pt-3" style={{ fontSize: "16px", textAlign: "justify" }}>
                        {review.description.slice(0, 100)}
                      </p>
                      <button onClick={() => showModal(review)} className="text-sm pb-2 text-orange-500 underline hover:text-orange-600 transition duration-300 ease-in-out">
                        Read More
                      </button>
                    </Col>
                  </Row>
                </div>
              ))}
            </Carousel>
          </Col>
        </Row>
      </div>
      <Row
  className="md:pl-0 md:pt-0 md:pr-0 md:pb-0 xl:pt-24 xl:pr-48 xl:px-24 xl:pl-48 xs:pt-0 xs:ps-0 xs:px-0 xs:pl-0  sm:pt-0 sm:ps-0 sm:px-0 "
  
  style={{ display: "flex", marginTop: "" }}
>
  <Col
    className="xs:p-4  sm:p-4 md:p-4  xl:p-12"
    style={{ backgroundColor: "white", border: "1px solid silver", borderRadius: "10px" }}
    span={24}
  >
      <Row>
      <Col xs={24} sm={24} md={12} xl={12} xxl={12} className="p-2  xl:pl-12">
        <p className="tracking-widest font-bold mb-4 text-yellow text-center md:text-left" >SUBSCRIBE TO NEWSLETTER</p>
        <p className="text-4xl font-extrabold mb-4">How to Subscribe</p>


        <div className="flex items-center mb-4 mt-3 font-semibold text-gray-500">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="w-10 h-8 rounded-full bg-yellow-700"
          >
            <path d="M9 12l2 2 4-4" />
          </svg>
         <p className="pl-2">Enter Your Email: Simply provide your email address in the subscription box.</p>
        </div>

        <div className="flex items-center mb-4 mt-3 font-semibold text-gray-500 ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="w-10 h-8 rounded-full bg-yellow-700"
          >
            <path d="M9 12l2 2 4-4" />
          </svg>
         <p className="pl-2">Stay Connected: Enjoy regular updates and valuable content tailored to your business needs.</p>
        </div>
        
        <div className="flex items-center mb-4 mt-3 font-semibold text-gray-500">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="w-10 h-8 rounded-full bg-yellow-700"
          >
            <path d="M9 12l2 2 4-4" />
          </svg>
         <p className="pl-2">Don't miss out on the opportunity to stay ahead of the curve. Subscribe now!</p>
        </div>
      </Col>
      <Col xs={24} sm={24} md={12} xl={12} xxl={12}   className="xs:p-0  sm:p-8  xl:pl-12">
      <div className="p-12 pb-2 bg-yellow-700" style={{ borderRadius: "10px" }}>
      <p className="font-bold text-lg text-white">NEWSLETTER</p>
      <p className="text-md text-white mt-2 mb-2">Subscribe to our newsletter and never miss out on important updates and opportunities.</p>
      <Form form={form} onFinish={handleSubscribe}>
        <Form.Item name="email">
          <Input size="large" className="mt-4 rounded-md border-0 p-2" placeholder="Enter Your Email" />
        </Form.Item>
        <Form.Item>
          <button
            className="btn bt-lg mt-2 font-bold pl-8 pr-8 pt-2 pb-2 text-sm"
            style={{ backgroundColor: "black", color: "white" }}
            type="submit"
            disabled={isSubmitting}
          >
            Subscribe now
          </button>
        </Form.Item>
      </Form>
    </div>

      </Col>
    </Row>
  </Col>
</Row>
    </>
  );
};

export default Reviews;
