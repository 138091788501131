import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { RiEmotionHappyLine } from 'react-icons/ri';
import { LuBarChart4 } from "react-icons/lu";
import axios from 'axios';
import { Link } from 'react-router-dom';

const Theme2Homepage = () => {
  const [data, setData] = useState<any>(null);
  const imageUrl = process.env.REACT_APP_IMAGE_URL + '/template/';
  console.log(imageUrl)


  // const imageurl = process.env.REACT_APP_IMAGE_URL

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('http://192.168.1.11:5002/api/templeate/66b4a2dc49f09604abfb8e85/edit');
        console.log(response.data);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <header className="text-gray-600 body-font">
        <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
          <a className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
            <img
          src={`  ${imageUrl}${data.header.logo}`}
          alt={ `${imageUrl}${data.header.logo}`}
              className="w-10 h-10 text-white p-2 bg-indigo-500 rounded-full"
            />
            <span className="ml-3 text-xl">Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere accusamus deleniti veniam distinctio, numquam sequi repellendus totam tenetur eveniet esse blanditiis reiciendis. Dolore facilis quas dolor alias eaque. Fugit, excepturi?</span>
          </a>
          <nav className="md:ml-auto flex flex-wrap items-end text-base justify-end">
            <a className="mr-5 hover:text-gray-900">First Link</a>
            <a className="mr-5 hover:text-gray-900">Second Link</a>
            <a className="mr-5 hover:text-gray-900">Third Link</a>
            <a className="mr-5 hover:text-gray-900">Fourth Link</a>
          </nav>
        </div>
      </header>
      <section className="text-gray-600 body-font">
        <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
              {data.header.title}
            </h1>
            <p className="mb-8 leading-relaxed d-flex justify-start" style={{ textAlign: 'justify' }}>
              {data.header.description}
            </p>
            <div className="flex w-full space-x-2 md:justify-start justify-center items-end">
          <Link to={data.header.buttonLink} className="">
          <button className="inline-flex text-white p-4 bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">
                {data.header.buttonText}
              </button>
              </Link>
            </div>
        
          </div>
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
            <img
              className="object-cover object-center rounded"
              src={`  ${imageUrl}${data.header.logo}`}
              alt={ `${imageUrl}${data.header.logo}`}
            />
          </div>
        </div>
      </section>
  
      <div className="container px-5 py-2 mx-auto">
        <div className="flex flex-col text-center w-full mb-2">
          <h1 className="sm:text-md text-md font-bold title-font mb-4 text-indigo-900">Benefit Having This</h1>
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">{data.section.title}</h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base">{data.section.description}</p>
        </div>
      </div>
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto flex flex-wrap">
          <div className="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden">
            <img
              className="object-cover object-center h-full w-full"
              src={`  ${imageUrl}${data.section.banner}`}
              alt={ `${imageUrl}${data.section.banner}`}
            />
          </div>
          <div className="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left">
            <Row align={'middle'}>
              <Col span={24}>
                <h1 className="title-font mt-5 font-extrabold sm:text-4xl text-3xl mb-4 text-gray-700">HighLight Your Benefits</h1>
                <h4 className="title-font sm:text-xl tracking-wider text-xl mb-4 font-medium text-black">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.
                </h4>
              </Col>
              <Col span={24} className='pb-4 pt-4'>
                <Row align={'middle'}>
                  <Col span={3}>
                    <RiEmotionHappyLine size={40} className='text-indigo-500' />
                  </Col>
                  <Col span={20}>
                    <p className='text-black font-semibold text-lg'>Sed ut perspiciatis Sed ut perspiciatis</p>
                    <p className='text-black font-medium text-md'>Lorem ipsum dolor sit ametLorem ipsum dolor sit ametLorem ipsum dolor sit amet</p>
                  </Col>
                </Row>
              </Col>
              <Col span={24} className='pb-4'>
                <Row align={'middle'}>
                  <Col span={3}>
                    <RiEmotionHappyLine size={40} className='text-indigo-500' />
                  </Col>
                  <Col span={20}>
                    <p className='text-black font-semibold text-lg'>Sed ut perspiciatis Sed ut perspiciatis</p>
                    <p className='text-black font-medium text-md'>Lorem ipsum dolor sit ametLorem ipsum dolor sit ametLorem ipsum dolor sit amet</p>
                  </Col>
                </Row>
              </Col>
              <Col span={24} className='pb-4'>
                <Row align={'middle'}>
                  <Col span={3}>
                    <LuBarChart4 size={40} className='text-indigo-500' />
                  </Col>
                  <Col span={20}>
                    <p className='text-black font-semibold text-lg'>Sed ut perspiciatis Sed ut perspiciatis</p>
                    <p className='text-black font-medium text-md'>Lorem ipsum dolor sit ametLorem ipsum dolor sit ametLorem ipsum dolor sit amet</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </section>

      
<section className="text-gray-600 body-font">
  <div className="container px-5 py-24 mx-auto">
    <div className="flex flex-col text-center w-full mb-20">
      <h1 className="sm:text-3xl text-2xl font-bold title-font text-indigo-500">
        Our Products
      </h1>
      <p className="lg:w-2/3 mx-auto leading-relaxed text-base font-semibold">
        Explore our range of top-quality products designed to meet your needs.
      </p>
    </div>
    <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
      <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
        <div className="rounded-lg h-64 overflow-hidden">
          <img
            alt="content"
            className="object-cover object-center h-full w-full"
            src="https://plus.unsplash.com/premium_photo-1683865776032-07bf70b0add1?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8dXJsfGVufDB8fDB8fHww"
          />
        </div>
        <h2 className="text-xl font-medium title-font text-gray-900 mt-5">
          Shooting Stars
        </h2>
        <p className="text-base leading-relaxed mt-2">
          Swag shoivdigoitch literally meditation subway tile tumblr
          cold-pressed. Gastropub street art beard dreamcatcher neutra, ethical
          XOXO lumbersexual.
        </p>
        <a className="text-indigo-500 inline-flex items-center mt-3">
          Learn More
          <svg
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            className="w-4 h-4 ml-2"
            viewBox="0 0 24 24"
          >
            <path d="M5 12h14M12 5l7 7-7 7" />
          </svg>
        </a>
      </div>
      <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
        <div className="rounded-lg h-64 overflow-hidden">
          <img
            alt="content"
            className="object-cover object-center h-full w-full"
            src="https://plus.unsplash.com/premium_photo-1683865776032-07bf70b0add1?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8dXJsfGVufDB8fDB8fHww"
          />
        </div>
        <h2 className="text-xl font-medium title-font text-gray-900 mt-5">
          The Catalyzer
        </h2>
        <p className="text-base leading-relaxed mt-2">
          Swag shoivdigoitch literally meditation subway tile tumblr
          cold-pressed. Gastropub street art beard dreamcatcher neutra, ethical
          XOXO lumbersexual.
        </p>
        <a className="text-indigo-500 inline-flex items-center mt-3">
          Learn More
          <svg
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            className="w-4 h-4 ml-2"
            viewBox="0 0 24 24"
          >
            <path d="M5 12h14M12 5l7 7-7 7" />
          </svg>
        </a>
      </div>
      <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
        <div className="rounded-lg h-64 overflow-hidden">
          <img
            alt="content"
            className="object-cover object-center h-full w-full"
            src="https://plus.unsplash.com/premium_photo-1683865776032-07bf70b0add1?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8dXJsfGVufDB8fDB8fHww"
          />
        </div>
        <h2 className="text-xl font-medium title-font text-gray-900 mt-5">
          The 400 Blows
        </h2>
        <p className="text-base leading-relaxed mt-2">
          Swag shoivdigoitch literally meditation subway tile tumblr
          cold-pressed. Gastropub street art beard dreamcatcher neutra, ethical
          XOXO lumbersexual.
        </p>
        <a className="text-indigo-500 inline-flex items-center mt-3">
          Learn More
          <svg
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            className="w-4 h-4 ml-2"
            viewBox="0 0 24 24"
          >
            <path d="M5 12h14M12 5l7 7-7 7" />
          </svg>
        </a>
      </div>
    </div>
  </div>
</section>
<footer className="text-gray-600 body-font">
  <div className="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
    <a className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        className="w-10 h-10 text-white p-2 bg-indigo-500 rounded-full"
        viewBox="0 0 24 24"
      >
        <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5" />
      </svg>
      <span className="ml-3 text-xl">Tailblocks</span>
    </a>
    <p className="text-sm text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0">
      © 2020 Tailblocks —
      <a
        href="https://twitter.com/knyttneve"
        className="text-gray-600 ml-1"
        rel="noopener noreferrer"
        target="_blank"
      >
        @knyttneve
      </a>
    </p>
    <span className="inline-flex sm:ml-auto sm:mt-0 justify-center sm:justify-start">
      <a className="text-gray-500">
        <svg
          fill="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          className="w-5 h-5"
          viewBox="0 0 24 24"
        >
          <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z" />
        </svg>
      </a>
      <a className="ml-3 text-gray-500">
        <svg
          fill="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          className="w-5 h-5"
          viewBox="0 0 24 24"
        >
          <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z" />
        </svg>
      </a>
      <a className="ml-3 text-gray-500">
        <svg
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          className="w-5 h-5"
          viewBox="0 0 24 24"
        >
          <rect width={20} height={20} x={2} y={2} rx={5} ry={5} />
          <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01" />
        </svg>
      </a>
      <a className="ml-3 text-gray-500">
        <svg
          fill="currentColor"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0}
          className="w-5 h-5"
          viewBox="0 0 24 24"
        >
          <path
            stroke="none"
            d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
          />
          <circle cx={4} cy={4} r={2} stroke="none" />
        </svg>
      </a>
    </span>
  </div>
</footer>

    </>
  );
};

export default Theme2Homepage;
