import React from 'react';
import { Row, Col } from 'antd';

const Counter = () => {
  return (

    <Row
    className="p-12 md:pt-8 md:ps-8 md:px-8 xl:pt-16 xl:ps-44 xl:px-44 sm:pt-8 sm:ps-8 sm:px-8 xs:pt-8 xs:ps-8 xs:px-8 xs:pl-8" 
    gutter={[16, 16]}
  >
   <Col xs={24} sm={12} md={6}>
  <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-md md:h-[200px]"> 
    <p className="text-3xl md:text-4xl font-extrabold text-yellow-500 mb-2">200+</p>
    <p className="text-lg md:text-xl font-semibold text-gray-800 mb-2">Awards Won</p>
    <p className="text-xs md:text-sm text-gray-500">Recognized for excellence and innovation.</p>
  </div>
</Col>



    <Col xs={24} sm={12} md={6}>
      <div className="p-4 bg-black rounded-lg shadow-md  md:h-[200px]">
        <p className="text-3xl md:text-4xl font-extrabold text-yellow-500 mb-2">76k</p>
        <p className="text-lg md:text-xl font-semibold text-white mb-2">Approved Listings</p>
        <p className="text-xs md:text-sm text-gray-300">Verified and trusted by thousands.</p>
      </div>
    </Col>

    <Col xs={24} sm={12} md={6} >
      <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-md  md:h-[200px]">
        <p className="text-3xl md:text-4xl font-extrabold text-yellow-500 mb-2">400+</p>
        <p className="text-lg md:text-xl font-semibold text-gray-800 mb-2">Expert People</p>
        <p className="text-xs md:text-sm text-gray-500">Highly skilled professionals at your service.</p>
      </div>
    </Col>

    <Col xs={24} sm={12} md={6}>
      <div className="p-4 bg-black rounded-lg shadow-md  md:h-[200px]">
        <p className="text-3xl md:text-4xl font-extrabold text-yellow-500 mb-2">40k+</p>
        <p className="text-lg md:text-xl font-semibold text-white mb-2">Happy Customers</p>
        <p className="text-xs md:text-sm text-gray-300">Trusted by thousands and counting.</p>
      </div>
    </Col>
  </Row>
  );
};

export default Counter;
