import { Card, Row, Table, Col, Input, message, Spin, Modal } from "antd";
import { AiFillDelete, AiOutlineExport } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import { useEffect, useState } from "react";
import { deletePackage, getAllPackages } from "../../../utils/API";
import { RiAddBoxFill } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";

interface Package {
  _id: string;
  packageName: string;
  price: number;
  descriptions: string[];
}


export const Packagetable = () => {
  const [datasource, setDatasource] = useState<Package[]>([]);
  const [loading, setLoading] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const navigate = useNavigate();

  const fetchPackages = async () => {
    setLoading(true);
    try {
      const response = await getAllPackages();
      setDatasource(response); 
    } catch (error) {
      message.error("Network error. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPackages();
  }, []);

  const handleEditRedirect = (record: Package) => {
    navigate(`/backend/package/${record._id}/edit`);
  };

  const handleDeleteConfirmation = (id: string) => {
    setDeleteId(id);
  };

  const handleDelete = async () => {
    if (deleteId) {
      try {
        await deletePackage(deleteId);
        message.success("Package deleted successfully");
        fetchPackages();
      } catch (error) {
        message.error("Failed to delete package");
      } finally {
        setDeleteId(null);
      }
    }
  };

  const columns = [
    {
      title: "Package Name",
      dataIndex: "packageName",
      key: "packageName",
      render: (text: string) => text || "-",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (price: number) => `${price.toFixed(2)}`,
    },
    {
      title: "Duraction",
      dataIndex: "duraction",
      key: "duraction",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_: any, record: Package) => (
        <div className="d-flex">
          <FaEdit
            className="me-4 text-yellow"
            style={{ fontSize: "20px", cursor: "pointer" }}
            onClick={() => handleEditRedirect(record)}
          />
          <AiFillDelete
            className="text-yellow"
            style={{ fontSize: "20px", cursor: "pointer" }}
            onClick={() => handleDeleteConfirmation(record._id)}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <Row className="m-2" align={"middle"}>
        <Col
          xs={24}
          sm={24}
          md={9}
          xl={6}
          xxl={6}
          className="d-flex justify-content-start font-bold"
        >
          <h2 className="text-2xl">Package</h2>
        </Col>
        <Col xs={24} sm={24} md={24} xl={18} xxl={18} className="">
          <Row gutter={16} className="float-center xs:m-2">
            <Col xs={0} sm={0} md={0} xl={7} xxl={7}></Col>
            <Col xs={36} sm={36} md={18} xl={12} xxl={12} className="">
              <Input
                prefix={<SearchOutlined style={{ color: "#a6a6a6" }} />}
                size="large"
                className="float-end"
                placeholder="Search..."
                allowClear
                // onChange={(e) => handleSearch(e.target.value)}
              />
            </Col>
            <Col xs={24} sm={24} md={6} xl={5} xxl={5}>
              <Link to={"/backend/package/add"}>
                <button className="btn  d-flex justify-content-center align-items-center w-100 text-center bg-yellow-700 text-black" style={{  color: "white" }}>
                  <RiAddBoxFill style={{ fontSize: "15px" }} />
                  <div className="ms-2">Add Package</div>
                </button>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Card className="container w-100">
          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Spin spinning={loading}>
              <Table columns={columns} dataSource={datasource} />
            </Spin>
          </Col>
        </Card>
      </Row>
      <Modal
        title="Confirmation"
        visible={!!deleteId}
        onOk={handleDelete}
        onCancel={() => setDeleteId(null)}
        okText="Yes"
        cancelText="No"
      >
        <p>Are you sure you want to delete this package?</p>
      </Modal>
    </div>
  );
};
