import React from 'react';
import { FaCheck } from 'react-icons/fa';

const NewBusiness = () => (
    <div className="font-poppins min-h-screen bg-gray-100 flex justify-center items-center">
        <div className="w-full max-w-screen-xl bg-white p-8 rounded-lg shadow-md flex flex-wrap">
            <div className="w-full md:w-3/5 flex flex-col flex-1 p-4">
                <div className="text-left mb-4">
                    <a href="#" className="text-blue-500 font-bold no-underline">Home</a> &gt; <a href="#" className="text-blue-500 font-bold no-underline">Free Business Listing</a>
                </div>
                <h1 className="text-3xl font-extrabold mb-8">
                    List Your Business <span className="text-blue-500">for FREE</span>
                </h1>
                <p className="text-lg font-bold mb-4">with India's No. 1 Local Search Engine</p>

                <div className="flex items-center mb-4 border rounded-lg border-gray-300">
                    <div className="flex items-center bg-gray-200 rounded-l-lg p-2">
                        <img src="https://akam.cdn.jdmagicbox.com/images/icontent/listingbusiness/india_flag.svg" className="w-5 h-auto mr-2" alt="India Flag" />
                        <span>+91</span>
                    </div>
                    <input type="text" placeholder="Enter Mobile No." className="p-2 border-none flex-1" />
                    <button className="p-2 px-4 bg-blue-500 text-white font-medium rounded-r-lg cursor-pointer">Start Now <span className="text-lg">&rarr;</span></button>
                </div>

                <div className="text-left mb-4">
                    <div className="flex items-center mb-2">
                        <FaCheck className="w-8 h-6 mr-2 text-green-500" />
                        <p className="font-bold text-lg">Get Discovered and Create Your Online Business</p>
                    </div>
                    <div className="flex items-center mb-2">
                        <FaCheck className="w-8 h-6 mr-2 text-green-500" />
                        <p className="font-bold text-lg">Respond to Customer Reviews and Questions</p>
                    </div>
                    <div className="flex items-center">
                        <FaCheck className="w-8 h-6 mr-2 text-green-500" />
                        <p className="font-bold text-lg">Showcase Your Product and Service Offerings</p>
                    </div>
                </div>

                <div className="mb-4 text-sm text-gray-600">
                    By continuing, you agree to our <a href="#" className="text-blue-500 no-underline">Terms of Use</a>, <a href="#" className="text-blue-500 no-underline">Privacy</a> & <a href="#" className="text-blue-500 no-underline">Infringement Policy</a>
                </div>
            </div>

            <div className="w-full md:w-2/5 flex flex-col items-center justify-center relative mt-4 p-4">
                <img src="https://www.justdial.com/Free-Listing/_next/image?url=http%3A%2F%2Fakam.cdn.jdmagicbox.com%2Fimages%2Ficontent%2Flistingbusiness%2Fman_new_2x.png&w=640&q=75" alt="Person" className="w-80 max-w-full rounded-lg object-cover h-auto" />
                <div className="flex justify-between w-full mt-4">
                    <div className="flex-1 mr-2">
                        <div className="bg-blue-500 text-white font-bold rounded-lg p-4 text-center">
                            <p>17.1 Crore+ Buyers*</p>
                        </div>
                    </div>
                    <div className="flex-1 mx-2">
                        <div className="bg-orange-500 text-white font-bold rounded-lg p-4 text-center">
                            <p>3.8 Crore+ Businesses Listed</p>
                        </div>
                    </div>
                    <div className="flex-1 ml-2">
                        <div className="bg-yellow-500 text-white font-bold rounded-lg p-4 text-center">
                            <p>5.4 Lakh+ Happy Customers</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default NewBusiness;
