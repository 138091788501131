import React from "react";
import { GiCheerUp } from "react-icons/gi";
import { Row, Col, Card, Progress, Button } from "antd";
import {
  CheckCircleOutlined,
  OrderedListOutlined,
  LikeFilled,
  GlobalOutlined,
  TeamOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import img1 from "../../../assets/frontendassets/About us/1.jpg";
import img2 from "../../../assets/frontendassets/About us/2.jpg";
import TeamSlider from "./Slider";
import HowWorks from "../Home/HowWorks";
import Reviews from "../Home/Reviews";
import ClientReview from "../ClientReview/ClientReview";
import InfoSection from "../../Auth/AboutUsBuild";
import aboutus from "../../../assets/frontendassets/About us/aboutus.jpg";

const AboutUs = () => {
  return (
    <div>
      <div
        className="relative h-64 md:h-80 lg:h-96 bg-cover bg-center"
        style={{ backgroundImage: `url(${aboutus})` }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-25 flex flex-col items-center justify-center px-4">
          <h1 className="text-white text-2xl md:text-5xl lg:text-6xl font-bold text-center">
            Empowering Businesses
          </h1>
          <p className="text-white text-sm md:text-lg lg:text-xl mt-2 text-center">
            Connecting Opportunitiess
          </p>
        </div>
      </div>
      <hr
        className="text-white"
        style={{
          marginLeft: "100px",
          marginRight: "100px",
          marginTop: " 30px",
          marginBottom: "30px",
        }}
      />
      <div className=" lg:ps-5 xl:px-36 xl:ps-36 md:px-5 md:ps-5 sm:px-3 xs:ps-5">
        <Row>
          <Col xs={24} md={12} lg={12} className="px-3 pt-3">
            <Row gutter={[16, 16]} className="about-us-images">
              <Col xs={24} sm={24} md={12} lg={12} className="image-container">
                <img
                  src={img1}
                  alt="Image 1"
                  className="w-full rounded-lg lg:relative lg:top-20 md:relative md:top-20"
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} className="image-container">
                <img
                  src={img2}
                  alt="Image 2"
                  className="w-full rounded-lg lg:relative lg:top-0 md:relative md:top-0"
                />
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <div className="lg:mt-0 lg:ms-12 md:mt-0 md:ms-8 sm:mt-4 sm:ms-4 text-left">
              <h5 className="text-yellow my-2.5 tracking-wider font-semibold">
                Who We Are
              </h5>
              <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold my-2.5">
                Building destination to get things done
              </h1>
              <p className="lg:text-lg md:text-base sm:text-sm text-gray-600 mt-2 mb-4">
                At Net4Surge, we believe in the power of interconnectedness. By
                providing a comprehensive platform that links enterprises to
                both fellow businesses and end consumers worldwide, we help
                drive economic advancement and create a vibrant business
                ecosystem.
              </p>
              <ul className="list-none p-0 lg:text-lg md:text-base sm:text-sm">
                <li className="flex items-start mb-2">
                  <CheckCircleOutlined className="text-yellow text-xl mr-2.5" />
                  <div className="text-black">
                    <h4 className="font-bold m-0">
                    Free Business Listing
                    </h4>
                  </div>
                </li>
                <li className="flex items-start mb-2">
                  <CheckCircleOutlined className="text-yellow text-xl mr-2.5" />
                  <div className="text-black">
                    <h4 className="font-bold m-0">
                    Verified Listings
                    </h4>
                  </div>
                </li>
                <li className="flex items-start mb-2">
                  <CheckCircleOutlined className="text-yellow text-xl mr-2.5" />
                  <div className="text-black">
                    <h4 className="font-bold m-0">
                    Comprehensive Marketing Tools
                    </h4>
                  </div>
                </li>
                <li className="flex items-start mb-2">
                  <CheckCircleOutlined className="text-yellow text-xl mr-2.5" />
                  <div className="text-black">
                    <h4 className="font-bold m-0">
                    Business Website with SEO
                    </h4>
                  </div>
                </li>
                <li className="flex items-start mb-2">
                  <CheckCircleOutlined className="text-yellow text-xl mr-2.5" />
                  <div className="text-black">
                    <h4 className="font-bold m-0">
                    Social Media Management Services
                    </h4>
                  </div>
                </li>
              </ul>
              <div className="my-3">
                <div className="flex justify-between items-center">
                  <span className="text-sm">Directory</span>
                  <span className="text-sm">50%</span>
                </div>
                <div className="w-full bg-gray-200 rounded-full h-3">
                  <div
                    className="bg-yellow-700 h-3 rounded-full"
                    style={{ width: "50%" }}
                  ></div>
                </div>
              </div>
              <button className="bg-yellow-700 text-black h-12 font-bold py-2 px-4 rounded focus:outline-none focus:bg-gray-800">
                Get Started
              </button>
            </div>
          </Col>
        </Row>
      </div>

      <hr
        className="text-white"
        style={{
          marginLeft: "100px",
          marginRight: "100px",
          marginTop: " 30px",
          marginBottom: "30px",
        }}
      />

      <hr
        className="text-white"
        style={{
          marginLeft: "100px",
          marginRight: "100px",
          marginTop: " 30px",
          marginBottom: "30px",
        }}
      />
      <div
        className="fun-facts relative py-12 bg-cover bg-center g:px-0 lg:ps-0 xl:px-36 xl:ps-36  md:px-0 md:ps-0 sm:ps-5 xs:ps-5"
        style={{
          backgroundImage:
            "url('https://gaviaspreview.com/wp/lestin/wp-content/uploads/2023/08/bg-17.jpg')",
          width: "100%",
        }}
      >
        <div className="relative z-1  lg:mx-12  md:mx-6  sm:mx-3">
          <Row gutter={[16, 16]}>
            <Col xs={24} md={12} lg={12}>
              <div className="mx-10 md:mx-3 text-left">
                <h5 className="text-yellow font-semibold tracking-wider">
                  OUR FUN FACTS
                </h5>
                <h1 className="text-4xl text-white font-bold my-4">
                Trusted by Over 40,000+ Clients Worldwide
                </h1>
                <ul className="list-none p-0 text-lg">
                  <li className="flex items-start mb-5">
                    <CheckCircleOutlined className="text-yellow text-4xl mr-2.5" />
                    <div className="text-white">
                      <h3 className="font-bold m-0">
                      We're Professional and Certified
                      </h3>
                      <p className="mt-1 font-extralight" style={{ fontSize: "16px", textAlign: "justify" }}>
                      At Net4Surge, we pride ourselves on our professionalism and certification. Our team of experts is dedicated to providing top-notch services, ensuring your business is in the best hands.
                      </p>
                    </div>
                  </li>
                  <li className="flex items-start mb-5">
                    <CheckCircleOutlined className="text-yellow text-4xl mr-2.5" />
                    <div className="text-white">
                      <h3 className="font-bold m-0">
                      Get Instant Access for Listings
                      </h3>
                      <p className="mt-1 font-extralight" style={{ fontSize: "16px", textAlign: "justify" }}>
                      Unlock immediate opportunities by listing your business on Net4Surge. With our easy and fast listing process, gain instant visibility and connect with potential customers right away.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </Col>

            <Col
              xs={24}
              md={12}
              lg={12}
              className="flex flex-col items-center justify-center"
            >
              <Row justify="center" className="w-full mb-5">
                <Col
                  xs={24}
                  sm={12}
                  md={12}
                  className="flex justify-center mb-5"
                >
                  <div className="w-36 h-36 sm:w-40 sm:h-40 lg:w-44 lg:h-44 bg-yellow-700 text-black font-bold rounded-full flex items-center justify-center relative text-center">
                    <div>
                      <h3 className="text-2xl m-0">76k</h3>
                      <p className="text-sm m-0">Approved Listing</p>
                      <OrderedListOutlined
                        className="absolute bottom-0 transform translate-y-1/2 left-1/2 -translate-x-1/2 w-11 h-11 text-black text-3xl bg-white rounded-full"
                        style={{ justifyContent: "center" }}
                      />
                    </div>
                  </div>
                </Col>
                <Col
                  xs={24}
                  sm={12}
                  md={12}
                  className="flex justify-center mb-5"
                >
                  <div className="w-36 h-36 sm:w-40 sm:h-40 lg:w-44 lg:h-44 bg-yellow-700 text-black font-bold rounded-full flex items-center justify-center relative text-center">
                    <div>
                      <h3 className="text-2xl m-0">40+</h3>
                      <p className="text-sm m-0">Places in the World</p>
                      <GlobalOutlined
                        className="absolute bottom-0 transform translate-y-1/2 left-1/2 -translate-x-1/2 w-11 h-11 text-black text-3xl bg-white rounded-full"
                        style={{ justifyContent: "center" }}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row justify="center">
                <Col
                  xs={24}
                  sm={12}
                  md={24}
                  className="flex justify-center mb-5"
                >
                  <div className="w-36 h-36 sm:w-40 sm:h-40 lg:w-44 lg:h-44 bg-yellow-700 text-black font-bold rounded-full flex items-center justify-center relative text-center">
                    <LikeFilled
                      className="absolute -top-9 left-1/2 transform -translate-x-1/2 w-11 h-11 text-black text-3xl bg-white rounded-full"
                      style={{ justifyContent: "center" }}
                    />
                    <div>
                      <h3 className="text-2xl m-0">40k+</h3>
                      <p className="text-sm m-0">Happy Customers</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>

      <InfoSection />

      <div className="flex justify-center p-4">
        <Row gutter={[16, 16]} className="max-w-screen-lg w-full">
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Card
              className="rounded-lg relative overflow-hidden hover:bg-yellow-700"
              style={{
                backgroundColor: "#ffff",
                border: "1px solid grey",
              }} // Increased the height slightly for better alignment
            >
              <Row>
                <Col span={20}>
                  <div className="flex flex-col ">
                    {" "}
                    {/* Adjusted padding for better alignment */}
                    <h2 className="text-xl font-bold text-gray-900">
                    Join Our Business Community
                    </h2>
                    <p className="mt-2 text-gray-600">
                    Become a part of a thriving network of entrepreneurs and industry leaders. Connect, collaborate, and grow with us as we work together to drive success and innovation.
                    </p>
                  </div>
                </Col>
                <Col span={4} className="absolute bottom-6 right-3">
                  <div className="w-32 h-32 bg-gray-300 rounded-full flex items-center justify-center transition duration-300 ease-in-out hover:bg-yellow-500">
                    <TeamOutlined className="text-white text-4xl mr-4 mt-3" />
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 12 }}>
            <Card
              className="rounded-lg shadow-md  relative overflow-hidden hover:bg-yellow-700"
              style={{
                backgroundColor: "#ffff",
                border: "1px solid grey",
              }}
            >
              <Row>
                <Col span={4} className="absolute bottom-6 right-3 ">
                  <div className="w-32 h-32 bg-gray-300 rounded-full  flex items-center justify-center transition duration-300 ease-in-out hover:bg-yellow-500">
                    <SolutionOutlined className="text-white text-4xl mr-4 mt-3 " />
                  </div>
                </Col>
                <Col span={20}>
                  <div className="flex flex-col ">
                    <h2 className="text-xl font-bold text-gray-900">
                    See How It Works
                    </h2>
                    <p className="mt-2 text-gray-600 ">
                    Discover how Net4Surge can transform your business. Explore our straightforward process to list, connect, and grow.
                    </p>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AboutUs;
