import React, { useState } from 'react';
import { Card, Col, Modal, Row, Table, Switch } from 'antd';

interface User {
  username: string;
  email: string;
  description: string;
  switchState: boolean;
}

const WebsiteReveiws: React.FC = () => {
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());
  const [users, setUsers] = useState<User[]>([
    { username: 'Amzadkhan', email: 'Amzad@example.com', description: 'A web developer. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nisi est, facilisis ac tellus vitae, egestas efficitur erat.', switchState: false },
    { username: 'fejalkhan', email: 'fejal@example.com', description: 'A web developer. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nisi est, facilisis ac tellus vitae, egestas efficitur erat.', switchState: false },
    { username: 'samirkhan', email: 'samir@example.com', description: 'An electrical engineer. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nisi est, facilisis ac tellus vitae, egestas efficitur erat.', switchState: false },
  ]);

  const handleSwitchChange = (checked: boolean, user: User, event: React.MouseEvent) => {
    event.stopPropagation();
    const updatedUsers = users.map((u) =>
      u.username === user.username ? { ...u, switchState: checked } : u
    );
    setUsers(updatedUsers);
  };

  const showModal = (user: User) => {
    setSelectedUser(user);
  };

  const handleOk = () => {
    setSelectedUser(null);
  };

  const handleCancel = () => {
    setSelectedUser(null);
  };

  const toggleDescription = (username: string) => {
    setExpandedRows((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(username)) {
        newSet.delete(username);
      } else {
        newSet.add(username);
      }
      return newSet;
    });
  };

  const columns = [
    {
      title: 'User Name',
      dataIndex: 'username',
      key: 'username',
      width: 150,
    },
    {
      title: 'User Email',
      dataIndex: 'email',
      key: 'email',
      width: 200,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 300,
      render: (text: string, record: User) => (
        <div>
          <span className="description-text">
            {expandedRows.has(record.username) ? text : `${text.slice(0, 50)}...`}
          </span>
          
          <a 
            onClick={(e) => {
              e.stopPropagation();
              toggleDescription(record.username);
            }}
            style={{ color: 'blue', cursor: 'pointer', marginLeft: '8px', }}
          >
            {expandedRows.has(record.username) ? ' Read Less' : ' Read More'}
          </a>
        </div>
      ),
    },
    {
      title: 'Publish',
      dataIndex: 'publish',
      key: 'publish',
      render: (text: any, record: User) => (
        <Switch
          checked={record.switchState}
          onChange={(checked, event) => handleSwitchChange(checked, record, event)}
          style={{
            backgroundColor: record.switchState ? '#F59E0B' : undefined,
          }}
        />
      ),
      width: 100,
    },
  ];

  return (
    <>
      <Row className="m-2" align="middle">
        <Col xs={24} md={9} xl={6} xxl={6}>
          <h2 className="text-2xl">Reviews</h2>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Card className="bg-white border" style={{ borderRadius: '12px' }}>
            <Table
              columns={columns}
              dataSource={users}
              pagination={{ position: ['bottomRight'] }}
              scroll={{ x: 'max-content' }}
            />
          </Card>
        </Col>
      </Row>
      {selectedUser && (
        <Modal
          title="User Details"
          visible={true}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
          className="custom-modal"
        >
          <>
            <p><strong>Username:</strong> {selectedUser.username}</p>
            <p><strong>Email:</strong> {selectedUser.email}</p>
            <p><strong>Description:</strong> {selectedUser.description}</p>
          </>
        </Modal>
      )}
    </>
  );
};

export default WebsiteReveiws;
