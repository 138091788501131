import React, { useState, useEffect } from "react";
import { Card, Col, Row, Table } from "antd";
import { getContacts } from "../../../utils/API";

const columns = [
  {
    title: (
      <div className="font-bold text-xs text-gray-500">Name</div>
    ),
    dataIndex: "name",
    key: "name",
  },
  {
    title: (
      <div className="font-bold text-xs text-gray-500">Phone</div>
    ),
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: (
      <div className="font-bold text-xs text-gray-500">Email</div>
    ),
    dataIndex: "email",
    key: "email",
  },
  {
    title: (
      <div className="font-bold text-xs text-gray-500">Date</div>
    ),
    dataIndex: "createdAt",
    key: "date",
    render: (text) => {
      const date = new Date(text);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    }
  },
  {
    title: (
      <div className="font-bold text-xs text-gray-500">Subject</div>
    ),
    dataIndex: "subject",
    key: "subject",
  },
  {
    title: (
      <div className="font-bold text-xs text-gray-500">Message</div>
    ),
    dataIndex: "message",
    key: "message",
  },
];

const Tabel = () => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleContacts = async () => {
    setLoading(true);
    try {
      const response = await getContacts();
      setDataSource(response.data);
    } catch (error) {
      console.error("Failed to fetch contacts", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleContacts();
  }, []);

  return (
    <>
      <Row className="m-2" align={"middle"}>
        <Col
          xs={24}
          sm={24}
          md={9}
          xl={6}
          xxl={6}
          className="flex justify-start font-bold"
        >
          <h2 className="text-xl sm:text-2xl">Inquiries</h2>
        </Col>
      </Row>

      <div className="flex flex-col items-center w-full min-h-screen">
        <div className="rounded-lg w-full">
          <Card className="bg-white border" style={{ borderRadius: "12px" }}>
            <Table
              dataSource={dataSource}
              columns={columns}
              loading={loading}
              pagination={{ pageSize: 5 }}
              scroll={{ x: 400 }}
              className="w-full"
            />
          </Card>
        </div>
      </div>
    </>
  );
};

export default Tabel;
