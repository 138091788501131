import { GlobalOutlined } from "@ant-design/icons";
import React from "react";
import { Row, Col } from "antd";
import img3 from "../../../assets/pricing/pricing/banner.png"; // Import the image

const PromoBanner = () => {
  return (
    <div className=" mt-10 w-full mb-10 sm:w-full md:w-full lg:w-full  lg:px-0 lg:ps-0 xl:px-36 xl:ps-36  md:px-0 md:ps-0 sm:ps-0 xs:ps-0 ">
      <div
        style={{
          backgroundImage: `url(${img3})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className=" relative h-64 bg-gradient-to-r from-yellow-600 to-transparent  flex flex-col justify-center text-center"
      >
        <div className="absolute inset-0 bg-cover bg-right">
          <div className="absolute inset-0 bg-gradient-to-r from-yellow-600/30 to-yellow-600"></div>
        </div>
        <div className="relative pl-8 z-1 sm: py-8 ">
          <Row justify="center" align="middle">
            <Col xs={24} sm={24} md={18} lg={16} className="text-center">
              <div className="text-white flex items-center">
                <div className="inline-block mr-4">
                  <GlobalOutlined className="text-white text-4xl lg:text-5xl md:text-5xl" />
                </div>
                <div className="inline-block text-left">
                  <p className="text-base md:text-base lg:text-xl xl:text-2xl">
                    Your story starts from here
                  </p>
                  <h1 className="text-2xl md:text-3xl xl:text-5xl font-bold">
                    Let's visit the best places
                  </h1>
                </div>
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={6}
              lg={8}
              className="text-left mt-4 lg:text-center lg:mt-0"
            >
              <button className="bg-black text-white px-4 py-2 rounded-md h-12 w-30 md:h-12  md:w-auto md:mr-4 font-bold text-base">
                Discover More
              </button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default PromoBanner;
