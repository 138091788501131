import React, { useContext, useEffect, useState } from "react";
import { DesktopOutlined } from "@ant-design/icons";
import { Drawer, Layout, Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import { BsSubtract } from "react-icons/bs";
import { BiCategory, BiSlider, BiSolidBusiness } from "react-icons/bi";
import { CiLogout, CiSettings } from "react-icons/ci";
import Logo from "../../assets/frontendassets/net 4 surge (1).png";
import { FaCoins } from "react-icons/fa6";
import { AuthContext } from "../../Pages/Auth/AuthContext";
import "./Master.css";
import { FaBusinessTime, FaNewspaper, FaThemeco, FaUser } from "react-icons/fa";
import { GrSettingsOption } from "react-icons/gr";
import { MdSettingsSuggest } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { packeagevalied } from "../../utils/API";

const { Sider } = Layout;

interface MenuItem {
  key: string;
  icon: JSX.Element;
  text: string;
  link: string;
}

interface SubMenu extends MenuItem {
  children: MenuItem[];
}

interface SidebarProps {
  collapsed: boolean;
  isSmallScreen: boolean;
  hoverEffectActive: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onCollapse: (
    e:
      | React.MouseEvent<Element, MouseEvent>
      | React.KeyboardEvent<Element>
      | boolean
  ) => void;
}

const Sidebar: React.FC<SidebarProps> = ({
  collapsed,
  onCollapse,
  onMouseEnter,
  onMouseLeave,
  hoverEffectActive,
  isSmallScreen,
}) => {
  const location = useLocation();
  const { authData } = useContext(AuthContext);
  const [activeMenuItemKey, setActiveMenuItemKey] = useState<string | null>(null);
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [showMyWebsite, setShowMyWebsite] = useState<boolean>(false);
  const role_id = authData.roleId;
  const navigate = useNavigate();
  const handleClick = (itemKey: string) => {
    if (itemKey === "logout") {
      localStorage.clear();
      navigate('/login');
    } else {
      setActiveMenuItemKey(itemKey);
    }
  };

  const fetchdomain = async () => {
    try {
      const response = await packeagevalied();
      if (!response.data || !response.data._id) {
        setShowMyWebsite(false);
      } else {
        setShowMyWebsite(true);
      }
    } catch (error: any) {
    }
  };

  useEffect(() => {
    fetchdomain();
  }, []);

  const menuItemsForRole1: (MenuItem | SubMenu)[] = [
    {
      key: "Business",
      icon: <BiSolidBusiness style={{ color: "white", fontWeight: "bold" }} />,
      text: "Businesses",
      link: "/backend/manage-business"
    },
    {
      key: "MLM-Business",
      icon: <BiSolidBusiness style={{ color: "white", fontWeight: "bold" }} />,
      text: "MLM Businesses",
      link: "/backend/mlm-business"
    },
    {
      key: "packages",
      icon: <CiSettings style={{ color: "white", fontWeight: "bold" }} />,
      text: "Manage Packages",
      link: "/backend/packages",
    },
    {
      key: "Categories",
      icon: <BiCategory style={{ color: "white", fontWeight: "bold" }} />,
      text: "Categories",
      link: "/backend/categories",
    },
    {
      key: "NewsLetters",
      icon: <FaNewspaper style={{ color: "white", fontWeight: "bold" }} />,
      text: "NewsLetters",
      link: "/backend/NewsLetters"
    },
    {
      key: "Testimonial",
      icon: <MdSettingsSuggest color="white"/>,
      text: "Testimonials",
      link: "/backend/testimonials",
    },
    // {
    //   key: "Websites Reviews",
    //   icon: <FaBusinessTime style={{ color: "white", fontWeight: "bold" }} />,
    //   text: "Websites Reviews",
    //   link: "/backend/WebsiteReviews",
    // },
    {
      key: "contacts",
      icon: <FaUser style={{ color: "white", fontWeight: "bold" }} />,
      text: " inquiries",
      link: "/backend/inquiries"
    },
    {
      key: "Pricing Plans",
      icon: <FaCoins style={{ color: "white", fontWeight: "bold" }} />,
      text: "Pricing Plans",
      link: "/backend/pricing-plan",
    },
    {
      key: "my-website",
      icon: <FaCoins style={{ color: "white", fontWeight: "bold" }} />,
      text: "My Website",
      link: "/backend/mywebsite",
    },
    {
      key: "profile",
      icon: <BiSlider style={{ color: "white", fontWeight: "bold" }} />,
      text: "My Profile ",
      link: "/backend/profile",
    },
    {
      key: "Setting",
      icon: <MdSettingsSuggest color="white"/>,
      text: "Settings",
      link: "/backend/settings",
    },
  
    {
      key: "logout",
      icon: <CiLogout style={{ color: "white", fontWeight: "bold" }} />,
      text: "Logout",
      link: "/login",
    },
  ];

  const menuItemsForRole2: MenuItem[] = [
    {
      key: "profile",
      icon: <BiSlider style={{ color: "white", fontWeight: "bold" }} />,
      text: "My Profile ",
      link: "/backend/profile",
    },
    {
      key: "my-website",
      icon: <FaThemeco style={{ color: "white", fontWeight: "bold" }} />,
      text: "My Website ",
      link: "/backend/mywebsite",
    },
    {
      key: "Favourite",
      icon: <BiSlider style={{ color: "white", fontWeight: "bold" }} />,
      text: "Favourite Businesses",
      link: "/backend/favourites",
    },
    {
      key: "pricingplane",
      icon: <FaCoins style={{ color: "white", fontWeight: "bold" }} />,
      text: "Pricing plans",
      link: "/backend/pricing-plan",
    },
    {
      key: "MyBusinesses",
      icon: <BiSolidBusiness style={{ color: "white", fontWeight: "bold" }} />,
      text: "My Businesses",
      link: "/backend/business-index",
    },
    // {
    //   key: "AddNewBusiness",
    //   icon: <BiSolidBusiness style={{ color: "white", fontWeight: "bold" }} />,
    //   text: "Add New Business",
    //   link: "/backend/AddNewBusiness",
    // },
    // {
    //   key: "Theme 2 Preview",
    //   icon: <BiSlider style={{ color: "white", fontWeight: "bold" }} />,
    //   text: "Theme 2 Preview ",
    //   link: "/backend/themes/2",
    // },
    // {
    //   key: "Theme 3 Preview",
    //   icon: <BiSlider style={{ color: "white", fontWeight: "bold" }} />,
    //   text: "Theme 3 Preview ",
    //   link: "/backend/themes/3",
    // },
    {
      key: "Help",
      icon: <BiSlider style={{ color: "white", fontWeight: "bold" }} />,
      text: "Help",
      link: "/backend/help",
    },
    {
      key: "Logout",
      icon: <CiLogout  style={{ color: "white", fontWeight: "bold" }} />,
      text: "Logout",
      link: "/login",
    },
  ];

  const menuItems = role_id === 1 ? menuItemsForRole1 : menuItemsForRole2;

  useEffect(() => {
    const path = location.pathname;
    const menuItem = menuItems.find((item) => {
      if ((item as SubMenu).children) {
        return (item as SubMenu).children.some((subItem) => subItem.link === path);
      } else {
        return item.link === path;
      }
    });
  
    if (menuItem) {
      const key = menuItem.key;
      setActiveMenuItemKey(key);
      localStorage.setItem('activeMenuItemKey', key);
  
      if ((menuItem as SubMenu)?.children) {
        setOpenKeys([(menuItem as SubMenu).key]);
      }
    }
  }, [location.pathname, menuItems]);
  
  useEffect(() => {
    const savedActiveKey = localStorage.getItem('activeMenuItemKey');
    if (savedActiveKey) {
      setActiveMenuItemKey(savedActiveKey);
      // Optionally set openKeys if needed
    }
  }, []);
  

  const handleOpenChange = (keys: string[]) => {
    setOpenKeys(keys);
  };

  const collapsedWidth = isSmallScreen ? 0 : 60;

  return (
    <>
      {isSmallScreen ? (
        <Drawer
          placement="left"
          closable={false}
          onClose={onCollapse}
          width="200px"
          open={collapsed}
          maskClosable
          style={{ backgroundColor: "#343A40" }}
        >
          <div
            style={{
              top: 0,
              position: "sticky",
              display: "block",
              backgroundColor: "white",
              textAlign: "center",
              zIndex: 99,
            }}
          >
            <img
              src={Logo}
              alt="Image Title"
              style={{
                maxHeight: "70px",
                margin: "auto",
                color: "white",
              }}
            />
          </div>
          <Menu
            mode="inline"
            theme="dark"
            selectedKeys={[activeMenuItemKey || ""]}
            style={{
              color: "white",
              minHeight: "100vh",
            }}
          >
            {menuItems.map((item) => (
              <Menu.Item
                key={item.key}
                icon={item.icon}
                onClick={() => handleClick(item.key)}
                className={activeMenuItemKey === item.key ? "sidebar-menu-active" : "sidebar-menu"}
              >
                <Link to={item.link} style={{ textDecoration: "none" }}>
                  {item.text}
                </Link>
              </Menu.Item>
            ))}
          </Menu>
        </Drawer>
      ) : (
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          collapsedWidth={collapsedWidth}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <div style={{ textAlign: "center", backgroundColor: "white", padding: "25px" }}>
            <Link to="/" style={{ textDecoration: "none" }}>
              <img
                src={Logo}
                alt="ImageLogo"
                style={{ maxHeight: "70px", margin: "auto" }}
              />
            </Link>
          </div>
          <Menu
            mode="inline"
            theme="dark"
            selectedKeys={[activeMenuItemKey || ""]}
            openKeys={openKeys}
            onOpenChange={handleOpenChange}
            style={{ minHeight: "calc(100vh - 70px)" }}
            inlineCollapsed={collapsed}
          >
            {menuItems.map((item) => (
              <Menu.Item
                key={item.key}
                icon={item.icon}
                onClick={() => handleClick(item.key)}
                className={activeMenuItemKey === item.key ? "sidebar-menu-active" : "sidebar-menu"}
              >
                <Link to={item.link} style={{ textDecoration: "none" }}>
                  {item.text}
                </Link>
              </Menu.Item>
            ))}
          </Menu>
        </Sider>
      )}
    </>
  );
};

export default Sidebar;
