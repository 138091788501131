import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Spin } from 'antd';
import { AuthContext , AuthContextProps } from './Pages/Auth/AuthContext';

const PrivateRoute: React.FC<{ children: ReactNode }> = ({ children })  => {
  const { authData } = useContext<AuthContextProps>(AuthContext); // Specify AuthContextProps for useContext
  const [loading, setLoading] = useState<boolean>(true); // Specify boolean type for loading state

  useEffect(() => {
    setLoading(false); 
  }, []);


  if (loading) {
    return <Spin className='d-flex justify-content-center align-items-center' style={{ height: '100vh' }} />;
  }

  return authData.token ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
