import { Card, Col, Form, Input, Row, Select, message, Spin } from 'antd';
import React, { useState } from 'react';
import { BiMinus, BiPlus } from 'react-icons/bi';
import { FaArrowLeft } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { addPackage } from '../../../utils/API';
import { AiOutlineLoading } from 'react-icons/ai';

const Add: React.FC = () => {
  const [loading, setLoading] = useState(false); // State to manage loading indicator
  const navigate = useNavigate();

  const handleFinish = (values: any) => {
    setLoading(true); // Set loading state to true when form is submitted
    addPackage(values)
      .then((response) => {
        if (response.status === 201) {
          message.success('Package added successfully');
          navigate('/backend/packages');
        }
      })
      .catch((error) => {
        message.error(error.response.data.message);
      })
      .finally(() => {
        setLoading(false); // Reset loading state when request is completed (success or failure)
      });
  }

  const pricingOptions = [
    'day',
    'week',
    'month',
    'year',
  ];

  return (
    <>
      <div>
        <Row className="m-2" align="middle">
          <Col span={10}>
            <h2 className="text-2xl font-bold">Add Package</h2>
          </Col>
          <Col span={12}></Col>
          <Col span={2} style={{ textAlign: 'right' }}>
            <Link to="/backend/packages">
              <button className="d-flex justify-content-center bg-yellow-700 text-black btn align-items-center w-100 border btn-brand">
                <FaArrowLeft className="me-2" />
                <div className="ms-2">Back</div>
              </button>
            </Link>
          </Col>
        </Row>
        <Form className="bg-white" onFinish={handleFinish}>
          <Card style={{ backgroundColor: '#ffffff' }}>
            <Row className="border border-warning-2 p-5 bg-white rounded-md" style={{ marginLeft: 0, marginRight: 0 }}>
              <Col span={24}>
                <Row className="bg-white">
                  <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5">
                    <label className="font-bold">
                      Package Name <span className="text-danger">*</span>
                    </label>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="packageName" rules={[{ required: true, message: 'Please Enter package name' }]}>
                      <Input size="large" className="rounded border border-1" placeholder="Package Name" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row className="bg-white">
                  <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5">
                    <label className="font-bold">
                      Price <span className="text-danger">*</span>
                    </label>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="price" rules={[{ required: true, message: 'Please Enter Price' }]}>
                      <Input size="large" className="rounded border border-1" placeholder="Price" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row className="bg-white">
                  <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5">
                    <label className="font-bold">
                      Duration <span className="text-danger">*</span>
                    </label>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="duraction" rules={[{ required: true, message: 'Please select a duration' }]}>
                      <Select size="large" className="rounded border border-1" placeholder="Select duration">
                        {pricingOptions.map(option => (
                          <Select.Option key={option} value={option}>
                            {option}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col span={24} className="d-flex justify-content-center">
                <button type="submit" className="btn bg-yellow-700 text-black" disabled={loading}>
                  {loading ? <Spin indicator={<Spin style={{ fontSize: 24 }}  />} /> : 'Add Package'}
                </button>
              </Col>
            </Row>
          </Card>
        </Form>
      </div>
    </>
  );
};

export default Add;
