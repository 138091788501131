import React, { useEffect, useState, useContext } from "react";
import { Card, Rate, Col, Carousel, message, Modal, Button, notification, Tabs } from "antd";
import { BiPhoneCall } from "react-icons/bi";
import { FaEdit, FaHeart, FaRegHeart, FaRegShareSquare, FaRegThumbsUp, FaPhotoVideo } from "react-icons/fa";
import { EnvironmentOutlined } from "@ant-design/icons";
import { useParams, useNavigate } from "react-router-dom";
import { getBusinessDetail, addReview } from "../../../utils/API";
import { AuthContext } from "../../Auth/AuthContext";
import Edit from "../../backend/Package/Edit";

const { TabPane } = Tabs;

interface BusinessHour {
  closeTime: string;
  openTime: string;
  name: string;
  selected: boolean;
  from: string;
  to: string;
  _id: string;
}

interface Review {
  author: string;
  image: string;
  rating: number;
  comment: string;
  date: string;
}

interface BusinessDetail {
  _id: string;
  pincode: string;
  state: string;
  streetName: string;
  buildingName: string;
  businessHours: BusinessHour[];
  businessName: string;
  mobileNumber: string | string[];
  name: string;
  email: string;
  galleryImage: string[];
  location: string;
  reviews?: Review[];
  rating?: number;
  reviewsCount?: number;
  suggestions?: number;
  userId?: string;
}

const BusinessDetail: React.FC = () => {
  const { authData } = useContext(AuthContext);
  const userId = authData?.user?._id;
  const [businessDetail, setBusinessDetail] = useState<BusinessDetail | null>(null);
  const [isNumberVisible, setIsNumberVisible] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [wishlist, setWishlist] = useState<any[]>([]);
  const { slug } = useParams<{ slug: string }>();
  const imageUrl = process.env.REACT_APP_IMAGE_URL + "/businesse/";
  const navigate = useNavigate();
  const [rating, setRating] = useState(0);
  const [reviewText, setReviewText] = useState('');
  const [error, setError] = useState(null);
  const [slidesToShow, setSlidesToShow] = useState(2);
  const businessId = businessDetail?._id;

  const handleRatingClick = (index: number) => {
    setRating(index + 1); // Update the rating based on the clicked star index
  };
  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    if (!businessId && !userId) {
      message.error("You must be logged in to submit a review.");
      return;
    }
    
    try {
      const data = { userId: userId, rating, reviewText }; // Replace "USER_ID_HERE" with actual user ID
      const response = await addReview(businessId, data);
      console.log(response)
      message.success("Review submitted successfully!");
      setRating(0);
      setReviewText('');
    } catch (err) {
      message.error("Failed to submit review. Please try again later.");
    }
  };
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1200) {
        setSlidesToShow(6);
      } else if (window.innerWidth > 768) {
        setSlidesToShow(4);
      } else if (window.innerWidth > 435) {
        setSlidesToShow(2);
      }
      else {
        setSlidesToShow(1);
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);


  useEffect(() => {
    const handleGetBusinessDetail = async () => {
      try {
        const response = await getBusinessDetail(slug);
        setBusinessDetail(response[0]);
      } catch (error) {
        message.error("Network error. Please try again.");
      }
    };

    if (slug) {
      handleGetBusinessDetail();
    }
  }, [slug]);

  useEffect(() => {
    const savedWishlist = JSON.parse(localStorage.getItem("wishlist") || "[]");
    setWishlist(savedWishlist);
  }, []);

  useEffect(() => {
    localStorage.setItem("wishlist", JSON.stringify(wishlist));
  }, [wishlist]);

  useEffect(() => {
    if (businessDetail) {
      setIsClicked(isInWishlist(businessDetail));
    }
  }, [businessDetail, wishlist]);

  if (!businessDetail) {
    return <div>Loading...</div>;
  }

  const {
    businessHours,
    businessName,
    mobileNumber = [],
    email,
    galleryImage,
    location,
    reviews = [],
    // rating = 2,

    suggestions = 5,
    buildingName,
    streetName,
    state,
    pincode
  } = businessDetail;

  const mobileNumbersArray = Array.isArray(mobileNumber) ? mobileNumber : [mobileNumber];

  const formatTime = (time: string): string => {
    const [hour, minute] = time.split(':').map(Number);
    const period = hour >= 12 ? 'PM' : 'AM';
    const adjustedHour = hour % 12 || 12;
    return `${adjustedHour}:${minute.toString().padStart(2, '0')} ${period}`;
  };

  const formatBusinessHours = (hours: BusinessHour[]): JSX.Element[] => {
    return hours.map(hour => (
      <div key={hour._id}>
        <p>
          {`${hour.name}: ${hour.selected ? `${formatTime(hour.openTime)} - ${formatTime(hour.closeTime)}` : 'Closed'}`}
        </p>
      </div>
    ));
  };

  const isOwner = authData?.user?._id ? (authData.user._id === businessDetail.userId) : null;


  const renderReviews = (filteredReviews: Review[]) => {
    return filteredReviews.length > 0 ? (
      filteredReviews.map((review, index) => (
        <div key={index} className="mb-4">
          <div className="flex items-center">
            <img
              src={review.image}
              alt={review.author}
              className="w-12 h-12 rounded-full mr-4"
            />
            <p className="font-semibold">{review.author}</p>
          </div>
          <div className="flex items-center">
            <Rate
              disabled
              defaultValue={review.rating}
              className="mr-2"
            />
            <span className="text-lg font-semibold pl-2 pr-2">
              {review.rating}
            </span>
          </div>
          <p>{review.comment}</p>
          <p className="text-gray-600">{review.date}</p>
          <div className="flex items-center mt-2">
            <button className="flex text-base items-center justify-center font-semibold space-x-2 px-2 py-1 rounded-md text-black">
              <FaRegThumbsUp className="mr-2" /> Helpful
            </button>
            <button className="flex items-center text-base justify-center font-semibold space-x-2 px-2 py-1 rounded-md text-black ml-2">
              <FaRegShareSquare className="mr-2" /> Share
            </button>
          </div>
        </div>
      ))
    ) : (
      <p className="text-center text-gray-600">No Reviews Found</p>
    );
  };

  const handleToggleNumberVisibility = () => {
    setIsNumberVisible(!isNumberVisible);
  };

  const handleShareClick = () => {
    setIsModalVisible(true);
  };

  const handleCopyToClipboard = () => {
    const url = window.location.href;

    if (navigator.clipboard) {
      navigator.clipboard.writeText(url)
        .then(() => message.success("URL copied to clipboard!"))
        .catch(() => message.error("Failed to copy URL."));
    } else {
      const textarea = document.createElement('textarea');
      textarea.value = url;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand('copy');
        message.success("URL copied to clipboard!");
      } catch (err) {
        message.error("Failed to copy URL.");
      }
      document.body.removeChild(textarea);
    }
  };

  const isInWishlist = (business: any) => {
    return wishlist.some((item: any) => item.slug === business.slug);
  };

  const handleAddToWishlist = (business: any) => {
    if (isInWishlist(business)) {
      handleRemoveFromWishlist(business);
    } else {
      setWishlist((prevWishlist: any) => [...prevWishlist, business]);
      notification.success({
        message: 'Added to Wishlist',
        description: 'This business has been saved successfully!',
        placement: 'top'
      });
    }
  };

  const handleRemoveFromWishlist = (business: any) => {
    setWishlist((prevWishlist: any) => prevWishlist.filter((item: any) => item.slug !== business.slug));
  };

  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  const handleEditRedirect = () => {
    if (businessDetail?._id) {
      navigate(`/business/${businessDetail._id}/edit`);
    } else {
      message.error("Business details are not available. Please try again.");
    }
  };
  const handleEnquiryRedirect = () => {
    navigate("/contact-us");
  };
  const handleButtonClick = () => {
    if (businessDetail?._id) {
      // navigate(`/business/${businessDetail._id}/edit?step=4`);
      navigate(`/business/${businessDetail._id}/editimage`);
    } else {
      message.error("Business details are not available. Please try again.");
    }
  };



  return (
    <div className="bg-gray-100 min-h-screen mt-20">
      <Card>
        <h2 className="text-2xl font-bold mb-2">{businessName}</h2>
        <div className="flex items-center mb-2">
          <Rate disabled defaultValue={rating} className="mr-2" />
          <span className="text-lg font-semibold pl-2 pr-2">{rating}</span>
          {/* <p>{reviewsCount} Ratings</p> */}
          {/* <p className="font-bold pl-2">"Clean vehicle"</p>
          <p className="font-bold pl-2">{suggestions} Suggestions</p> */}
        </div>
        <div className="flex items-center pt-3 pb-2">
          <EnvironmentOutlined size={20} className="pr-2 text-yellow-500" />
          <p>{`${buildingName}, ${streetName}, ${state} ${pincode}`}</p>
        </div>
        <div className="flex items-center">
          <p className="text-sm text-gray-600">{email}</p>
        </div>
        <div className="flex items-center flex-wrap pt-3">
          <Col xs={24} sm={8} md={8} lg={3} xl={3} xxl={3} className="mt-2 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0 mr-2">
            <button
              onClick={handleToggleNumberVisibility}
              className={`flex items-center font-bold justify-center space-x-2 py-1 rounded-md ${isNumberVisible ? 'bg-white border border-gray-300 text-gray-700' : 'bg-black text-yellow-400'} w-full`}
            >
              <BiPhoneCall />
              <span>{isNumberVisible ? mobileNumbersArray.join(', ') : 'Show Number'}</span>
            </button>
          </Col>
          <Col xs={24} sm={8} md={8} lg={3} xl={3} xxl={3} className="mt-2 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0 mr-2">
            <button className="flex items-center justify-center font-bold space-x-2 px-4 py-1 rounded-md bg-yellow-700 text-black w-full"
              onClick={handleEnquiryRedirect}
            >

              <span>send a enquiry</span>
            </button>
          </Col>


          <Col xs={24} sm={8} md={8} lg={5} xl={3} xxl={3} className="mt-2 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0 mr-2">
            <button
              onClick={() => handleAddToWishlist(businessDetail)}
              className="flex items-center justify-center font-bold space-x-2 px-2 py-1 rounded-md border w-full bg-white"
            >
              <div onClick={handleClick} style={{ cursor: 'pointer' }}>
                {isClicked ? (
                  <FaHeart size={18} color="red" className="mr-2" />
                ) : (
                  <FaRegHeart size={18} color="black" className="mr-2" />
                )}
              </div>
              <span className={`text-gray-700 ${isClicked ? 'text-red-500' : ''}`}>Save</span>
            </button>
          </Col>
          <Col xs={24} sm={8} md={8} lg={5} xl={3} xxl={3} className="mt-2 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0 mr-2">
            <button onClick={handleShareClick} className="flex items-center justify-center font-bold space-x-2 px-2 py-1 rounded-md bg-white border border-gray-300 text-gray-700 w-full">
              <FaRegShareSquare size={18} color="blue" />
              <span>Share</span>
            </button>
          </Col>
          {authData?.token && isOwner ? (
            <>
              <Col xs={24} sm={8} md={8} lg={3} xl={3} xxl={3} className="mt-2 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0 mr-2">


                <Button
                  onClick={handleEditRedirect}
                  className="flex items-center justify-center font-bold space-x-2 px-2 py-1 rounded-md bg-white border border-gray-300 text-gray-700 w-full "
                >
                  <FaEdit size={18} color="green" />
                  <span>Edit</span>
                </Button>

              </Col>
              <Col xs={24} sm={8} md={8} lg={3} xl={3} xxl={3} className="mt-2 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0 mr-2">

                <Button
                  onClick={handleButtonClick}
                  className="flex items-center justify-center font-bold space-x-2 px-2 py-1 rounded-md bg-white border border-gray-300 text-gray-700 w-full "
                >
                  <FaPhotoVideo size={18} color="green" />
                  <span>Add Photos </span>
                </Button>

              </Col>
            </>
          ) : null}
        </div>
        <div className="mt-4">
          <h3 className="text-xl font-semibold pt-4 pb-4">Gallery</h3>
          {galleryImage.length > 6 ? (
            <Carousel autoplay slidesToShow={slidesToShow} autoplaySpeed={3000}>
              {galleryImage.map((image, imgIndex) => (
                <img
                  key={imgIndex}
                  className="w-44 h-48 xl:h-48 md:h-60 p-2 lg:h-48 rounded-lg"
                  src={`${imageUrl}${image}`}
                  alt={`Carousel ${imgIndex}`}
                />
              ))}
            </Carousel>
          ) : (
            <div className="grid grid-cols-6 gap-2">
              {galleryImage.map((photo, index) => (
                <img
                  key={index}
                  className="w-44 h-48 xl:h-48 md:h-60 lg:h-48 rounded-lg"
                  src={`${imageUrl}${photo}`}
                  alt={`Carousel ${index}`}
                />
              ))}
            </div>
          )}
        </div>

        <div className="mt-4">
          <h3 className="text-xl font-semibold pt-4 pb-4">Timings</h3>
          <div className="flex text-base">
            <p>{formatBusinessHours(businessHours)}</p>
          </div>
        </div>

        <div className="mt-4">
          <h3 className="text-xl font-semibold pt-4 pb-4">Reviews</h3>
          <div className="flex items-center">
            {/* <div className="text-5xl bg-yellow-700 w-20 rounded-lg p-2">
              {reviewsCount}
            </div> */}
            <span className="text-lg font-semibold pl-2 pr-2">
              Reviews
              <br />
              100% Authenticated & Trusted ratings from net4surge users
            </span>

          </div>
        </div>

        <div className="mt-4">
          <Tabs defaultActiveKey="1">
            <TabPane tab="All Reviews" key="1">
              {renderReviews(reviews)}
            </TabPane>
            <TabPane tab="Positive Reviews" key="2">
              {renderReviews(reviews.filter(review => review.rating >= 4))}
            </TabPane>
            <TabPane tab="Negative Reviews" key="3">
              {renderReviews(reviews.filter(review => review.rating < 4))}
            </TabPane>
          </Tabs>
          <div className="flex justify-end">
  <form className="max-w-lg bg-white p-6 rounded-lg shadow-lg mt-10">
    <h3 className="text-xl font-semibold mb-2">Submit Review</h3>
    <form onSubmit={handleSubmit}>
      <label className="block text-sm font-medium text-gray-700 mb-1">Rating:</label>
      <div className="text-yellow-400 mb-4 text-4xl">
        {Array.from({ length: 5 }, (_, index) => (
          <span
            key={index}
            onClick={() => handleRatingClick(index)}
            style={{ cursor: 'pointer' }}
          >
            {index < rating ? '★' : '☆'}
          </span>
        ))}
      </div>

      <label className="block text-xl font-medium text-gray-700 mb-1">Comment:</label>
      <textarea
        className="w-full p-2 border border-gray-300 rounded-lg mb-4"
        rows={4}
        value={reviewText}
        onChange={(e) => setReviewText(e.target.value)}
      ></textarea>

      {error && <div className="text-red-500 mb-4">{error}</div>}

      <button
        type="submit"
        className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"
      >
        Submit Review
      </button>
    </form>
  </form>
</div>

        </div>
      </Card>

      <Modal
        title="Share this Page"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key="copy" type="primary" className="bg-yellow-700" onClick={handleCopyToClipboard}>
            Copy URL
          </Button>,
          <Button key="close" onClick={() => setIsModalVisible(false)}>
            Close
          </Button>,
        ]}
      >
        <p>{window.location.href}</p>
      </Modal>
    </div>
  );
};

export default BusinessDetail;
