// Setting.tsx
import React, { useEffect, useState } from "react";
import {
  Upload,
  message,
  Row,
  Col,
  Card,
  Form,
  Modal,
  Button,
  Input,
  Image,
} from "antd";
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
} from "antd/lib/upload/interface";
import { GetSettingData, updateSetting } from "../../../utils/API";
import { useNavigate } from "react-router-dom";
import { BiUpload } from "react-icons/bi";

const Setting: React.FC = () => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [logo, setLogo] = useState<UploadFile[]>([]);
  const [favicon, setfavicon] = useState<UploadFile[]>([]);
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string>("");
  const [logopreview, setlogopreview] = useState<string>("");
  const imageLink = process.env.REACT_APP_IMAGE_URL;
  const [faviconpreview, setfaviconpreview] = useState<string>("");
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handleLogoImage = (info: UploadChangeParam<UploadFile<any>>) => {
    const { file } = info;
    // Display preview for the uploaded file
    if (file.originFileObj) {
      const reader = new FileReader();  
      reader.onload = (e) => {
        setlogopreview(e.target?.result as string); // Set preview URL
      };
      reader.readAsDataURL(file.originFileObj);
    } else {
      setlogopreview(null as any); // Clear preview if no file is selected or upload is in progress
    }
    setLogo(info.fileList); // Update fileList state
  };

  const handlefaviconImage = (info: UploadChangeParam<UploadFile<any>>) => {
    const { file } = info;
    if (file.originFileObj) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setfaviconpreview(e.target?.result as string); // Set preview URL
      };
      reader.readAsDataURL(file.originFileObj);
    } else {
      setfaviconpreview(null as any); // Clear preview if no file is selected or upload is in progress
    }
    setfavicon(info.fileList); // Update fileList state
  };

  useEffect(() => {
    GetSettingData()
      .then((response) => {
        form.setFieldsValue(response.settings[0]);
        const logoImage = `${imageLink}/settings/${response.settings[0].logo}`;
        const faviconImage = `${imageLink}/settings/${response.settings[0].favicon}`;

        setlogopreview(logoImage);
        setfaviconpreview(faviconImage);
        // Set fileList for logo and favicon if needed
        setLogo([{ uid: "-1", name: "", status: "done", url: logoImage }]);
        setfavicon([
          { uid: "-1", name: "favicon", status: "done", url: faviconImage },
        ]);
      })
      .catch((error) => {
        console.error("Error fetching setting data:", error);
      });
  }, []);

  const handleSubmit = async () => {
    const id = form.getFieldValue("_id");
    try {
      const formData = new FormData();
      formData.append("siteName", form.getFieldValue("siteName"));
      formData.append("phone", form.getFieldValue("phone"));
      formData.append("address", form.getFieldValue("address"));
      formData.append("aboutus", form.getFieldValue("aboutus"));
      formData.append("contactEmail", form.getFieldValue("contactEmail"));
      formData.append("LinkedinLink", form.getFieldValue("LinkedinLink"));
      formData.append("instagramLink", form.getFieldValue("instagramLink"));
      formData.append("twitterLink", form.getFieldValue("twitterLink"));
      formData.append("copyrightYear", form.getFieldValue("copyrightYear"));
      formData.append("adminEmail", form.getFieldValue("adminEmail"));
      formData.append("seoTitle", form.getFieldValue("seoTitle"));
      formData.append("metaKeywords", form.getFieldValue("metaKeywords"));
      formData.append("metaDescription", form.getFieldValue("metaDescription"));
      formData.append("canonical", form.getFieldValue("canonical"));
      formData.append(
        "googleTagManager",
        form.getFieldValue("googleTagManager")
      ); 

      // Append logo if it exists
      if (logo.length > 0 && logo[0].originFileObj) {
        formData.append("logo", logo[0].originFileObj as Blob);
      }

      // Append favicon if it exists
      if (favicon.length > 0 && favicon[0].originFileObj) {
        formData.append("favicon", favicon[0].originFileObj as Blob);
      }

      const response = await updateSetting(id, formData);
   
      message.success(response.data.message);
      GetSettingData();
      navigate("/backend/settings");
    } catch (error) {
      console.error("Update failed:", error);
    }
  };

  return (
    <div className="">
      <Row className="m-2" align="middle">
        <Col span={18}>
          <h2 className="text-2xl font-bold">Settings</h2>
        </Col>
      </Row>
      <Form className="bg-white" onFinish={handleSubmit} form={form}>
        <Card style={{ backgroundColor: "#ffffff", borderRadius: "5px" }}>
          <Row
            className="border border-warning-2 p-5 bg-white rounded-md"
            style={{ marginLeft: 0, marginRight: 0 }}
          >
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  span={24}
                  className="d-flex justify-content-start align-items-center"
                ></Col>
                <Col span={24}>
                  <Row>
                    <Col
                      span={4}
                      className="d-flex justify-content-start me-4 bg-white mb-5  "
                    >
                      <label className="font-bold">
                        Site Name <span className="text-danger">*</span>
                      </label>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="siteName"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Site name",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          className="rounded border border-1"
                          placeholder="Site name"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
  <Row>
    <Col
      span={4}
      className="d-flex justify-content-start me-4 bg-white mb-5"
    >
      <label className="font-bold">
        Phone <span className="text-danger">*</span>
      </label>
    </Col>
    <Col span={12}>
      <Form.Item
        name="phone"
        rules={[
          {
            required: true,
            message: "Please Enter Phone",
          },
          {
            validator: (_, value) => 
              value && value < 0 ? Promise.reject('Only positive numbers are allowed') : Promise.resolve(),
          },
        ]}
      >
        <Input
          type="number"
          min="0"
          size="large"
          className="rounded border border-1"
          placeholder="phone"
        />
      </Form.Item>
    </Col>
  </Row>
</Col>

                <Col span={24}>
                  <Row>
                    <Col
                      span={4}
                      className="d-flex justify-content-start me-4 bg-white mb-5  "
                    >
                      <label className="font-bold">
                        Address<span className="text-danger">*</span>
                      </label>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="address"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Site Address",
                          },
                        ]}
                      >
                        <Input.TextArea
                          autoSize={{ minRows: 3, maxRows: 5 }}
                          className="rounded border border-1"
                          placeholder="Site address"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col
                      span={4}
                      className="d-flex justify-content-start me-4 bg-white mb-5  "
                    >
                      <label className="font-bold">
                        About us <span className="text-danger">*</span>
                      </label>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="aboutus"
                        rules={[
                          {
                            required: true,
                            message: "Please Tell some about us",
                          },
                        ]}
                      >
                        <Input.TextArea
                          autoSize={{ minRows: 3, maxRows: 5 }}
                          className="rounded border border-1"
                          placeholder="About us"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col
                      span={4}
                      className="d-flex justify-content-start me-4 bg-white mb-5  "
                    >
                      <label className="font-bold">
                        Contact email <span className="text-danger">*</span>
                      </label>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="contactEmail"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Contact Email",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          className="rounded border border-1"
                          placeholder="Contact email"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col
                      span={4}
                      className="d-flex justify-content-start me-4 bg-white mb-5  "
                    >
                      <label className="font-bold">
                        Linkedin <span className="text-danger">*</span>
                      </label>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="linkedinLink"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Linkedin Link",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          className="rounded border border-1"
                          placeholder="Linkedin Link"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                   <Col span={24}>
                  <Row>
                    <Col
                      span={4}
                      className="d-flex justify-content-start me-4 bg-white mb-5  "
                    >
                      <label className="font-bold">
                        Facebook <span className="text-danger">*</span>
                      </label>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="facebookLink"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Facebook Link",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          className="rounded border border-1"
                          placeholder="Facebook Link"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col
                      span={4}
                      className="d-flex justify-content-start me-4 bg-white mb-5  "
                    >
                      <label className="font-bold">
                        Instagram <span className="text-danger">*</span>
                      </label>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="instagramLink"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Instagram Link",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          className="rounded border border-1"
                          placeholder="Instagram Link"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col
                      span={4}
                      className="d-flex justify-content-start me-4 bg-white mb-5  "
                    >
                      <label className="font-bold">
                        TwitterLink <span className="text-danger">*</span>
                      </label>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="twitterLink"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Twitter Link",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          className="rounded border border-1"
                          placeholder="Twitter Link"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col
                      span={4}
                      className="d-flex justify-content-start me-4 bg-white mb-5  "
                    >
                      <label className="font-bold">
                        Copyright Year <span className="text-danger">*</span>
                      </label>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="copyrightYear"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Copyright Year",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          className="rounded border border-1"
                          placeholder="Copyright Year"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col
                      span={4}
                      className="d-flex justify-content-start me-4 bg-white mb-5  "
                    >
                      <label className="font-bold">
                        Admin Email <span className="text-danger">*</span>
                      </label>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="adminEmail"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter admin email",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          className="rounded border border-1"
                          placeholder="Admin email"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col
                      span={4}
                      className="d-flex justify-content-start me-4 bg-white mb-5"
                    >
                      <label className="font-bold">
                        Logo <span className="text-danger">*</span>
                      </label>
                    </Col>
                    <Col xs={24} lg={12}>
                      <Upload
                        name="logoimage"
                        action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                        fileList={logo}
                        onChange={handleLogoImage}
                        maxCount={1}
                        accept=".png, .jpg, .jpeg"
                        beforeUpload={(file) => {
                          // Validate file type and size
                          const isJpgOrPng =
                            file.type === "image/jpeg" ||
                            file.type === "image/png";
                          if (!isJpgOrPng) {
                            message.error("You can only upload JPG/PNG file!");
                          }
                          const isLt2M = file.size / 1024 / 1024 < 1;
                          if (!isLt2M) {
                            message.error("Image must be smaller than 1MB!");
                          }
                          return isJpgOrPng && isLt2M;
                        }}
                      >
                        <Button icon={<BiUpload />}>Upload</Button>
                      </Upload>
                      {logo.length > 0 && (
                        <div style={{ marginTop: "10px" }}>
                          <Image
                            src={logopreview}
                            alt="Preview"
                            style={{ maxWidth: "100%", height: "100px" }}
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col
                      span={4}
                      className="d-flex justify-content-start me-4 bg-white mb-5"
                    >
                      <label className="font-bold">
                        Favicon <span className="text-danger">*</span>
                      </label>
                    </Col>
                    <Col span={12}>
                      <Upload
                        name="faviconimage"
                        action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                        fileList={favicon}
                        onChange={handlefaviconImage}
                        maxCount={1}
                        accept=".png, .jpg, .jpeg"
                        beforeUpload={(file) => {
                          const isJpgOrPng =
                            file.type === "image/jpeg" ||
                            file.type === "image/png";
                          if (!isJpgOrPng) {
                            message.error("You can only upload JPG/PNG file!");
                          }
                          const isLt2M = file.size / 1024 / 1024 < 1;
                          if (!isLt2M) {
                            message.error("Image must be smaller than 1MB!");
                          }
                          return isJpgOrPng && isLt2M;
                        }}
                      >
                        <div>
                          <Button icon={<BiUpload />}>Upload</Button>
                        </div>
                      </Upload>
                      {favicon.length > 0 && (
                        <div style={{ marginTop: "10px" }}>
                          <Image
                            src={faviconpreview}
                            alt="Preview"
                            style={{ width: "100%", height: "100px" }}
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col
                      span={4}
                      className="d-flex justify-content-start me-4 bg-white mb-5  "
                    >
                      <label className="font-bold">
                        SEO title <span className="text-danger">*</span>
                      </label>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="seoTitle"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Title",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          className="rounded border border-1"
                          placeholder="SEO title"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col
                      span={4}
                      className="d-flex justify-content-start me-4 bg-white mb-5  "
                    >
                      <label className="font-bold">
                        Meta Keyword <span className="text-danger">*</span>
                      </label>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="metaKeywords"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Meta Keyword",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          className="rounded border border-1"
                          placeholder="Meta Keyword"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col
                      span={4}
                      className="d-flex justify-content-start me-4 bg-white mb-5  "
                    >
                      <label className="font-bold">
                        Meta Description <span className="text-danger">*</span>
                      </label>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="metaDescription"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Meta Description",
                          },
                        ]}
                      >
                        <textarea
                          className="rounded border border-1"
                          placeholder="Meta Description"
                          rows={2} cols={55}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                
                  <Row>
                    <Col
                      span={4}
                      className="d-flex justify-content-start me-4 bg-white mb-5  "
                    >
                      <label className="font-bold">
                        Canonical <span className="text-danger">*</span>
                      </label>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="canonical"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Canonical",
                          },
                        ]}
                      >
                        <textarea
                          className="rounded border border-1"
                          placeholder="Canonical"
                          rows={2} cols={55}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col
                      span={4}
                      className="d-flex justify-content-start me-4 bg-white mb-5  "
                    >
                      <label className="font-bold">
                        Google tag Manager{" "}
                        <span className="text-danger">*</span>
                      </label>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="metaDescription"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Google tag ",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          className="rounded border border-1"
                          placeholder="Google tag "
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24} className="d-flex justify-content-center">
                  <button className="p-2 bg-yellow-700  text-black rounded-md" type="submit">
                    Submit
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default Setting;
