import { Card, Row, Table, Col, Input, message, Spin, Modal, Button, Image } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import { RiAddBoxFill } from "react-icons/ri";
import { deletetestimonial, getAllTestimonials } from "../../../utils/API";
import { set } from "@ant-design/plots/es/core/utils";

interface Testimonial {
  [x: string]: any;
  _id: string;
  name: string;
  image: string;
  description: string;
  createdAt: string;
  updatedAt: string;
}

export const TestimonialTable = () => {
  const [datasource, setDatasource] = useState<Testimonial[]>([]);
  const [loading, setLoading] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const imageLink = process.env.REACT_APP_IMAGE_URL || '';

  const fetchTestimonials = async () => {
    setLoading(true);
    try {
      const response = await getAllTestimonials();
      // Assuming response is an array of Testimonial objects
      setDatasource(response.filter((item: Testimonial) => !item.isDeleted));
    } catch (error) {
      message.error("Network error. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTestimonials();
  }, []);

  const handleEditRedirect = (record: Testimonial) => {
    navigate(`/backend/testimonial/${record._id}/edit`);
  };

  const handleDeleteConfirmation = (id: string) => {
    setDeleteId(id);
  }; 
  const handleDelete = async () => {
    if (deleteId) {
      try {
        await deletetestimonial(deleteId);
        message.success("Testimonial deleted successfully");
        fetchTestimonials();
      } catch (error) {
        message.error("Failed to delete Testimonial");
      } finally {
        setDeleteId(null);
      }
    }
  };

  const columns = [
    {
      title: (
        <div style={{ fontWeight: "bold", fontSize: "10px", color: "#a6a6a6" }}>
          Images
        </div>
      ),
      dataIndex: "image",
      width: "10%",
      key: "image",
      render: (image: string) => (
        <div>
          {image && (
            <Image
              src={`${imageLink}/testimonial/${image}`}
              alt="Thumbnail"
              style={{ width: "50px", height: "50px", marginRight: "5px" }}
            />
          )}
        </div>
      ),
    },
    
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: string) => text || "-",
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "50%",
      key: "description",
      render: (text: string) => text || "-",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text: any, record: Testimonial) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <FaEdit className="editMenuIcon" onClick={() => handleEditRedirect(record)} />
          <AiFillDelete className="editMenuIcon" onClick={() => handleDeleteConfirmation(record._id)} />
        </div>
      ),
    },
  ];

  const handleSearch = (value: string) => {
    setSearchText(value);
    // Assuming you want to filter the table by search text
    setDatasource(prevData => prevData.filter(item => item.name.toLowerCase().includes(value.toLowerCase())));
  };

  return (
    <div>
      <Row className="m-2" align="middle">
        <Col xs={24} sm={24} md={9} xl={6} xxl={6} className="d-flex justify-content-start font-bold">
          <h2 className="text-2xl">Testimonial</h2>
        </Col>
        <Col xs={24} sm={24} md={24} xl={18} xxl={18}>
          <Row gutter={16} className="float-center xs:m-2">
            <Col xs={0} sm={0} md={0} xl={7} xxl={7}></Col>
            <Col xs={24} sm={24} md={10} xl={12} xxl={12} className="">
              <Input
                prefix={<SearchOutlined style={{ color: "#a6a6a6" }} />}
                size="large"
                className="float-end w-75"
                placeholder="Search..."
                allowClear
                autoComplete="off"
                onChange={(e) => handleSearch(e.target.value)}
              />
            </Col>
            <Col xs={24} sm={24} md={6} xl={5} xxl={5} style={{ paddingRight: "-8px !important" }}>
              <Link to="/backend/testimonial/add">
                <button className="d-flex justify-content-center align-items-center w-100 border btn-brand">
                  <RiAddBoxFill style={{ fontSize: "15px" }} />
                  <div className="ms-2">Add Testimonial</div>
                </button>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Card className="container w-100">
          <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
            <Spin spinning={loading}>
              <Table columns={columns} dataSource={datasource} />
            </Spin>
          </Col>
        </Card>
      </Row>
      <Modal
        title="Confirmation"
        visible={!!deleteId}
        onOk={handleDelete}
        onCancel={() => setDeleteId(null)}
        okText="Yes"
        footer={null}
        cancelText="No"
      >
        <p>Are you sure you want to delete this testimonial?</p>
        <div style={{ textAlign: "end" }}>
          <Button onClick={handleDelete} className="rounded" style={{ backgroundColor: "orange", color: "white", marginRight: "1rem" }}>
            Yes
          </Button>
          <Button onClick={() => setDeleteId(null)} className="rounded bg-red-500 text-white">
            No
          </Button>
        </div>
      </Modal>
    </div>
  );
};
