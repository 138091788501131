
import Home from "./Homepage";
import Banner from "./Banner";
import LatestListing from "./LatestListing";
import GetStartedWithUs from "./GetStartedWithUs";
import HowWorks from "./HowWorks";
import PopularLocation from "./PopularLocation";
import LestinOffer from "./LestinOffer";
import FeaturedListing from "./FeaturedListing";
import Reviews from "./Reviews";
import Counter from "./Counter";
import DownloadLink from "./DownloadLink";
import Blog from "./Blog";
import Categories from "./Categories";
import Justdail from "./NewBusiness";

const DashboardUser = () => {
  return (
    <>
    
      <Home />
      <Categories />
      <LatestListing />
      <GetStartedWithUs />
      <HowWorks />
      <PopularLocation />
      <LestinOffer />
      <FeaturedListing />
      <Reviews />
      <Counter />
      <DownloadLink />
      <Blog />
    </>
  );
};

export default DashboardUser;
