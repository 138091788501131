import { Categorytable } from "../Pages/backend/Category";
import { AddCategory } from "../Pages/backend/Category/Add";
import { EditCategory } from "../Pages/backend/Category/Edit";
import Dashboard from "../Pages/backend/Dashboard/Dashboard";
import CustomerTable from "../Pages/User";
import CustomerMasterForm from "../Pages/User/Add";
import CustomerEditForm from "../Pages/User/Edit";
import Setting from "../Pages/backend/Setting/Setting";
import { Packagetable } from "../Pages/backend/Package";
import EditProfile from "../Pages/backend/Profile/EditProfile";
import Add from "../Pages/backend/Package/Add";
import ManagePassword from "../Pages/frontend/Listing/ManagePassword";
import Tabel from "../Pages/backend/Contacts/Tabel";
import Contacts from "../Pages/backend/Contacts/Tabel";
import EditPackage from "../Pages/backend/Package/Edit";
import WebsiteReview from "../Pages/backend/Websites Reviws/WebsiteReveiws";
import path from "path";
import Businesslist from "../Pages/frontend/Listing/business";
import justdail from "../Pages/frontend/Home/NewBusiness";
import PricingPlan from "../Pages/frontend/Home/PricingPlan";
import { Newsletter } from "../Pages/backend/NewsLetter/newsLetter";
import NewBusiness from "../Pages/frontend/Home/NewBusiness";
import BusinesslistMLM from "../Pages/frontend/Listing/business/MLMindex";
import FavouriteBusinesses from "../Pages/backend/UserMenu/FavouriteBusinesses";
import Help from "../Pages/backend/UserMenu/Help";
import logout from "../Pages/backend/UserMenu/Logout";
import Pricingplan from "../Pages/backend/UserMenu/Pricingplan";
import WebsiteSeo  from "../Pages/frontend/Home/WebsiteSeo"
import Theme1HomePage from "../Pages/Themes/Theme1/Homepage";
import TemplateForm from "../Pages/Themes/ThemeForm";
import Businessindex from "../Pages/frontend/Home/Businessindex";
import Logout from "../Pages/backend/UserMenu/Logout";
import SecurityAndPrivacy from "../Pages/frontend/Help";
import { TestimonialTable } from "../Pages/backend/Testimonial/Index";
import { AddTestimonials } from "../Pages/backend/Testimonial/Add.tsx/Add";
import { EditTestimonial } from "../Pages/backend/Testimonial/Edit";
import Theme2Homepage from "../Pages/Themes/Theme2/Home";
import Theme3Homepage from "../Pages/Themes/Theme3/Home";
import ThemeForm1 from "../Pages/Themes/ThemeForm1";
import ThemeForm2 from "../Pages/Themes/ThemeForm2";
import ThemeForm3 from "../Pages/Themes/ThemeForm3";
import ThemeForm4 from "../Pages/Themes/ThemeForm4";
import ThemeForm5 from "../Pages/Themes/ThemeForm5";



const backendRoutes = [
    {
        path: "/backend/dashboard",
        name: "Dashboard",
        component: Dashboard,
        exact: true,
    },
   {
       path : "/backend/profile",
       name : "Profile",
       component : EditProfile,
       exact : true
   },
    // {
    //     path : "/backend/users",
    //     name : "Users",
    //     component : CustomerTable,
    //     exact : true
    // },
    {
        path : "/backend/password",
        name : "Passwords",
        component : ManagePassword ,
        exact : true
    },
    // {
    //     path : "/backend/users/add",
    //     name : "Add User",
    //     component : CustomerMasterForm,
    //     exact : true
    // },
    // {
    //     path : "/backend/users/:id/edit",
    //     name : "Edit User",
    //     component : CustomerEditForm,
    //     exact : true
    // },
   
    {
        path : "/backend/categories",
        name : "Logout",
        component : Categorytable,
        exact : true
    },
    {
        path  : "/backend/categories/add",
        name :"Category",
        component : AddCategory,
        exact : true 
    },
    {
        path : "/backend/categories/:id/edit",
        name : "Edit Category",
        component : EditCategory,
        exact : true
    },
    {
        path : "/backend/categories/:id/edit",
        name : "Edit Product",
        component : EditCategory,
        exact : true
    },
    {
        path : "/backend/categories/add",
        name : "Edit Product",
        component : AddCategory,
        exact : true
    },
    {
        path : "/backend/categories",
        name : "Edit Product",
        component : Categorytable,
        exact : true
    },
    {
        path : "/backend/settings",
        name : "Edit Setting",
        component : Setting,
        exact : true
    },
    {
        path : "/backend/packages",
        name : "Package",
        component : Packagetable,
        exact : true
    },
    {
        path : "/backend/package/add",
        name : "Add Package",
        component : Add,
        exact : true
    },
    {
        path : "/backend/package/:id/edit",
        name : "Edit Package",
        component : EditPackage,
        exact : true
    },
    {
        path  : "/backend/category/add",
        name :"Category",
        component : AddCategory,
        exact : true 
    },
    {
        path : "/backend/category/:id/edit",
        name : "Edit Category",
        component : EditCategory,
        exact : true
    },
    {
        path : "/backend/inquiries",
        name : "Contacts",
        component : Contacts,
        exact : true
    } ,

     {
        path : "/backend/newsLetters",
        name : "Contacts",
        component : Newsletter,
        exact : true
     },

     {
        path : "/backend/WebsiteReviews",
        name : "Contacts",
        component : WebsiteReview,
        exact : true
     },
     {
        path : "/backend/manage-business",
        name : "business",
        component : Businesslist,
        exact : true
     },
     {
        path : "/backend/business-index",
        name : "business-index",
        component : Businessindex,
        exact : true
     },
     {
        path : "/backend/mlm-business",
        name : "business",
        component : BusinesslistMLM,
        exact : true
     },
     {
        path : "/backend/newbusiness",
        name : "Justdail",
        component : NewBusiness,
        exact : true
     },
     {
        path : "/backend/pricing-plan",
        name : "PricingPlan",
        component : PricingPlan , 
        exact : true
     },

     {
        path : "/backend/favourites",
        name : "Favourites",
        component : FavouriteBusinesses , 
        exact : true
     },
     {
        path : "/backend/help",
        name :"Business",
        component : SecurityAndPrivacy,
        exact : true
      },
      {
        path :"/backend/testimonials",
        name : "Testimonials",
        component : TestimonialTable,
        exact : true
    } , 
    {
        path :"/backend/testimonial/add",
        name : "Testimonials",
        component : AddTestimonials,
        exact : true
    } , 
    {
        path :"/backend/testimonial/:id/edit",
        name : "Testimonials",
        component : EditTestimonial,
        exact : true
    },
     {
        path : "/backend/Logout",
        name : "Logout",
        component :Logout , 
        exact : true
     },
     {
        path : "/backend/webseo",
        name : "WebsiteSeo",
        component :WebsiteSeo , 
        exact : true
     },
     {
        path : "/backend/mywebsite",
        name : "Theme1",
        component :Theme1HomePage , 
        exact : true
     },
   
    // {
    //      path : "/backend/themes/:index",
    //      name : "theme-form",
    //      component :TemplateForm, 
    //      exact : true
    // },
    {
         path : "/backend/themes/1",
         name : "theme-form1",
         component :ThemeForm1, 
         exact : true
    },
    {
         path : "/backend/themes/2",
         name : "theme-form2",
         component :ThemeForm2, 
         exact : true
    },
    {
         path : "/backend/themes/3",
         name : "theme-form3",
         component :ThemeForm3, 
         exact : true
    },
    {
         path : "/backend/themes/4",
         name : "theme-form4",
         component :ThemeForm4, 
         exact : true
    },
    {
         path : "/backend/themes/5",
         name : "theme-form5",
         component :ThemeForm5, 
         exact : true
    },

];

export { backendRoutes };