import React from 'react';
import { Row, Col } from 'antd';
import { ArrowRightOutlined, MobileOutlined, EnvironmentOutlined } from '@ant-design/icons';
import './ListingBenefit.css';

const ListingsBenefits = () => {
  return (
    <div className="container mx-auto mb-10 p-4">
      <Row gutter={[64, 16]} className="relative lg:px-0 lg:ps-0 xl:px-12 xl:ps-12 md:px-0 md:ps-0 ">
        <Col xs={24} sm={24} md={12} lg={12} xl={12} className="relative mb-20 md:mb-0">
          <div className="relative h-96 ">
            <img
              src="https://gaviaspreview.com/wp/lestin/wp-content/uploads/2023/08/image-12.png"
              alt="Overlay Background"
              className="absolute inset-0 z-0 -left-20 xl:w-90 xl:h-88 xl:left-0 md:-left-20 object-cover"
            />
            <img
              src="https://gaviaspreview.com/wp/lestin/wp-content/uploads/2023/08/image-10.jpg"
              alt="City View"
              className="absolute z-1 w-88 h-78 top-16  lg:top-16 xl:left-36 xl:top-16 xl:w-88 xl:h-88 md:w-78 md:h-78 md:left-2 md:top-20 rounded-lg transform transition-transform duration-700 ease-in-out hover:scale-105 hover:rotate-6"
              
            />
            <img
              src="https://gaviaspreview.com/wp/lestin/wp-content/uploads/2023/08/image-11.jpg"
              alt="Person Making Coffee"
              className="absolute z-2 top-20 -right-4 lg:top-40 xl:top-40 xl:right-2 xl:w-56 xl:h-72 md:w-56 md:h-80 md:-right-6 md:top-32 rounded-lg transform transition-transform duration-700 ease-in-out hover:scale-105 hover:rotate-6"
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={10} className="px-4 py-10 ">
          <h3 className="text-yellow-500 text-sm font-bold mb-3 tracking-wider">LISTINGS BENEFITS</h3>
          <h2 className="text-3xl lg:text-4xl xl:text-5xl md:text-2xl font-extrabold mb-4">Why you should use Lestin platform</h2>
          <p className="text-base md:text-base leading-relaxed text-gray-500 mb-6">
            There are many variations of passages of available but the majority have suffered.
            Alteration in some form, lipsum is simply free text by injected humour or randomised
            words even believable.
          </p>
          <div className="mb-4 flex items-center justify-between">
            <div className="flex items-start">
              <MobileOutlined className="text-yellow-500 text-5xl md:text-5xl mr-2" />
              <div>
                <h4 className="text-sm font-bold text-gray-500 mb-1">BENEFIT 01</h4>
                <h3 className="text-lg md:text-lg font-semibold">Easy & Fast Listings</h3>
              </div>
            </div>
            <div className="arrow-container ">
              <ArrowRightOutlined className="arrow-icon text-black" />
            </div>
          </div>
          <hr />
          <div className="flex items-center justify-between mt-4">
            <div className="flex items-start">
              <EnvironmentOutlined className="text-yellow-500 text-5xl md:text-5xl mr-2" />
              <div>
                <h4 className="text-sm font-bold text-gray-500 mb-1">BENEFIT 02</h4>
                <h3 className="text-lg md:text-lg font-semibold">Many Different Locations</h3>
              </div>
            </div>
            <div className="arrow-container">
              <ArrowRightOutlined className="arrow-icon text-black" />
            </div>
          </div>
          <hr className="mt-4" />
        </Col>
      </Row>
    </div>
  );
};

export default ListingsBenefits;
