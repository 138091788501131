import React from 'react';
import { Collapse, Card, Button } from 'antd';

const { Panel } = Collapse;

const data = [
 
  {
    question: 'My contact number wrongly linked to a Net4Surge listing',
    answer: 'Please contact our support team to correct the contact number linked to your listing.',
    link: 'https://www.net4surge.com/support'
  },
  {
    question: 'Edit profile information',
    answer: 'You can edit your profile information by going to the profile section in your account settings.',
    link: '/backend/profile'
  },
  {
    question: 'Profile deactivation',
    answer: 'You can deactivate your profile by submitting a request through the profile deactivation section in your account settings.',
    link: '/backend/profile'
  },
  {
    question: 'Raise a Concern',
    answer: 'To raise a concern, please visit our support page and fill out the concern form.',
    link: '/contact'
  },
  {
    question: 'Want to talk to Sales team',
    answer: 'You can contact our sales team by calling our customer service number or filling out the contact form on our website.',
    link: '/contact'
  },
];

const SecurityAndPrivacy = () => {
  return (
    <div className="flex justify-center items-center bg-gray-100">
      <Card className="w-full  p-4">
        <h2 className="text-xl font-semibold mb-4">Security and Privacy</h2>
        <Collapse accordion>
          {data.map((item : any, index : any) => (
            <Panel header={<span className="font-bold">{item.question}</span>} key={index}>
              <p className='p-2'>{item.answer}</p>
              {/* <Button type="primary" href={item.link} target="_blank">Go to</Button> */}
            </Panel>
          ))}
        </Collapse>
      </Card>
    </div>
  );
};

export default SecurityAndPrivacy;
