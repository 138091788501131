import React, { useContext } from "react";
import { Col, Row, Form, Input, message, Card } from "antd";
import { useNavigate } from "react-router-dom";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";
import { changePassword } from "../../../utils/API";
import { AuthContext } from "../../Auth/AuthContext";

const ManagePassword: React.FC = () => {
  const navigate = useNavigate();

  const [form] = Form.useForm(); // Use Form hook to get form instance
  const [isLoading, setIsLoading] = React.useState(false);
  const { authData } = useContext(AuthContext);
  // access first name from authData
  const currentuserid = authData?.user._id || '';
  const id = currentuserid.replace(/\D/g, '');

  const validatePassword = (_: any, value: any) => {
    // Password should contain at least one uppercase letter, one lowercase letter, one digit, and one special character
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!value) {
      return Promise.reject("Please enter your password");
    }
    if (!passwordRegex.test(value)) {
      return Promise.reject(
        "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character"
      );
    }
    return Promise.resolve();
  };




  const validateConfirmPassword = (_: any, value: any) => {
    const newPassword = form.getFieldValue("newPassword");
    if (value !== newPassword) {
      return Promise.reject("The passwords do not match");
    }
    return Promise.resolve();
  };


  const handleSubmit = async (values: any) => {
    const currentId = id
    if (currentId) {
      setIsLoading(true);
      try {
        const response = await changePassword(currentId, values);
        setIsLoading(false);
        if (response && response.data && response.data.message) {
          message.success(response.data.message);
          form.resetFields();
        } else {
          message.success("Password updated successfully");
          form.setFieldsValue({ password: "" });
        }
      } catch (error) {
        setIsLoading(false);
        message.error("Failed to submit data. Please try again.");
      }
    } else {
      setIsLoading(false);
      message.error("Failed Fetch data. Please try again.");
    }
  };

  return (
    <div className="">
      <Row className="m-2" align="middle">
        <Col span={18}>
          <h2 className="text-2xl font-bold">Profile</h2>
        </Col>
      </Row>
      <Form onFinish={handleSubmit} form={form} className="bg-white">
        <Card style={{ backgroundColor: "#ffffff" }}>
          <Row
            className="border border-warning-2 p-5 bg-white rounded-md"
            style={{ marginLeft: 0, marginRight: 0 }}
          >
            {/* Current Password */}
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  span={4}
                  className="d-flex justify-content-start me-4 bg-white mb-5  "
                >
                  <label className="font-bold">
                    Old Password<span className="text-danger">*</span>
                  </label>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="oldPassword"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Old Password",
                      },
                    ]}
                  >
                    <Input.Password
                      size="large"
                      className="rounded border border-1"
                      placeholder="Old Password"
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            {/* New Password */}
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  span={4}
                  className="d-flex justify-content-start me-4 bg-white mb-5  "
                >
                  <label className="font-bold">
                    New Password<span className="text-danger">*</span>
                  </label>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="newPassword"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter New Password",
                      },
                    
                    ]}
                  >
                    <Input.Password
                      size="large"
                      className="rounded border border-1"
                      placeholder="New Password"
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            {/* Confirm Password */}
            <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
              <Row className="bg-white">
                <Col
                  span={4}
                  className="d-flex justify-content-start me-4 bg-white mb-5  "
                >
                  <label className="font-bold">
                    Confirm Password<span className="text-danger">*</span>
                  </label>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="confirm_password"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Confirm Password",
                      },
                   
                    ]}
                  >
                    <Input.Password
                      size="large"
                      className="rounded border border-1"
                      placeholder="Confirm Password"
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={24}
              xl={24}
              xxl={24}
              className="d-flex justify-content-center"
            >
              <button className="btn-brand" disabled={isLoading}>
                {isLoading ? "Updating..." : "Update"}
              </button>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default ManagePassword;
