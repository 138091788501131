import React, { useState, ChangeEvent, useEffect } from 'react';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { templeteform, templetedata, servicegetuserwise, serviceadd, serviceedit, serviceupdate, servicedelete, domainadd, productdelete, teamdatadelete, teamgetuserwise, templateupdate, domainnamecheak, addTeamMember, updateTeamMember, teamedit, seoadd } from '../../utils/API';
import { Button, Input, message, Space, Table, Tabs, Upload, Form, Modal } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BiUpload } from 'react-icons/bi';
import TextArea from 'antd/es/input/TextArea';

const { TabPane } = Tabs;

type ProductDataType = {
  productImage: any;
  _id: any;
  name: string;
  description: string;
  image: File | null;
};

// Define the type for a team member
interface TeamMember {
  name: string;
  designation: string;
  image: File | null;
}

const ThemeForm1: React.FC = () => {
  const navigate = useNavigate();
  const imageUrl = process.env.REACT_APP_IMAGE_URL + '/template/';
  const imageUrlservice = process.env.REACT_APP_IMAGE_URL + '/service/';
  const imageUrlteammember = process.env.REACT_APP_IMAGE_URL + '/team/';

  const [formData, setFormData] = useState<any>({
    templateId: 1,
    header: {
      title: '',
      description: '',
      buttonText: '',
      buttonLink: '',
      logo: null,
      themecolore: '',
    },
    section: {
      title: '',
      description: '',
      banner: null,
    },
    about: {
      title: '',
      description: '',
      image: ''
    },
    footer: {
      about: '',
      address: '',
      email: '',
      phone: '',
      instagram: '',
      facebook: '',
      twitter: '',
      linkedin: '',
    },
    domainName: '',
    metaName: '',
    metaTitle: '',
    metaDescription: ''
  });

  const [activeTab, setActiveTab] = useState<string>('1');
  const [visibleSection, setVisibleSection] = useState<string | null>(null);
  const [serviceData, setServiceData] = useState<any>({ _id: '', name: '', description: '', serviceImage: '', price: '' });
  const [teamData, setTeamData] = useState<any>({ _id: '', name: '', description: '', profileImage: '' });
  const [services, setServices] = useState<any[]>([]);
  const [domainData, setDomainData] = useState('');
  const [isDomainAvailable, setIsDomainAvailable] = useState(false);
  const [logoPreview, setLogoPreview] = useState<any>(null);
  const [bannerPreview, setbannerPreview] = useState<any>(null);
  const [aboutImagePreview, setAboutImagePreview] = useState<any>(null);
  const [validationError, setValidationError] = useState('');
  const [isDomainValid, setIsDomainValid] = useState(true);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
  const [modalVisible, setModalVisible] = useState(false);


  const handleInputChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setTeamData((prevData: any) => ({
      ...prevData,
      [name]: value
    }));
  };


  const columnsteam = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Designation',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Image',
      dataIndex: 'profileImage',
      key: 'profileImage',
      render: (text: any, record: any) => (
        record.profileImage && (
          <img
            src={`${imageUrlteammember}${record.profileImage}`}
            alt="Product"
            className="w-24 h-24 object-cover"
          />
        )
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: any) => (
        <Space size="middle">
          <Button type="primary" onClick={() => handleEditteam(record._id)} className="mr-2 bg-yellow-500">
            <FontAwesomeIcon icon={faEdit} />
          </Button>
          <Button type="primary" danger onClick={() => handleRemoveteam(record._id)}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </Space>
      ),
    },

  ];
  const [errors, setErrors] = useState({
    header: { title: '', description: '', buttonText: '', buttonLink: '', logo: '' },
    section: { title: '', description: '', banner: '' },
    footer: { about: '', phone: '', email: '', address: '', instagram: '', facebook: '', twitter: '', linkedin: '' },
  });

  const [dataSource, setDataSource] = useState([
    { key: '1', section: 'Header' },
    { key: '2', section: 'Home' },
    { key: '3', section: 'Our-Services' },
    { key: '4', section: 'Our-Docters' },
    { key: '5', section: 'About' },
    { key: '6', section: 'Footer' },
  ]);
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');

  const scrollToSection = (sectionName: string) => {
    console.log('Section Name:', sectionName);
    setTimeout(() => {
      const sectionElement = document.getElementById(sectionName.toLowerCase());
      console.log('Found Element:', sectionElement);

      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: 'smooth' });
      } else {
        console.warn(`No element found with id: ${sectionName.toLowerCase()}`);
      }
    }, 100); // Adjust timeout as necessary
  };



  const section = [
    {
      title: 'Module Name',
      dataIndex: 'section',
      key: 'section',
      editable: true,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: any) => (
        <span>
          <Button
            type="link"
            onClick={() => {
              setActiveTab('2'); // Switch to Tab 2
              setEditingKey(record.key); // Optionally, set the editing key if needed
              // Scroll to or focus on the specific section (e.g., Footer)
              scrollToSection(record.section);
            }}
          >
            Edit
          </Button>
        </span>
      ),
    },
  ];



  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    const keys = name.split('.');

    if (keys.length === 2) {
      const [mainKey, subKey] = keys;
      let errorMessage = '';

      // Example validation: Check if fields are empty
      if (value.trim() === '') {
        switch (subKey) {
          case 'title':
            errorMessage = 'Title cannot be empty';
            break;
          case 'description':
            errorMessage = 'Description cannot be empty';
            break;
          case 'buttonText':
            errorMessage = 'Button Text cannot be empty';
            break;
          case 'buttonLink':
            errorMessage = 'Button Link cannot be empty';
            break;
          case 'logo':
            errorMessage = 'Logo cannot be empty';
            break;
          case 'title':
            errorMessage = 'Title cannot be empty';
            break;
          case 'description':
            errorMessage = 'Description cannot be empty';
            break;
          case 'banner':
            errorMessage = 'Banner cannot be empty';
            break;
          case 'about':
            errorMessage = 'About cannot be empty';
            break;
          case 'phone':
            errorMessage = 'Phone cannot be empty';
            break;
          case 'email':
            errorMessage = 'Email cannot be empty';
            break;
          case 'address':
            errorMessage = 'Address cannot be empty';
            break;
          case 'instagram':
            errorMessage = 'Instagram cannot be empty';
            break;
          case 'facebook':
            errorMessage = 'Facebook cannot be empty';
            break;
          case 'twitter':
            errorMessage = 'Twitter cannot be empty';
            break;
          case 'linkedin':
            errorMessage = 'Linkedin cannot be empty';
            break;
          default:
            break;
        }
      }

      // Update errors state
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [mainKey]: {
          ...prevErrors[mainKey],
          [subKey]: errorMessage,
        },
      }));

      // Update form data state
      setFormData((prevData: any) => ({
        ...prevData,
        [mainKey]: {
          ...prevData[mainKey],
          [subKey]: value,
        },
      }));
    } else {
      // Handle non-nested fields
      setFormData((prevData: any) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };


  const handleserviceimage = (info: any) => {
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
      setServiceData({ ...serviceData, serviceImage: info.file.originFileObj });
      setImagePreview(URL.createObjectURL(info.file.originFileObj as Blob));
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const handleFileChange = (info: any, fieldName: any) => {
    const file = info.file.originFileObj;

    setFormData((prevData: any) => {
      const updatedData = { ...prevData };

      if (fieldName === 'header.logo') {
        updatedData.header = { ...updatedData.header, logo: file };
        setLogoPreview(URL.createObjectURL(file));
      } else if (fieldName === 'section.banner') {
        updatedData.section = { ...updatedData.section, banner: file };
        setbannerPreview(URL.createObjectURL(file));
      } else if (fieldName === 'about.image') {
        updatedData.about = { ...updatedData.about, image: file };
        setAboutImagePreview(URL.createObjectURL(file));
      }

      return updatedData;
    });
  };

  const headerSubmit = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('templateId', formData.templateId);
    formDataToSend.append('header[title]', formData.header.title);
    formDataToSend.append('header[description]', formData.header.description);
    formDataToSend.append('header[buttonText]', formData.header.buttonText);
    formDataToSend.append('header[buttonLink]', formData.header.buttonLink);
    formDataToSend.append('header[themecolore]', formData.header.themecolore);
    if (formData.header.logo instanceof File) {
      formDataToSend.append('logo', formData.header.logo);
    }


    try {
      let response;
      if (formData._id) {
        response = await templateupdate(formData._id, formDataToSend);
        message.success('Header updated successfully');
        setImagePreview("");
      } else {
        response = await templeteform(formDataToSend);
        message.success('Header added successfully');
        setImagePreview("");
        fetchTemplate();
      }
      handleSaveAndNext('2'); // Proceed to the next tab
    } catch (error: any) {
      message.error(error.response?.data?.message || 'An error occurred');
    }
  };

  const seactionSubmit = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('section[title]', formData.section.title);
    formDataToSend.append('section[description]', formData.section.description);
    if (formData.section.banner) formDataToSend.append('favicon', formData.section.banner as Blob);


    try {
      let response;
      if (formData._id) {
        response = await templateupdate(formData._id, formDataToSend);
        message.success('Section updated successfully');
        setImagePreview("");
      } else {
        response = await templeteform(formDataToSend);
        message.success('Section added successfully');
        setImagePreview("");
        fetchTemplate();
      }
      handleSaveAndNext('2'); // Proceed to the next tab
    } catch (error: any) {
      message.error(error.response?.data?.message || 'An error occurred');
    }
  };

  const aboutSubmit = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('about[title]', formData.about.title);
    formDataToSend.append('about[description]', formData.about.description);
    if (formData.about.image) formDataToSend.append('image', formData.about.image as Blob);


    try {
      let response;
      if (formData._id) {
        response = await templateupdate(formData._id, formDataToSend);
        message.success('About updated successfully');
        setImagePreview("");
      } else {
        response = await templeteform(formDataToSend);
        message.success('About added successfully');
        setImagePreview("");
        fetchTemplate();
      }
      handleSaveAndNext('2'); // Proceed to the next tab
    } catch (error: any) {
      message.error(error.response?.data?.message || 'An error occurred');
    }
  };


  const footerSubmit = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('footer[about]', formData.footer.about);
    formDataToSend.append('footer[phone]', formData.footer.phone);
    formDataToSend.append('footer[email]', formData.footer.email);
    formDataToSend.append('footer[address]', formData.footer.address);
    formDataToSend.append('footer[instagram]', formData.footer.instagram);
    formDataToSend.append('footer[facebook]', formData.footer.facebook);
    formDataToSend.append('footer[twitter]', formData.footer.twitter);
    formDataToSend.append('footer[linkedin]', formData.footer.linkedin);


    try {
      let response;
      if (formData._id) {
        response = await templateupdate(formData._id, formDataToSend);
        message.success('Footer updated successfully');
        setImagePreview("");
      } else {
        response = await templeteform(formDataToSend);
        message.success('Footer added successfully');
        setImagePreview("");
        fetchTemplate();
      }
      handleSaveAndNext('2'); // Proceed to the next tab
    } catch (error: any) {
      message.error(error.response?.data?.message || 'An error occurred');
    }
  };

  const handleAddOrUpdateService = async () => {
    try {
      const serviceDataToSend = new FormData();
      serviceDataToSend.append("name", serviceData.name);
      serviceDataToSend.append("description", serviceData.description);
      serviceDataToSend.append("price", serviceData.price);
      if (serviceData.serviceImage) serviceDataToSend.append("serviceImage", serviceData.serviceImage);

      let response;
      if (serviceData._id) {
        response = await serviceupdate(serviceData._id, serviceDataToSend);
        message.success('Service updated successfully');
        fetchTemplate();
      } else {
        response = await serviceadd(serviceDataToSend);
        message.success('Service added successfully');
        fetchTemplate();
      }

      setServiceData({ _id: "", name: "", description: "", serviceImage: null, price: "" });
      setImagePreview(null);
      await fetchService();
    } catch (error: any) {
      message.error(error.response?.data?.message || "An error occurred");
    }
  };


  const handleAddOrUpdateTeam = async () => {
    try {
      const formData = new FormData();
      formData.append("name", teamData.name);
      formData.append("description", teamData.description);

      if (teamData.profileImage) {
        formData.append("profileImage", teamData.profileImage);
      }

      let response;
      if (teamData._id) {
        // Update existing team member
        response = await updateTeamMember(teamData._id, formData);
        message.success('Team member updated successfully');
        setModalVisible(false)
      } else {
        // Add new team member
        response = await addTeamMember(formData);
        message.success('Team member added successfully');
        setModalVisible(false)
      }

      // Clear form data and image preview
      setTeamData({ name: "", description: "", profileImage: null, _id: null });
      setImagePreview(null);

      // Fetch updated list of team members
      await fetchTeamMembers();
    } catch (error: any) {
      message.error(error.response?.data?.message || "An error occurred");
    }
  };


  const handlevalidChange = async (e: any) => {
    const inputValue = e.target.value;
    const filteredValue = inputValue.replace(/[^a-zA-Z0-9]/g, '');
    setDomainData(filteredValue);
    try {
      const response = await domainnamecheak({ domainName: inputValue });
      if (response.data.isValid) {
        setIsDomainValid(true);
      } else {
        setIsDomainValid(false);
        setValidationError(response.data.message);
      }
    } catch (error) {
      setIsDomainValid(false);
      setValidationError('Error checking domain name validity.');
      navigate("/login")
    }
  };

  const handleThirdTabSubmit = async () => {
    try {
      // Construct the payload to send to the server
      const payload = {
        metaName: formData.metaName,
        metaTitle: formData.metaTitle,
        metaDescription: formData.metaDescription,
      };

      let response;

      // If an ID exists, update the existing entry; otherwise, show an error message
      if (formData._id) {
        // If an ID exists, update the existing entry
        response = await seoadd(formData._id, payload);
      } else {
        // If no ID exists, show an error message
        message.error("Please add Web details first.");
        return; // Exit the function early
      }

      // Handle the success response
      message.success(response.data.message);
      setActiveTab("5");
    } catch (error: any) {
      // Handle any errors that occur
      message.error(error.response?.data?.message || 'An error occurred');
    }
  };



  const handleForthTabSubmit = async () => {
    if (!isDomainValid) {
      message.error(validationError || 'Please enter a valid domain name.');
      return;
    }

    try {
      const response = await domainadd(formData._id, { domainName: `${domainData}` });
      message.success(response.data.message);
      navigate('/'); // Redirect after successful submission
    } catch (error: any) {
      message.error(error.response?.data?.message || 'An error occurred');
    }
  };

  const handleSaveAndNext = (tabKey: string) => {
    setActiveTab(tabKey);
  };
  const fetchService = async () => {
    try {
      const response = await servicegetuserwise();
      const reversedata = response.data.reverse();
      setServices(reversedata);
    } catch (error: any) {
      console.log(error)
    }
  };

  const fetchTeamMembers = async () => {
    try {
      const response = await teamgetuserwise();
      const reversedProducts = response.data.reverse();
      setTeamMembers(reversedProducts);
    } catch (error: any) {
      console.log(error)
    }
  };
  const fetchTemplate = async () => {
    try {
      const response = await templetedata();
      const data = response.data;

      setFormData({
        _id: data._id || '',
        templateId: 1,
        header: {
          title: data.header.title || '',
          description: data.header.description || '',
          buttonText: data.header.buttonText || '',
          buttonLink: data.header.buttonLink || '',
          logo: data.header.logo || null,
          themecolore: data.header.themecolore || '',
        },
        section: {
          title: data.section.title || '',
          description: data.section.description || '',
          banner: data.header.logo || null,
        },
        about: {
          title: data.about.title || '',
          description: data.about.description || '',
          image: data.about.image || '',
        },
        footer: {
          about: data.footer?.about || '',
          phone: data.footer?.phone || '',
          email: data.footer?.email || '',
          address: data.footer?.address || '',
          instagram: data.footer?.instagram || '',
          facebook: data.footer?.facebook || '',
          twitter: data.footer?.twitter || '',
          linkedin: data.footer?.linkedin || '',
        },
        domainName: data.domainName || '',
        metaName: data.metaName || '',
        metaTitle: data.metaTitle || '',
        metaDescription: data.metaDescription || '',
      });
      const fullDomainName = response.data.domainName;
      const domainPrefix = fullDomainName.split('.net4surge.com')[0]; // Get the part before '.net4surge.com'
      setDomainData(domainPrefix);

      // Set the domain availability state
      setIsDomainAvailable(!!fullDomainName);

      // Set the initial preview from the fetched logo URL
      if (data.header.logo) {
        setLogoPreview(`${imageUrl}${data.header.logo}`);
      }

      // Set the initial preview from the fetched banner URL
      if (data.section.banner) {
        setbannerPreview(`${imageUrl}${data.section.banner}`);
      }

      // Set the initial preview from the fetched image URL
      if (data.about.image) {
        setAboutImagePreview(`${imageUrl}${data.about.image}`);
      }

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchService();
    fetchTemplate();
    fetchTeamMembers();
  }, []);


  const handleEditService = async (id: any) => {
    try {
      // Fetch service data using the provided ID
      const response = await serviceedit(id);

      // Set the service data state with the fetched data
      setServiceData({
        _id: response._id,
        name: response.name,
        description: response.description,
        serviceImage: response.serviceImage,
        price: response.price || ""
      });

      // Set the image preview state with the fetched image URL
      setImagePreview(`${imageUrlservice}${response.serviceImage}`);
    } catch (error: any) {
      // Display an error message if the request fails
      message.error(error.response?.data?.message || "An error occurred");
    }
  };


  const handleRemoveService = async (id: any) => {
    try {
      const response = await servicedelete(id);
      message.success(response.data.message);
      await fetchService();
    } catch (error: any) {
      message.error(error.response?.data?.message || "An error occurred");
    }
  };

  const handleRemoveteam = async (id: any) => {
    try {
      const response = await teamdatadelete(id);
      message.success(response.data.message);
      await fetchTeamMembers();
    } catch (error: any) {
      message.error(error.response?.data?.message || "An error occurred");
    }
  };
  const handleEditteam = async (id: any) => {
    try {
      const response = await teamedit(id); // Fetch the team member data by ID
      console.log(response);

      // Check if the response contains the teamMember object
      if (response && response.teamMember) {
        const teamMember = response.teamMember;

        // Set the fetched data into state
        setTeamData({
          _id: teamMember._id || '',          // Ensure the _id is included
          name: teamMember.name || '',
          description: teamMember.description || '',
          profileImage: teamMember.profileImage || '', // Assuming profileImage is a URL string
        });

        // Set the preview image if there's a profile image
        if (teamMember.profileImage) {
          setImagePreview(`${imageUrlteammember}${teamMember.profileImage}`);
        } else {
          setImagePreview(null);
        }

        // Open the modal
        setModalVisible(true);
      } else {
        message.error("Team member data not found");
      }
    } catch (error: any) {
      message.error(error.response?.data?.message || "An error occurred");
    }
  };

  const columnservice = [
    {
      title: 'Image',
      dataIndex: 'serviceImage',
      key: 'serviceImage',
      render: (text: any, record: any) => (
        record.serviceImage && (
          <img
            src={`${imageUrlservice}${record.serviceImage}`}
            alt="Service"
            className="w-24 h-24 object-cover"
          />
        )
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: any) => (
        <Space size="middle">
          <Button type="primary" onClick={() => handleEditService(record._id)} className="mr-2 bg-yellow-500">
            <FontAwesomeIcon icon={faEdit} />
          </Button>
          <Button type="primary" danger onClick={() => handleRemoveService(record._id)}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </Space>
      ),
    },
  ];


  return (
    <form className="mx-auto  bg-white  shadow-lg rounded-lg ">
      <Tabs activeKey={activeTab} className='p-4' onChange={setActiveTab}>
        <TabPane tab="Sections" key="1">
          <div className="mb-4">
            <Table
              bordered
              dataSource={dataSource}
              columns={section}
              pagination={false}
            />
          </div>
        </TabPane>

        <TabPane tab="Template Details" key="2">
          <div className="bg-gray-100 p-6 mb-6 rounded-lg">
            <h3 id='header' className="text-xl font-semibold mb-4">Header</h3>

            {/* Container for Logo and Theme Color */}
            <div className="flex mb-4">

              {/* Logo Upload Section */}
              <div className="w-1/3 pr-2">
                <label className="block text-gray-700 font-medium mb-1">
                  Logo<span className="text-red-500">*</span>
                </label>
                <Upload
                  action="/upload" // Your upload URL
                  listType="picture"
                  showUploadList={false}
                  onChange={(info) => handleFileChange(info, 'header.logo')}
                >
                  <Button icon={<UploadOutlined />}>Upload Logo</Button>
                </Upload>

                {/* Logo Preview */}
                {(logoPreview || formData.header.logo) && (
                  <div className="mt-2">
                    <img
                      src={logoPreview || `${imageUrl}${formData.header.logo}`}
                      alt="Logo Preview"
                      className="w-32 h-32 object-cover rounded-lg shadow-md border"
                    />
                  </div>
                )}
              </div>

              {/* Theme Color Input */}
              <div className="w-1/4 pl-2">
                <label className="block text-gray-700 font-medium mb-1">
                  Theme-color <span className="text-red-500">*</span>
                </label>

                {/* Color Picker Input */}
                <input
                  type="color"  // Color picker input type
                  name="header.themecolore"  // Adjust the name to match your data model
                  value={formData.header.themecolore}  // Bind the value to formData
                  onChange={handleChange}
                  className="w-full h-10 p-1 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>

            </div>


            {/* Flexbox Container */}
            <div className="flex justify-between">
              {/* Left Section */}
              <div className="w-3/4">

                {/* Title Input */}
                <div className="mb-4" style={{ width: '100%' }}>
                  <label className="block text-gray-700 font-medium mb-1">
                    Title <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="header.title"
                    value={formData.header.title}
                    onChange={handleChange}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  />
                  {errors.header.title && (
                    <p className="text-red-500 text-xs mt-1">{errors.header.title}</p>
                  )}
                </div>

                {/* Description Textarea */}
                <div className="mb-4" style={{ width: '100%' }}>
                  <label className="block text-gray-700 font-medium mb-1">
                    Description<span className="text-red-500">*</span>
                  </label>
                  <textarea
                    name="header.description"
                    value={formData.header.description}
                    onChange={handleChange}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    rows={4} // Adds more space for typing
                  />
                  {errors.header.description && (
                    <p className="text-red-500 text-xs mt-1">{errors.header.description}</p>
                  )}
                </div>

                {/* Button Text Input */}
                <div className="mb-4" style={{ width: '100%' }}>
                  <label className="block text-gray-700 font-medium mb-1">
                    Button Text<span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="header.buttonText"
                    value={formData.header.buttonText}
                    onChange={handleChange}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  {errors.header.buttonText && (
                    <p className="text-red-500 text-xs mt-1">{errors.header.buttonText}</p>
                  )}
                </div>

                {/* Button Link Input */}
                <div className="mb-4" style={{ width: '100%' }}>
                  <label className="block text-gray-700 font-medium mb-1">
                    Button Link<span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="header.buttonLink"
                    value={formData.header.buttonLink}
                    onChange={handleChange}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  {errors.header.buttonLink && (
                    <p className="text-red-500 text-xs mt-1">{errors.header.buttonLink}</p>
                  )}
                </div>
              </div>

              {/* Save Button on the Right */}
              <div className="flex items-end">
                <button
                  type="button"
                  onClick={headerSubmit}
                  className="bg-yellow-500 text-white py-2 px-4 rounded"
                >
                  Save
                </button>
              </div>
            </div>
          </div>


          <div className="bg-gray-100 p-6 mb-6 rounded-lg">
            <h3 id="home" className="text-xl font-semibold mb-4">Home</h3>

            {/* Flexbox Container */}
            <div className="flex justify-between">
              {/* Left Section */}
              <div className="w-3/4">
                {/* Title Input */}
                <div className="mb-4" style={{ width: '100%' }}>
                  <label className="block text-gray-700 font-medium mb-1">
                    Title<span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="section.title"
                    value={formData.section.title}
                    onChange={handleChange}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  {errors.section.title && (
                    <p className="text-red-500 text-xs mt-1">{errors.section.title}</p>
                  )}
                </div>

                {/* Description Textarea */}
                <div className="mb-4" style={{ width: '100%' }}>
                  <label className="block text-gray-700 font-medium mb-1">
                    Description<span className="text-red-500">*</span>
                  </label>
                  <textarea
                    name="section.description"
                    value={formData.section.description}
                    onChange={handleChange}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    rows={4} // Adds more space for typing
                  />
                  {errors.section.description && (
                    <p className="text-red-500 text-xs mt-1">{errors.section.description}</p>
                  )}
                </div>

                {/* Banner Upload */}
                <div className="mb-4" style={{ width: '100%' }}>
                  <label className="block text-gray-700 font-medium mb-1">
                    Banner<span className="text-red-500">*</span>
                  </label>
                  <Upload
                    action="/upload" // Your upload URL
                    listType="picture"
                    showUploadList={false}
                    onChange={(info) => handleFileChange(info, 'section.banner')}
                  >
                    <Button icon={<UploadOutlined />}>Upload Banner</Button>
                  </Upload>

                  {/* Banner Preview */}
                  {(bannerPreview || formData.section.banner) && (
                    <div className="mt-4">
                      <img
                        src={bannerPreview || `${imageUrl}${formData.section.banner}`}
                        alt="Banner Preview"
                        className="w-100 h-40 object-cover rounded-lg shadow-md border"
                      />
                    </div>
                  )}
                </div>
              </div>

              {/* Save Button on the Right */}
              <div className="flex items-end">
                <button
                  type="button"
                  onClick={seactionSubmit}
                  className="bg-yellow-500 text-white py-2 px-4 rounded"
                >
                  Save
                </button>
              </div>
            </div>
          </div>

          <div className="bg-gray-100 p-6 mb-6 rounded-lg">
            <h3 id="about" className="text-xl font-semibold mb-4">About us</h3>

            {/* Flexbox Container */}
            <div className="flex justify-between">
              {/* Left Section */}
              <div className="w-3/4">
                {/* Title Input */}
                <div className="mb-4" style={{ width: '100%' }}>
                  <label className="block text-gray-700 font-medium mb-1">
                    Title<span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="about.title"
                    value={formData.about.title}
                    onChange={handleChange}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  {errors.section.title && (
                    <p className="text-red-500 text-xs mt-1">{errors.section.title}</p>
                  )}
                </div>

                {/* Description Textarea */}
                <div className="mb-4" style={{ width: '100%' }}>
                  <label className="block text-gray-700 font-medium mb-1">
                    Description<span className="text-red-500">*</span>
                  </label>
                  <textarea
                    name="about.description"
                    value={formData.about.description}
                    onChange={handleChange}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    rows={4} // Adds more space for typing
                  />
                  {errors.section.description && (
                    <p className="text-red-500 text-xs mt-1">{errors.section.description}</p>
                  )}
                </div>

                {/* Banner Upload */}
                <div className="mb-4" style={{ width: '100%' }}>
                  <label className="block text-gray-700 font-medium mb-1">
                    Image<span className="text-red-500">*</span>
                  </label>
                  <Upload
                    action="/upload" // Your upload URL
                    listType="picture"
                    showUploadList={false}
                    onChange={(info) => handleFileChange(info, 'about.image')}
                  >
                    <Button icon={<UploadOutlined />}>Upload Image</Button>
                  </Upload>

                  {/* Banner Preview */}
                  {(aboutImagePreview || formData.about.image) && (
                    <div className="mt-4">
                      <img
                        src={aboutImagePreview || `${imageUrl}${formData.about.image}`}
                        alt="Banner Preview"
                        className="w-40 h-50 object-cover rounded-lg shadow-md border"
                      />
                    </div>
                  )}
                </div>
              </div>

              {/* Save Button on the Right */}
              <div className="flex items-end">
                <button
                  type="button"
                  onClick={aboutSubmit}
                  className="bg-yellow-500 text-white py-2 px-4 rounded"
                >
                  Save
                </button>
              </div>
            </div>
          </div>


          <div className="bg-gray-100 p-6 mb-6 rounded-lg">
            <h3 id="our-services" className="text-xl font-semibold mb-2">
              {serviceData._id ? 'Update Service' : 'Add Service'}
            </h3>
            <div className="mb-4">
              <label className="block text-gray-700">Name</label>
              <Input
                type="text"
                required={true}
                name="name"
                value={serviceData.name}
                onChange={(e) => setServiceData({ ...serviceData, name: e.target.value })}
                className="w-3/4 p-2 border border-gray-300 rounded mt-1"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Description</label>
              <TextArea
                required={true}
                name="description"
                value={serviceData.description}
                onChange={(e) => setServiceData({ ...serviceData, description: e.target.value })}
                className="w-3/4 p-2 border border-gray-300 rounded mt-1"
                rows={8}
              />
            </div>

            {/* <div className="mb-4">
              <label className="block text-gray-700">Price</label>
              <Input
                type="number" // Changed to number
                required={true}
                name="price"
                value={serviceData.price}
                onChange={(e) => setServiceData({ ...serviceData, price: e.target.value })}
                className="w-3/4 p-2 border border-gray-300 rounded mt-1"
              />
            </div> */}

            <div className="mb-4">
              <label className="block text-gray-700 mb-4">Image</label>
              <Upload
                name="image"
                listType="picture"
                className="w-full p-2 border border-gray-300 rounded mt-1"
                showUploadList={false}
                customRequest={({ file, onSuccess }) => {
                  setServiceData({ ...serviceData, serviceImage: file });
                  setImagePreview(URL.createObjectURL(file as Blob));
                  // onSuccess(null, file);
                }}
                onChange={handleserviceimage}
              ><UploadOutlined /> Seleact Image</Upload>
              {imagePreview && (
                <div className="mt-2">
                  <img
                    src={imagePreview}
                    alt="Service Preview"
                    className="w-24 h-24 object-cover"
                  />
                </div>
              )}
            </div>
            <div className="flex justify-end items-center mb-4">
              <button
                type="button"
                onClick={handleAddOrUpdateService}
                className="bg-yellow-500 text-white py-2 px-4 rounded mr-4"
              >Save
                {/* {productData._id ? 'Update Service' : 'Add Service'} */}
              </button>
            </div>

            <Table className="mt-4" columns={columnservice} dataSource={services} rowKey="_id" />
          </div>


          <div className="bg-gray-100 p-6 mb-6 rounded-lg">
            <h3 id="our-docters" className="text-xl font-semibold mb-4">Our Team</h3>

            {/* Add Member Button */}
            <div className="flex justify-between items-center mb-4">
              <div></div> {/* Empty div to align button on the right */}

              {/* Add Member Button */}
              <button
                type="button"
                onClick={() => setModalVisible(true)}
                className="bg-yellow-500 text-white py-2 px-4 rounded"
              >
                Add Member
              </button>
            </div>

            {/* Team Members Table */}
            <Table dataSource={teamMembers} columns={columnsteam} rowKey="name" className="mt-4" />

            {/* Modal for Adding New Team Member */}
            <Modal
              title={teamData._id ? "Edit Team Member" : "Add New Team Member"} // Update title based on the action
              visible={modalVisible}
              onOk={handleAddOrUpdateTeam}
              onCancel={() => {
                setModalVisible(false);
                setTeamData({ _id: '', name: '', description: '', profileImage: '' }); // Reset form when modal closes
                setImagePreview(null); // Reset image preview
              }}
              okText={teamData._id ? "Update" : "Add"} // Change button text based on action
              okButtonProps={{
                className: 'bg-yellow-500 hover:bg-yellow-600 border-yellow-500 hover:border-yellow-600 text-white',
              }}
            >
              <div className="mb-4">
                <label className="block text-gray-700 font-medium mb-1">
                  Name<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  value={teamData.name} // Bind to teamData state
                  onChange={handleInputChange}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 font-medium mb-1">
                  Designation<span className="text-red-500">*</span>
                </label>
                <textarea
                  name="description"
                  value={teamData.description} // Bind to teamData state
                  onChange={handleInputChange}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  rows={4}
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 font-medium mb-1">
                  Image
                </label>
                <Upload
                  beforeUpload={(file) => {
                    setTeamData((prevData: any) => ({ ...prevData, profileImage: file })); // Store file temporarily
                    setImagePreview(URL.createObjectURL(file)); // Set preview for newly uploaded file
                    return false; // Prevent automatic upload
                  }}
                  showUploadList={false}
                >
                  <Button icon={<UploadOutlined />}>Upload Image</Button>
                </Upload>

                {/* Image Preview */}
                {imagePreview && (
                  <div className="mt-2">
                    <img
                      src={imagePreview} // Use the image preview URL
                      alt="Image Preview"
                      className="w-32 h-32 object-cover rounded-lg shadow-md border"
                    />
                  </div>
                )}
              </div>
            </Modal>
          </div>



          <div className="bg-gray-100 p-6">
            <h3 id="footer" className="text-xl font-semibold mb-4">Footer</h3>

            <div className="flex justify-between">
              {/* Left Side: Contact Information */}
              <div className="w-full md:w-1/2 pr-4">
                <div className="mb-4">
                  <label className="block text-gray-700">Phone<span className="text-red-500">*</span></label>
                  <input
                    type="text"
                    name="footer.phone"
                    value={formData.footer.phone}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                  />
                  {errors.footer.phone && <p className="text-red-500 text-xs mt-1">{errors.footer.phone}</p>}
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">Email<span className="text-red-500">*</span></label>
                  <input
                    type="text"
                    name="footer.email"
                    value={formData.footer.email}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">Address<span className="text-red-500">*</span></label>
                  <textarea
                    name="footer.address"
                    value={formData.footer.address}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                    rows={3} // Optional: Set the number of rows (height) for the textarea
                  />
                  {errors.footer.address && <p className="text-red-500 text-xs mt-1">{errors.footer.address}</p>}
                </div>

                <div className="mb-4">
                  <label className="block text-gray-700">About<span className="text-red-500">*</span></label>
                  <textarea
                    name="footer.about"
                    value={formData.footer.about}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                    rows={3}
                  />
                  {errors.footer.about && <p className="text-red-500 text-xs mt-1">{errors.footer.about}</p>}
                </div>
              </div>

              {/* Right Side: Social Links */}
              <div className="w-full md:w-1/2 pl-4">
                <div className="mb-4">
                  <label className="block text-gray-700">Instagram<span className="text-red-500">*</span></label>
                  <input
                    type="text"
                    name="footer.instagram"
                    value={formData.footer.instagram}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                  />
                  {errors.footer.instagram && <p className="text-red-500 text-xs mt-1">{errors.footer.instagram}</p>}
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">Facebook<span className="text-red-500">*</span></label>
                  <input
                    type="text"
                    name="footer.facebook"
                    value={formData.footer.facebook}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                  />
                  {errors.footer.facebook && <p className="text-red-500 text-xs mt-1">{errors.footer.facebook}</p>}
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">Twitter<span className="text-red-500">*</span></label>
                  <input
                    type="text"
                    name="footer.twitter"
                    value={formData.footer.twitter}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                  />
                  {errors.footer.twitter && <p className="text-red-500 text-xs mt-1">{errors.footer.twitter}</p>}
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">LinkedIn<span className="text-red-500">*</span></label>
                  <input
                    type="text"
                    name="footer.linkedin"
                    value={formData.footer.linkedin}
                    onChange={handleChange}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                  />
                  {errors.footer.linkedin && <p className="text-red-500 text-xs mt-1">{errors.footer.linkedin}</p>}
                </div>
                <div className="flex justify-end mt-6">
                  <button
                    type="button"
                    onClick={footerSubmit}
                    className="bg-yellow-500 text-white py-2 px-4 rounded"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>

          </div>

        </TabPane>

        <TabPane tab="SEO Details" key="4">
          <div className="mb-4">
            <label className="block text-gray-700">Meta Name</label>
            <div className="flex items-center">
              <input
                type="text"
                name="metaName"
                value={formData.metaName}
                onChange={handleChange}
                placeholder="Enter Meta Name"
                className="border border-gray-300 rounded px-3 py-2 w-full"
              />
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Meta Title</label>
            <div className="flex items-center">
              <input
                type="text"
                name="metaTitle"
                value={formData.metaTitle}
                onChange={handleChange}
                placeholder="Enter Meta Title"
                className="border border-gray-300 rounded px-3 py-2 w-full"
              />
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Meta Description</label>
            <div className="flex items-center">
              <input
                type="text"
                name="metaDescription"
                value={formData.metaDescription}
                onChange={handleChange}
                placeholder="Enter Meta Description"
                className="border border-gray-300 rounded px-3 py-2 w-full"
              />
            </div>
          </div>
          <button
            type="button"
            onClick={handleThirdTabSubmit}
            className="bg-yellow-500 text-white py-2 px-4 rounded"
          >
            Save
          </button>
        </TabPane>

        <TabPane tab="Domain Name" key="5">
          <div className="mb-4">
            <label className="block text-gray-700">Domain Name</label>
            <div className="flex items-center">
              <input
                type="text"
                name="domainName"
                value={domainData}
                onChange={handlevalidChange}
                className={`w-medium p-2 border rounded-lg mt-2 ${isDomainAvailable ? 'bg-gray-200 text-gray-500' : ''
                  }`}
                placeholder="Enter domain name"
                disabled={isDomainAvailable}
              />
              <span className="ml-2 text-gray-700">.net4surge.com</span>
            </div>

          </div>
          {!isDomainValid && (
            <div className="text-red-500 mt-1">{validationError}</div>
          )}
          <button
            type="button"
            onClick={handleForthTabSubmit}
            className="bg-yellow-500 text-white py-2 px-4 rounded"
          >
            Save
          </button>
        </TabPane>
      </Tabs>
    </form>
  );
};

export default ThemeForm1;