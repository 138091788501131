import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  Image,
  Input,
  Form,
  Upload,
  Card,
  Row,
  Col,
  message,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import { BiSolidInbox } from "react-icons/bi";
import { MdDeleteOutline } from "react-icons/md";
import {
  deleteimage,
  getCustomerByID,
  updateCustomer,
} from "../../../utils/API";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { AuthContext } from "../../Auth/AuthContext";
import { UploadFile } from "antd/lib/upload/interface";

const EditProfile: React.FC = () => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewImage, setPreviewImage] = useState<string>("");
  const [apiImageUploaded, setApiImageUploaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { authData } = useContext(AuthContext);
  const id = authData?.user?._id;
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const fetchData = async () => {
    try {
      const existingData = await getCustomerByID(id);
      form.setFieldsValue({
        ...existingData,
        dob: dayjs(existingData.dob).format("YYYY-MM-DD"),
      });
      if (existingData.image) {
        const imageUrl = `${process.env.REACT_APP_IMAGE_URL}/users/${existingData.image}`;
        setFileList([
          {
            uid: "1",
            name: existingData.image,
            status: "done",
            url: imageUrl,
          },
        ]);
        setPreviewImage(imageUrl);
        setApiImageUploaded(true);
      } else {
        setApiImageUploaded(false);
      }
    } catch (error) {
      message.error("Error fetching data. Please try again.");
      navigate("/dashboard");
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  const getBase64 = (file: File | Blob) =>
    new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const imageProps = {
    name: "image",
    multiple: false,
    beforeUpload(file: File) {
      const isImage = file.type.startsWith("image/");
      if (!isImage) {
        message.error("You can only upload image files!");
      }
      const isLt64M = file.size / 1024 / 1024 < 64;
      if (!isLt64M) {
        message.error("Image must be smaller than 64MB!");
      }
      return isImage && isLt64M;
    },
    onChange(info: any) {
      const newFileList = [...info.fileList];
      if (newFileList.length > 0) {
        const file = newFileList[0];
        if (!file.url && !file.preview) {
          getBase64(file.originFileObj).then((preview) => {
            file.preview = preview;
            setPreviewImage(preview);
          });
        } else {
          setPreviewImage(file.url || file.preview || "");
        }
      } else {
        setPreviewImage("");
      }
      setFileList(newFileList.slice(-1));
    },
    fileList,
  };

  const handleSubmit = async (values: any) => {
    const currentId = form.getFieldValue("_id");
    if (currentId) {
      setIsLoading(true);
      try {
        const formData = new FormData();
        Object.keys(values).forEach((key) => {
          formData.append(key, values[key]);
        });
  
        // Only append the file if there is a file in the fileList
        if (fileList.length > 0 && fileList[0].originFileObj) {
          formData.append("image", fileList[0].originFileObj as Blob);
        }
  
        const response = await updateCustomer(currentId, formData);
        setIsLoading(false);
  
        if (response && response.data && response.data.message) {
          message.success(response.data.message);
        } else {
          message.success("Profile updated successfully");
          form.setFieldsValue({ password: "" });
        }
      } catch (error) {
        setIsLoading(false);
        message.error("Failed to submit data. Please try again.");
      }
    } else {
      setIsLoading(false);
      message.error("Failed to fetch data. Please try again.");
    }
  };
  

  const handleRemove = (event: any) => {
    event.stopPropagation();
    setPreviewImage("");
    setFileList([]);
  };

  const onFinishFailed = (errorInfo: any) => {
    message.error("Please correct the errors in the form.");
    errorInfo.errorFields.forEach((field: any) => {
      form.scrollToField(field.name, {
        behavior: "smooth",
        block: "center",
      });
    });
  };
  

  return (
    <div className="profile">
      <Row className="m-2" align={"middle"}>
        <Col
          xs={24}
          sm={24}
          md={9}
          xl={6}
          xxl={6}
          className="d-flex justify-content-start font-bold"
        >
          <h2 className="text-2xl">Manage Profile</h2>
        </Col>
      </Row>

      <Card>
        <Form layout="vertical" form={form} onFinish={handleSubmit} onFinishFailed={onFinishFailed}>
          <h2 className="text-xl font-bold pb-3">Avatar</h2>
          <Col span={6}>
            <Form.Item
              name="image"
              rules={[
                {
                  required: true,
                  message: "Please upload profile image",
                },
              ]}
            >
              <Upload.Dragger {...imageProps}>
                {!previewImage && (
                  <div>
                    <p className="ant-upload-drag-icon d-flex justify-center">
                      <BiSolidInbox />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                  </div>
                )}
                {previewImage && (
                  <div className="relative h-40 w-40">
                    <img
                      src={previewImage}
                      alt="Uploaded preview"
                      className="w-full h-full"
                    />
                    <button
                      className="absolute top-0 right-0 bg-orange-500 text-black p-1"
                      onClick={handleRemove}
                    >
                      <MdDeleteOutline />
                    </button>
                  </div>
                )}
              </Upload.Dragger>
            </Form.Item>
          </Col>
          <h2
            style={{ textAlign: "left" }}
            className="text-xl font-bold pb-3"
          >
            Information
          </h2>
          <div className="infoblock">
            <Row gutter={50}>
              <Col xs={20} md={20} lg={12}>
                <Form.Item name="firstName">
                  <Input
                    className="Allinput"
                    type="text"
                    placeholder="First Name"
                  />
                </Form.Item>
              </Col>
              <Col xs={20} md={20} lg={12}>
                <Form.Item name="lastName">
                  <Input
                    className="Allinput"
                    type="text"
                    placeholder="Last Name"
                  />
                </Form.Item>
              </Col>
              <Col xs={20} md={20} lg={12}>
                <Form.Item name="displayName">
                  <Input
                    className="Allinput"
                    type="text"
                    placeholder="Display Name"
                  />
                </Form.Item>
              </Col>
              <Col xs={20} md={20} lg={12}>
                <Form.Item name="email">
                  <Input
                    className="Allinput"
                    type="text"
                    placeholder="Email"
                  />
                </Form.Item>
              </Col>
              <Col xs={20} md={20} lg={12}>
                <Form.Item name="mobileNo">
                  <Input
                    className="Allinput"
                    type="text"
                    placeholder="Phone"
                  />
                </Form.Item>
              </Col>
              <Col xs={20} md={20} lg={12}>
                <Form.Item name="address">
                  <Input
                    className="Allinput"
                    type="text"
                    placeholder="Address"
                  />
                </Form.Item>
              </Col>
              <Col xs={20} md={20} lg={12}>
                <Form.Item name="website">
                  <Input
                    className="Allinput"
                    type="text"
                    placeholder="Website"
                  />
                </Form.Item>
              </Col>
              <Col xs={20} md={20} lg={12}></Col>
            </Row>
          </div>
          <div className="description">
            <Row>
              <Col xs={20} md={20} lg={12}>
                <Form.Item name="biographicalInfo">
                  <Input.TextArea
                    className="Allinput"
                    style={{ width: "200%" }}
                    placeholder="Biographical Info"
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>

          <div className="socials">
            <h2
              style={{ textAlign: "left" }}
              className="text-xl font-bold pt-3 pb-3"
            >
              Socials
            </h2>

            <Row gutter={50}>
              <Col xs={20} md={20} lg={12}>
                <Form.Item name="facebook">
                  <Input
                    className="Allinput"
                    type="text"
                    placeholder="Facebook"
                  />
                </Form.Item>
              </Col>
              <Col xs={20} md={20} lg={12}>
                <Form.Item name="twitter">
                  <Input
                    className="Allinput"
                    type="text"
                    placeholder="Twitter"
                  />
                </Form.Item>
              </Col>
              <Col xs={20} md={20} lg={12}>
                <Form.Item name="google">
                  <Input
                    className="Allinput"
                    style={{ height: "50px" }}
                    type="text"
                    placeholder="Google"
                  />
                </Form.Item>
              </Col>
              <Col xs={20} md={20} lg={12}>
                <Form.Item name="pinterest">
                  <Input
                    className="Allinput"
                    type="text"
                    placeholder="Pinterest"
                  />
                </Form.Item>
              </Col>
              <Col xs={20} md={20} lg={12}>
                <Form.Item name="linkedin">
                  <Input
                    className="Allinput"
                    type="text"
                    placeholder="LinkedIn"
                  />
                </Form.Item>
              </Col>
              <Col xs={20} md={20} lg={12}>
                <Form.Item name="instagram">
                  <Input
                    className="Allinput"
                    type="text"
                    placeholder="Instagram"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <button
                type="submit"
                className="p-2 bg-yellow-700  text-black rounded-md"
                style={{ width: "15%" }}
              >
                Save Changes
              </button>
            </Form.Item>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default EditProfile;
