import { message } from 'antd';
import axios from 'axios';

export  const API = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL 
})


API.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

API.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response) {
    // Check for invalid token response
    if (error.response.status === 401) {
      message.error('Invalid token. Please login again.');
      localStorage.removeItem('token');
      window.location.href = '/login'; // Adjust the redirect URL as needed
    }
  }
  return Promise.reject(error);
});
export const login = async (data: {
  email: any;
  password: any;

}) => {
  try {
    const response = await API.post('/login', data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const sendOTP = async (data: any) => {
  try {
    const response = await API.post('/login',  { mobileNo: data.mobileNo });
    return response;
  }
  catch (error) {
    throw error;
  }
}



export const verifyOTP = async (data : any) => {
  try {
      const response  = await API.post('/login',  { mobileNo: data.mobile, otp: data.otp } );
      return response;
  }
  catch (error) {
throw error;

  }
}

export const addTestimonials = async (formData: any) => {
  try {
   const response = await API.post("/testimonial/store", formData);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllTestimonials = async () => {
  try {
    const response = await API.get("/testimonial");
    return response.data;
  }
  catch (error) {
    throw error;
  }
}

export const Testimonials = async () => {
  try {
    const response = await API.get('/testimonial');
    return response;
  } catch (error) {
    throw error;
  }
}


export const getTestimonialById = async (id: any) => {
  try {
    const response = await API.get(`/testimonial/${id}/edit`);  
    return response.data;
  }

  catch (error) {
    throw error;
  }

}

export const deletetestimonial = async (id: any) => {
  try {
    const response = await API.delete(`/testimonial/${id}/delete`);
    return response;
  } catch (error) {
    throw error;
  }
};



export const updateTestimonial = async (
  id: number,
  values: any
): Promise<any> => {
  try {
    const response = await API.put(`/testimonial/${id}/update`, values);
    return response.data;
  } catch (error) {
    throw error;
  }
}


export const changePassword = async ( id : any , data: any) => {
  try {
    const response = await axios.put( process.env.REACT_APP_LOGIN_URL + `users/${id}/change-password`, data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const loginwithmlm = async (data: {
  username: any;
  password: any;

}) => {
  try {
    const response = await API.post('/mlm/login', data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const addCustomer = async (data: {
  first_name: any;
  last_name: any;
  mobile_no : any;
  sponsor_id : any;
  placement : any;
  product_id : any;
}) => {
  try {
    const response = await API.post('/users/add', data);
    return response;
  } catch (error) {
    throw error;
  }
};
export const getCustomers = async (searchText: string) => {
  try {
    const response = await API.get('/backend/users', {
      params: {
        search: searchText,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteCustomer = async (id: number) => {
  try {
    const response = await API.delete(`/backend/uers/${id}/delete`)
    return response
  } catch (error) {
    throw error;
  }
}
export const updateCustomer = async (customerId: number, data: any): Promise<any> => {
  try {
    const response = await API.put(`/user/${customerId}/update`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};



export const getCustomerByID = async (id: any) => {

  try {
    const response = await API.get(`/user/${id}/edit`)
    return response.data
  } catch (error) {
    throw error;
  }
}



export const UpdateFlag = async (id: number, isDeleted: boolean) => {
  try {
    const response = await API.delete(`/backend/users/${id}/delete`);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const ContactUsapi = async (data: any) => {
  try {
    const response = await API.post('/contactus', data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getContacts = async () => {
  try {
    const response = await API.get('/backend/contactus');
    return response;
  }
  catch (error) {
    throw error;
  }
}

export const getAllcategories = async () => {
  try {
    const response = await API.get("/categories");
    return response.data;
  } catch (error) {
    // Handle errors
    throw error;
  }
};

export const addCategory = async (formData: any) => {
  try {
   const response = await API.post("/backend/categories", formData);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deletecategory = async (id: number) => {
  try {
    const response = await API.delete(`/backend/categories/${id}/delete`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getCategorybyId = async (id: any) => {
  try {
    const response = await API.get(`/backend/categories/${id}/edit`);
    return response.data;
  }
  catch (error) {
    throw error;
  }
}


export const updatecategory = async (
  id: number,
  values: any
): Promise<any> => {
  try {
    const response = await API.put(`/backend/categories/${id}/update`, values);
    return response.data;
  } catch (error) {
    throw error;
  }
}


export const getallnewsletter = async () => {
  try {
    const response = await API.get('/backend/news');
    return response.data;
  } catch (error) {
    throw error;
  }
}

export const addPackage  = async (data: any) => {
  try {
    const response = await API.post('/backend/packages', data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const editPackage  = async (id: any) => {
  try {
    const response = await API.get(`/backend/packages/${id}/edit`);
    return response.data
  } catch (error) {
    throw error;
  }
}

export const updatePackage  = async (id: any, data: any) => {
  try {
    const response = await API.put(`/backend/packages/${id}/update`, data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const GetSettingData = async () => {
  try {
    const response = await API.get('/settings');
    return response.data
  } catch (error) {
    throw error;
  }
}

export const updateSetting = async (
  id: any,
  formData: FormData
): Promise<any> => {
  try {
    const response = await API.put(`/backend/settings/${id}/update`, formData);
    return response;
  } catch (error) {
    throw error;
  }
}

export const newsletter = async (values: any) => {
  try {
    const response = await API.post('/news/add', values);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getAllPackages = async () => {
  try {
    const response = await API.get('/packages');
    return response.data
  } catch (error) {
    throw error;
  }
}

export const getAllStates = async () => {
  try {
    const response = await API.get('/business/state');
    return response.data
  } catch (error) {
    throw error;
  }
}

export const getpackage = async () => {
  try {
    const response = await API.get('/backend/packages/:id/edit');
    return response.data
  } catch (error) {
    throw error;
  }
}

export const updatepackage = async (
  id: any,
  values: any
): Promise<any> => {
  try {
    const response = await API.put(`/packages/${id}/update`, values);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deletePackage = async (id: any) => {
  try {
    const response = await API.delete(`/backend/packages/${id}/delete`);
    return response;
  } catch (error) {
    throw error;
  }
}


////////////////////////// Business Routes /////////////////////////

export const getMyBusiness = async (userId : any ) => {
  try {
    const response  = await API.get(`/business/user/${userId}`)
    return response.data
  }
  catch (error) {
    throw error
  }
}

export const addBusiness = async (formData : any) => {
  try {
    const response = await API.post('/business', formData)
    return response
  }
  catch (error) {
    throw error
  }
}

export const getAllBusiness = async (page : any , categoryId : any , state   :any) => {
  try {
    const response = await API.get('/business', {
      params: { page , categoryId , state } 
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const getPremiumBusiness = async (page : any , categoryId : any , state   :any) => {
  try {
    const response = await API.get('/premium', {
      params: { page , categoryId , state } 
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const getHomeFeaturedBusiness = async () => {
  try {
    const response = await API.get('/business/all');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateBusinessStatus = (id : any, data : any) => {
  try {
    const response = API.put(`/backend/business/${id}/updatestatus`, data);
    return response;
  } catch (error) {
    throw error;
  }
};


export const getmlmBusiness = async (page : any , categoryId : any , state   :any) => {
  try {
    const response = await API.get('/backend/business/mlm', {
      params: { page , categoryId , state } 
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getnet4surgeBusiness = async (page : any , categoryId : any , state   :any) => {
  try {
    const response = await API.get('/backend/business/net4surge', {
      params: { page , categoryId , state } 
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getBusinesuser = async (userId:any, page : any , categoryId : any , state   :any) => {
  try {
    const response = await API.get(`/backend/business/${userId}/user`, {
      params: { page, categoryId, state }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const getBusinessDetail = async (slug: any) => {
  try {
    const resposne  = await API.get(`/business/${slug}/`)
    return resposne.data
  }
  catch (error) {
    throw error
  }
}


export const deletebusiness = async (id: number) => {
  try {
    const response = await API.delete(`/backend/business/${id}/delete`);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const BusinessVerify = async (id: number) => {
  try {
    const response = await API.put(`/backend/business/${id}/verify`);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const BusinessTrusted = async (id: number) => {
  try {
    const response = await API.put(`/backend/business/${id}/trusted`);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const BusinessVerified = async (id: any, data: any) => {
  try {
    const response = await API.put(`/business/${id}/status`, data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const BusinessUpdate = async (id: any, data: any) => {
  try {
    const response = await API.put(`/business/${id}/update`, data);
    return response;
  } catch (error) {
    throw error;
  }
}



export const EditBusiness = async (id: any) => {
  try {
    const response = await API.get(`/business/${id}/edit`);
    return response.data
  } catch (error) {
    throw error;
  }
}


export const createRazorpayOrder = async (orderData: any) => {
  try {
      const response = await API.post(`/create-order`, orderData);
      return response.data;
  } catch (error) {
      console.error('Error creating Razorpay order:', error);
      throw error;
  }
};


export const verifyBusinessPayment = async (paymentData: { razorpay_payment_id: any; razorpay_order_id: any; razorpay_signature: any }) => {
  try {
      const response = await API.post(`/verify-payment`, paymentData);
      return response.data;
  } catch (error) {
      console.error('Error verifying payment:', error);
      throw error;
  }
};

export const deleteimage = async (businessId: any, imageIndex: any) => {
  try {
    const response = await API.delete(`/business/${businessId}/delete-image`, {
      data: { imageIndex }
    });
    return response;
  } catch (error) {
    console.error(error);
    throw new Error('Failed to delete image');
  }
};


//Feature Routes
export const getFeature = async () => {
  try {
    const response = await API.get('/backend/features');
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const userPackeage = async (data: any) => {
  try {
    const response = await API.put(`/user-packages`, data);
    return response;
  } catch (error) {
    throw error;
  }
}


//testimonial
export const testimonial = async () => {
  try {
    const response = await API.get('/testimonial');
    return response;
  } catch (error) {
    throw error;
  }
}

//user-plan
export const Userplan = async (data: any) => {
  try {
    const response = await API.post('/user-packages', data);
    return response;
  } catch (error) {
    throw error;
  }
};
export const templeteform = async (data: any) => {
  try {
    const response = await API.post('/templeate', data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const templetedata = async () => {
  try {
    const response = await API.get('/templeate/userid');
    return response;    
  } catch (error) {
    throw error;
  }
};

export const productgetuserwise = async () => {
  try {
    const response = await API.get('/business-products/user');
    return response;
  } catch (error) {
    throw error;
  }
}


export const productadd = async (data: any) => {
  try {
    const response = await API.post('/business-products', data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const productedit = async (id: any) => {
  try {
    const response = await API.get(`/business-products/${id}/edit`);
    return response.data
  } catch (error) {
    throw error;
  }
}
export const productupdate = async (id: any,data: any) => {
  try {
    const response = await API.put(`/business-products/${id}/update`, data);
    return response.data
  } catch (error) {
    throw error;
  }
}

export const productdelete = async (id: number) => {
  try {
    const response = await API.delete(`/business-products/${id}/delete`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const addTeamMember = async (data: any) => {
  try {
    const response = await API.post('/team-members', data);
    return response;
  } catch (error) {
    throw error;
  }
};
export const updateTeamMember = async (id: any,data: any) => {
  try {
    const response = await API.put(`/team-members/${id}/update`, data);
    return response.data
  } catch (error) {
    throw error;
  }
}

export const teamgetuserwise = async () => {
  try {
    const response = await API.get('/team-members/user');
    return response;
  } catch (error) {
    throw error;
  }
}
export const teamedit = async (id: any) => {
  try {
    const response = await API.get(`/team-members/${id}/edit`);
    return response.data
  } catch (error) {
    throw error;
  }
}
export const teamdatadelete = async (id: number) => {
  try {
    const response = await API.delete(`/team-members/${id}/delete`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const servicegetuserwise = async () => {
  try {
    const response = await API.get('/services/user');
    return response;
  } catch (error) {
    throw error;
  }
}


export const serviceadd = async (data: any) => {
  try {
    const response = await API.post('/services', data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const serviceedit = async (id: any) => {
  try {
    const response = await API.get(`/services/${id}/edit`);
    return response.data
  } catch (error) {
    throw error;
  }
}
export const serviceupdate = async (id: any,data: any) => {
  try {
    const response = await API.put(`/services/${id}/update`, data);
    return response.data
  } catch (error) {
    throw error;
  }
}

export const servicedelete = async (id: number) => {
  try {
    const response = await API.delete(`/services/${id}/delete`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const clientgetuserwise = async () => {
  try {
    const response = await API.get('/client/user');
    return response;
  } catch (error) {
    throw error;
  }
}

export const clientadd = async (data: any) => {
  try {
    const response = await API.post('/client', data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const clientedit = async (id: any) => {
  try {
    const response = await API.get(`/client/${id}/edit`);
    return response.data
  } catch (error) {
    throw error;
  }
}
export const clientupdate = async (id: any,data: any) => {
  try {
    const response = await API.put(`/client/${id}/update`, data);
    return response.data
  } catch (error) {
    throw error;
  }
}

export const clientdelete = async (id: number) => {
  try {
    const response = await API.delete(`/client/${id}/delete`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const achivmentgetuserwise = async () => {
  try {
    const response = await API.get('/achivment/user');
    return response;
  } catch (error) {
    throw error;
  }
}


export const achivmentadd = async (data: any) => {
  try {
    // console.log(data);
    const response = await API.post('/achivment', data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const achivmentedit = async (id: any) => {
  try {
    const response = await API.get(`/achivment/${id}/edit`);
    return response.data
  } catch (error) {
    throw error;
  }
}
export const achivmentupdate = async (id: any,data: any) => {
  try {
    // console.log(data);
    const response = await API.put(`/achivment/${id}/update`, data);
    return response.data
  } catch (error) {
    throw error;
  }
}

export const achivmentdelete = async (id: number) => {
  try {
    const response = await API.delete(`/achivment/${id}/delete`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const domainadd = async (id: any, data: any) => {
  try {
    const response = await API.put(`/user/${id}/domain`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const domainget = async () => {
  try {
    const response = await API.get('/user/domain');
    return response;
  } catch (error) {
    throw error;
  }
}

export const domainnamecheak = async (data: any) => {
  try {
    const response = await API.post('/user/domain/check', data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const domainfind = async (data:any) => {
  try {
    const response = await API.post('/find/domain', data);
    return response;
  } catch (error) {
    throw error;
  }
}

export const seoadd = async (id: any, data: any) => {
  try {
    const response = await API.put(`/user/${id}/seo`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const templateupdate = async (id: any,data: any) => {
  try {
    const response = await API.put(`/templeate/${id}/update`, data);
    return response.data
  } catch (error) {
    throw error;
  }
}

export const packeagevalied = async () => {
  try {
    const response = await API.get('/user-packages/user');
    return response;
  } catch (error) {
    throw error;
  }
}

export const addReview = async (id: any, data: any) => {
  try {
    const response = await API.post(`/business/${id}/review`, data);
    return response;
  } catch (error) {
    throw error;
  }
};