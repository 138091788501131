import React, { useEffect, useState } from "react";
import {  Carousel, Col, Input, Row } from "antd";
import {  FaStar, FaStarHalf } from "react-icons/fa";

const reviewsData = [
  {
    name: "Jessica Brown",
    role: "Founder @ CEO",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    rating: 5,
    imgSrc: "https://gaviaspreview.com/wp/lestin/wp-content/uploads/2023/07/testimonial-3.jpg"
  },
  {
    name: "Kevin Smith",
    role: "Director",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    rating: 4.5,
    imgSrc: "https://gaviaspreview.com/wp/lestin/wp-content/uploads/2023/07/testimonial-2.jpg"
  },
  {
    name: "Jessica Brown",
    role: "Founder @ CEO",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    rating: 5,
    imgSrc: "https://gaviaspreview.com/wp/lestin/wp-content/uploads/2023/07/testimonial-3.jpg"
  },
  {
    name: "Kevin Smith",
    role: "Director",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    rating: 4.5,
    imgSrc: "https://gaviaspreview.com/wp/lestin/wp-content/uploads/2023/07/testimonial-2.jpg"
  }
  // Add more reviews as needed
];

const ClientReview = () => {
  const [slidesToShow, setSlidesToShow] = useState(1);

  useEffect(() => {
    const handleResize = () => {
      // Update slidesToShow based on window width
      if (window.innerWidth > 1200) {
        setSlidesToShow(2);
      } else if (window.innerWidth <= 1170 && window.innerWidth >= 1060) {
        setSlidesToShow(1);
      } else if (window.innerWidth >= 768) {
        setSlidesToShow(1);
      } else {
        setSlidesToShow(1);
      }
    };
    window.addEventListener('resize', handleResize);

    // Call handleResize once to set initial state
    handleResize();

    // Cleanup function to remove event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderStars = (rating : any) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;
    return (
      <div className="flex justify-content-center pt-3">
        {[...Array(fullStars)].map((_, index) => (
          <FaStar key={index} size={15}  className="pr-0.5 text-yellow" />
        ))}
        {halfStar && <FaStarHalf size={15}  className="pr-0.5 text-yellow" />}
      </div>
    );
  };

  return (
    <>
      <div
        style={{
          backgroundImage:
            "url('https://gaviaspreview.com/wp/lestin/wp-content/uploads/2023/08/bg-10.png')",
          backgroundColor: "#f5f5f5",
          height: "auto",
          width: "100%",
        }}
      >
        <Row className="md:pt-8 md:ps-8 md:px-8 xl:pt-12 xl:ps-48 xl:px-12 p-6 sm:pt-8 sm:ps-8 sm:px-8">
          <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={10} className="md:pt-8 md:ps-8 md:px-8 sm:pt-8 sm:ps-8 sm:px-8">
            <p
              className="font-bold text-md tracking-widest mb-3 text-yellow"
            >
              OUR FEEDBACKS
            </p>
            <p className="text-5xl font-extrabold tracking-tight">
              What they’re say about us
            </p>
            <div className="d-flex pt-4">
              <div
                className="inline-block p-2 border-2 bg-yellow-700"
              >
                <FaStar
                  size={20}
                  style={{ color: "#ffffff" }}
                  className="text-white"
                />
              </div>
              <div
                className="inline-block p-2 border-2 bg-yellow-700"
              >
                <FaStar
                  size={20}
                  style={{ color: "#ffffff" }}
                  className="text-white"
                />
              </div>{" "}
              <div
                className="inline-block p-2 border-2 bg-yellow-700"
              >
                <FaStar
                  size={20}
                  style={{ color: "#ffffff" }}
                  className="text-white"
                />
              </div>{" "}
              <div
                className="inline-block p-2 border-2 bg-yellow-700"
              >
                <FaStar
                  size={20}
                  style={{ color: "#ffffff" }}
                  className="text-white"
                />
              </div>{" "}
              <div className="inline-block p-2 border-2 bg-yellow-700" >
                <FaStar
                  size={20}
                  style={{ color: "#ffffff" }}
                  className="text-white"
                />
              </div>
            </div>
            <div className="font-bold text-gray-500 pt-4 " style={{ fontSize: "14px" }}>
              Trust score 4.5 (Based on 2,500 reviews)
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={16} xxl={14} className="md:pl-12 md:pb-12">
            <Carousel autoplay autoplaySpeed={3000} slidesToShow={slidesToShow} draggable dots={false}>
              {reviewsData.map((review, index) => (
                <div key={index} className="bg-white p-5 mr-24 slick-slide">
                  <Row gutter={16} justify={"center"}>
                    <Col span={6} className="">
                      <img
                        src={review.imgSrc}
                        className="mx-auto border-2 p-1 border-yellow-400 w-16 h-16 rounded-full"
                        alt="Profile"
                      />
                      {renderStars(review.rating)}
                    </Col>
                    <Col span={18} className="">
                      <p className="font-extrabold " style={{ fontSize: "20px" }}>
                        {review.name}
                      </p>
                      <p className="text-gray-500 text-semibold" style={{ fontSize: "14px" }}>
                        {review.role}
                      </p>
                      <p className="text-gray-500 pt-4" style={{ fontSize: "16px" }}>
                        {review.text}
                      </p>
                    </Col>
                  </Row>
                </div>
              ))}
            </Carousel>
          </Col>
        </Row>
      </div>
      
    </>
  );
};

export default ClientReview;
