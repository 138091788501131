import React, { useContext } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import PrivateRoute from './PrivateRoute';
import Login from './Pages/Auth/Login';
import NotFound from './Components/NotFound';
import { backendRoutes } from './Routes/backendRoutes';
import Master from './Components/backend/Master';
import frontendRoutes from './Routes/frontendRoutes';
import {  AuthProvider } from './Pages/Auth/AuthContext';
import FrontendMaster from './Components/frontend/Master';
import DashboardUser from './Pages/frontend/Home/Dashboard';
import Mlogin from './Pages/frontend/Auth/Login';
import Theme3Homepage from './Pages/Themes/Theme3/Home';
import Theme2Homepage from './Pages/Themes/Theme2/Home';


const App: React.FC = () => {

  return (
    <>
  
        <AuthProvider> 
      <BrowserRouter>
     

        <Routes>
        <Route path="/login" element={<Login />} />  
        <Route path="/mlm/login" element={<Mlogin />} />
        <Route path="/themes/3" element={<Theme3Homepage/>} />  
        <Route path="/theme/2" element={<Theme2Homepage />} />
          <Route element={<PrivateRoute  children={<DashboardUser />} />}>

            {backendRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={<Master children={<route.component />} />}
              />
            ))}
          </Route>

          {frontendRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={<FrontendMaster children={<route.component />} />}
            />
          ))}
          
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
      </AuthProvider>
   
      
    </>
  );
}

export default App;
