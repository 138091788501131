import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import theme1 from "../../../assets/frontendassets/themes/helthcare.png";
import theme2 from "../../../assets/frontendassets/themes/beutycare.png";
import theme3 from "../../../assets/frontendassets/themes/it-services.jpg";
import theme4 from "../../../assets/frontendassets/themes/food&nutraction.avif";
import theme5 from "../../../assets/frontendassets/themes/theme5.jpg";
import { packeagevalied, templetedata } from "../../../utils/API";
import { message } from 'antd';

const themes = [
  { src: theme1, alt: '1', name: 'Health & Fitness' },
  { src: theme2, alt: '2', name: 'Beauty Care' },
  { src: theme3, alt: '3', name: 'Information Technology' },
  { src: theme4, alt: '4', name: 'Food & Nutrition' },
  { src: theme5, alt: '5', name: 'Education' },
];

const Theme1HomePage = () => {
  const [selectedTheme, setSelectedTheme] = useState<string | null>(null);
  const [hasAccess, setHasAccess] = useState<boolean>(false);
  const [warningMessage, setWarningMessage] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleSelect = (theme: string) => {
    console.log("Selected theme:", theme);
    setSelectedTheme(theme);
  };

  const handleSubmit = () => {
    if (selectedTheme) {
      console.log("Submitting theme:", selectedTheme);
      navigate(`/backend/themes/${selectedTheme}`);
    }
  };

  const handleBuyPackage = () => {
    navigate('/backend/pricing-plan');
  };

  const fetchDomain = async () => {
    try {
      const response = await packeagevalied();
      if (!response.data || !response.data._id) {
        setWarningMessage('To upgrade your plan please contact on +91 98187 55350.');
      } else {
        setHasAccess(true);
        const templateResponse = await templetedata();
        if (templateResponse.data && templateResponse.data.templateId) {
          setSelectedTheme(templateResponse.data.templateId);
          setWarningMessage(null);
        } else {
          setSelectedTheme(null);
          setWarningMessage('No template selected.');
        }
      }
    } catch (error: any) {
      message.error(error.response?.data?.message || 'An error occurred while fetching products');
    }
  };

  useEffect(() => {
    fetchDomain();
  }, []);

  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-2 mx-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {themes.map((theme, index) => (
            <div key={index} className="p-4">
              <div className={`h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden ${!hasAccess && 'opacity-50'}`}>
                <img
                  className="h-auto max-h-80 md:max-h-36 w-full object-cover object-center"
                  src={theme.src}
                  alt={theme.alt}
                />
                <div className="p-4 text-center">
                  <input
                    type="radio"
                    id={`theme${index}`}
                    name="theme"
                    value={theme.alt}
                    onChange={() => handleSelect(theme.alt)}
                    checked={selectedTheme === theme.alt}
                    className="mr-2"
                    disabled={!hasAccess}
                  />
                  <label htmlFor={`theme${index}`}> {theme.name} </label>
                </div>
              </div>
            </div>
          ))}
        </div>
        {hasAccess && (
          <div className="text-center mt-6">
            <button
              onClick={handleSubmit}
              disabled={!selectedTheme}
              className="bg-yellow-500 text-white px-4 py-2 rounded disabled:opacity-50"
            >
              Next
            </button>
          </div>
        )}
        {!hasAccess && (
          <div className="text-center mt-6">
            {warningMessage && (
              <div className="mb-4 text-red-500 text-xl font-semibold">
                {warningMessage}
              </div>
            )}
            <button
              onClick={handleBuyPackage}
              className="bg-yellow-500 text-white px-4 py-2 rounded"
            >
              Buy Package
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default Theme1HomePage;
