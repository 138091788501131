import React, { useEffect, useState } from 'react';
import image1 from "../../../assets/frontendassets/first-img1.png";
import { Button, Card, Col, Divider, Row, Space, Typography } from 'antd';
import { MailOutlined, PhoneOutlined, CodeOutlined, TranslationOutlined, SyncOutlined, TeamOutlined } from '@ant-design/icons';
import { IoIosArrowForward } from "react-icons/io";
import { FaFacebook, FaInstagram, FaLinkedin, FaPinterest, FaTwitter } from "react-icons/fa"
import { Link } from 'react-router-dom';
import axios from 'axios';

const { Title, Paragraph } = Typography;

const Theme3Homepage = () => {
  const [data, setData] = useState<any>(null);
  const imageUrl = process.env.REACT_APP_IMAGE_URL + '/template/';
  console.log(imageUrl)


  // const imageurl = process.env.REACT_APP_IMAGE_URL

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('http://192.168.1.11:5002/api/templeate/66b4a2dc49f09604abfb8e85/edit');
        console.log(response.data.header.logo);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
    <div className='overflow-x-hidden'>
    <div className="min-h-screen flex flex-col items-center bg-gradient-to-b from-blue-50 to-white">
      <header className="w-full py-4 bg-white shadow">
        <div className="container mx-auto flex justify-between items-center px-4">
          <h1 className="text-2xl font-bold text-indigo-600">{data?.header?.title}</h1> 
          {/* <nav>
            <ul className="flex space-x-4">
              <li><a href="#home" className="text-gray-700 hover:text-indigo-600">Lorem</a></li>
              <li><a href="#features" className="text-gray-700 hover:text-indigo-600">Lorem</a></li>
              <li><a href="#pricing" className="text-gray-700 hover:text-indigo-600">Lorem</a></li>
              <li><a href="#contact" className="text-gray-700 hover:text-indigo-600">Lorem</a></li>
            </ul>
          </nav> */}
        </div>
      </header>
      <main className="flex-grow flex items-center justify-center px-4 py-8">
        <div className="container mx-auto flex flex-col md:flex-row items-center justify-center w-full">
          <div className="md:text-left max-w-2xl md:w-1/2 mb-8 md:mb-0 flex flex-col items-center md:items-start p-4 m-5">
            <h2 className="text-4xl font-bold text-gray-800 mb-4">{data?.header.title}</h2>
            <p className="text-gray-600 mb-6">{data?.header.description}</p>
            <div className="flex justify-center md:justify-start space-x-4 mb-8">
              <button className="bg-indigo-600 text-white py-2 px-4 rounded shadow">{data?.header.buttonText}</button>
              {/* <button className="bg-pink-600 text-white py-2 px-4 rounded shadow">Download</button> */}
            </div>
          </div>
          <div className="md:w-1/2 flex justify-center">
                <img 
                  src={data?.header.logo ? `${imageUrl}${data.header.logo}` :image1} 
                  alt="App screenshot" 
                  className="rounded-lg shadow-lg" 
                />
              </div>
        </div>
      </main>

      <section className="bg-white py-16">
        <div className="container mx-auto px-4">
          <div className="text-center mb-12">
            <Title level={2}>
              Team management mobile apps don't get better than Pavo. It's probably the best app in the world for this purpose. Don't hesitate to give it a try today and you will fall in love with it.
            </Title>
          </div>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={8}>  
              <Card
                hoverable
                style={{ backgroundColor: '#f0f9ff' }}
              >
                <div className="flex flex-col items-center text-center">
                  <MailOutlined className="text-blue-600 text-4xl mb-4" />
                  <Title level={3}>Platform Integration</Title>
                  <Paragraph>Your sales force can use the app on any smartphone platform without compatibility issues</Paragraph>
                </div>  
              </Card>
            </Col>
            <Col xs={24} md={8}>
              <Card
                hoverable
                style={{ backgroundColor: '#f0f9ff' }}
              >
                <div className="flex flex-col items-center text-center">
                  <PhoneOutlined className="text-blue-600 text-4xl mb-4" />
                  <Title level={3}>Easy On Resources</Title>
                  <Paragraph>Works smoothly even on older generation hardware due to our optimization efforts</Paragraph>
                </div>
              </Card>
            </Col>
            <Col xs={24} md={8}>
              <Card
                hoverable
                style={{ backgroundColor: '#f0f9ff' }}
              >
                <div className="flex flex-col items-center text-center">
                  <CodeOutlined className="text-blue-600 text-4xl mb-4" />
                  <Title level={3}>Great Performance</Title>
                  <Paragraph>Optimized code and innovative technology ensure no delays and ultra-fast responsiveness</Paragraph>
                </div>
              </Card>
            </Col>
            <Col xs={24} md={8}>
              <Card
                hoverable
                style={{ backgroundColor: '#f0f9ff' }}
              >
                <div className="flex flex-col items-center text-center">
                  <TranslationOutlined className="text-blue-600 text-4xl mb-4" />
                  <Title level={3}>Multiple Languages</Title>
                  <Paragraph>Choose from one of the 40 languages that come pre-installed and start selling smarter</Paragraph>
                </div>
              </Card>
            </Col>
            <Col xs={24} md={8}>
              <Card
                hoverable
                style={{ backgroundColor: '#f0f9ff' }}
              >
                <div className="flex flex-col items-center text-center">
                  <SyncOutlined className="text-blue-600 text-4xl mb-4" />
                  <Title level={3}>Free updates</Title>
                  <Paragraph>Don't worry about future costs, pay once and receive all future updates at no extra cost</Paragraph>
                </div>
              </Card>
            </Col>
            <Col xs={24} md={8}>
              <Card
                hoverable
                style={{ backgroundColor: '#f0f9ff' }}
              >
                <div className="flex flex-col items-center text-center">
                  <TeamOutlined className="text-blue-600 text-4xl mb-4" />
                  <Title level={3}>Community Support</Title>
                  <Paragraph>Register the app and get access to knowledge and ideas from the Pavo online community</Paragraph>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </section>

      {/* <section className="bg-white py-16">
        <div className="container mx-auto px-4">
          <Row gutter={[16, 16]} align="middle">
            <Col xs={24} md={12} className="flex flex-col items-start">
              <Title level={2} className="text-black font-bold mb-4">Results driven ground breaking technology</Title>
              <Paragraph className="font-bold text-gray-600 mb-4">
                Based on our team's extensive experience in developing line of business applications and constructive customer feedback we reached a new level of revenue.
              </Paragraph>
              <Paragraph className="font-bold text-gray-600 mb-4">
                We enjoy helping small and medium sized tech businesses take a shot at established Fortune 500 companies
              </Paragraph>
            </Col>
            <Col xs={24} md={12} className="flex justify-center md:justify-end">
              <img src={image1} alt="App screenshot" className="rounded-lg shadow-lg" />
            </Col>
          </Row>
        </div>
      </section> */}

      <section className="bg-white py-16">
        <div className="container mx-auto px-4">
          <Row gutter={[16, 16]} align="middle">
            <Col xs={24} md={12} className="flex justify-center">
              <img src={image1} alt="App screenshot" className="rounded-lg shadow-lg" />
            </Col>
            <Col xs={24} md={12} className="flex flex-col justify-center pl-4">
              <Title level={2} className="text-black font-bold mb-4">{data?.section.title}</Title>
              <div className="flex items-center text-gray-600 mb-2">
                <IoIosArrowForward className="mr-2" />
                <Paragraph className="mb-0 font-bold text-gray-600 ">{data?.section.description}</Paragraph>
              </div>
              <div className="flex items-center text-gray-600 mb-2">
                <IoIosArrowForward className="mr-2" />
                <Paragraph className="mb-0 font-bold text-gray-600">Features that will help you and your marketers</Paragraph>
              </div>
              <div className="flex items-center text-gray-600 mb-2">
                <IoIosArrowForward className="mr-2" />
                <Paragraph className="mb-0 font-bold text-gray-600">Features that will help you and your marketers</Paragraph>
              </div>
              <div className="flex justify-center md:justify-start space-x-4 mt-4 mb-2">
                <button className="bg-indigo-600 text-white py-2 px-4 rounded shadow">Lightbox</button>
                <button className="bg-gray-600 text-white py-2 px-4 rounded shadow">Details</button>
              </div>
            </Col>
          </Row>
        </div>
      </section>
  
    </div>


    <section className="text-gray-600 body-font overflow-hidden">
  <div className="container mx-auto px-5 py-2">
    <div className="flex flex-col text-center w-full mb-16">
    <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold title-font text-indigo-500">
  Our Products
</h1>

<p className="lg:w-2/3 mx-auto leading-relaxed text-lg font-bold font-semibold">
  Explore our range of top-quality products designed to meet your needs.
</p>

    </div>

    <div className="flex flex-wrap justify-center -m-4">
      <div className="p-4 w-full md:w-1/3 flex-grow">
        <div className="h-full bg-blue-100 rounded-2xl overflow-hidden">
          <div className="rounded-lg h-64 overflow-hidden">
            <img
              alt="content"
              className="object-cover object-center h-full w-full"
              src="https://thumbs.dreamstime.com/b/business-management-success-concept-gears-showing-steps-creative-vision-innovation-idea-to-financial-personal-185365905.jpg"
            />
          </div>
          <div className="p-6">
            <h2 className="text-xl font-medium title-font text-gray-900 mt-5">
              Shooting Stars
            </h2>
            <p className="text-base leading-relaxed mt-2">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum, asperiores in. Nesciunt explicabo dolores sunt eos, dolorem necessitatibus exercitationem amet accusantium blanditiis voluptate quae nisi delectus corrupti rerum totam non.
            </p>
            <a className="text-indigo-500 inline-flex items-center mt-3">
              Learn More
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                className="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7" />
              </svg>
            </a>
          </div>
        </div>
      </div>

      <div className="p-4 w-full md:w-1/3 flex-grow">
        <div className="h-full bg-blue-100 rounded-2xl overflow-hidden">
          <div className="rounded-lg h-64 overflow-hidden">
            <img
              alt="content"
              className="object-cover object-center h-full w-full"
              src="https://lifexchangesolutions.com/wp-content/uploads/2019/12/What-is-Management-1024x614.jpg"
            />
          </div>
          <div className="p-6">
            <h2 className="text-xl font-medium title-font text-gray-900 mt-5">
              The Catalyzer
            </h2>
            <p className="text-base leading-relaxed mt-2">
              Swag shoivdigoitch literally meditation subway tile tumblr cold-pressed.
              Gastropub street art beard dreamcatcher neutra, ethical XOXO
              lumbersexual.
            </p>
            <a className="text-indigo-500 inline-flex items-center mt-3">
              Learn More
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                className="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7" />
              </svg>
            </a>
          </div>
        </div>
      </div>

      <div className="p-4 w-full md:w-1/3 flex-grow">
        <div className="h-full bg-blue-100 rounded-2xl overflow-hidden">
          <div className="rounded-lg h-64 overflow-hidden">
            <img
              alt="content"
              className="object-cover object-center h-full w-full"
              src="https://cdn.pixabay.com/photo/2022/06/12/15/04/time-management-7258198_640.jpg"
            />
          </div>
          <div className="p-6">
            <h2 className="text-xl font-medium title-font text-gray-900 mt-5">
              The 400 Blows
            </h2>
            <p className="text-base leading-relaxed mt-2">
              Swag shoivdigoitch literally meditation subway tile tumblr cold-pressed.
              Gastropub street art beard dreamcatcher neutra, ethical XOXO
              lumbersexual.
            </p>
            <a className="text-indigo-500 inline-flex items-center mt-3">
              Learn More
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                className="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>





      <footer
  className="py-10 text-center"
  style={{
    background: 'linear-gradient(to bottom, #F1FAFE, #C8EBF9, #a3d6f3)',
  }}
>
  <div className="container mx-auto px-4">
    {/* <p className="text-2xl font-bold text-gray-600 mb-4">
      Pavo is a mobile application for marketing automation and you can reach the team at  
      <span className="text-blue-600 block">email@domain.com</span>
    </p> */}
    <div className="flex justify-center mt-6 gap-4 flex-wrap">
      <Link
        to="https://www.twitter.com/Net4Surge/"
        className="inline-block bg-gray-200 text-gray-600 p-3 rounded transition hover:bg-blue-300 hover:text-black"
      >
        <FaTwitter className="text-xl" />
      </Link>

      <Link
        to="https://www.facebook.com/net4surge/"
        className="inline-block bg-gray-200 text-gray-600 p-3 rounded transition hover:bg-blue-300 hover:text-black"
      >
        <FaFacebook className="text-xl" />
      </Link>

      <Link
        to="https://www.instagram.com/net4surge/"
        className="inline-block bg-gray-200 text-gray-600 p-3 rounded transition hover:bg-blue-300 hover:text-black"
      >
        <FaInstagram className="text-xl" />
      </Link>

      <Link
        to="https://www.linkedin.com/company/net4surge/"
        className="inline-block bg-gray-200 text-gray-600 p-3 rounded transition hover:bg-blue-300 hover:text-black"
      >
        <FaLinkedin className="text-xl" />
      </Link>
    </div>

    <div className="flex justify-center flex-wrap gap-4 mt-6 text-sm">
      <p className="text-gray-600 flex-wrap text-center">
        Article Details | Terms & Conditions | Privacy Policy
      </p>
      <p className="text-gray-600 flex-wrap text-center">
        Copyright &copy; Your name
      </p>
      <p className="text-gray-600 flex-wrap text-center">
        Distributed by: Themewagon
      </p>
    </div>
  </div>
</footer>


</div>
    </>

  );
};

export default Theme3Homepage;
