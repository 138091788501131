import React, { useContext, useEffect, useState } from 'react';
import { MenuOutlined } from '@ant-design/icons';
import { Button, Col, Layout, Row } from 'antd';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../../Pages/Auth/AuthContext';

const { Header } = Layout;

interface NavbarProps {
  collapsed: boolean;
  toggleSidebar: () => void;
  disableHoverEffect: () => void;
}
interface StaticRoute {
    kind: 'static';
    header: string;
  }
  
  interface DynamicRoute {
    kind: 'dynamic';
    header: (params: Record<string, string>) => string;
  }
  
  type RouteHeaderMap = {
    [key: string]: StaticRoute | DynamicRoute;
  };
  
const Navbar: React.FC<NavbarProps> = ({ collapsed, toggleSidebar, disableHoverEffect }) => {
  const location = useLocation();
  const [headerName, setHeaderName] = useState('');

  const ButtonStyle = {
    marginTop: '18px',
    background: 'transparent',
  };
  useEffect(() => {
    // Map your routes to header names
    const routeHeaderMap: RouteHeaderMap = {
      '/login': { kind: 'static', header: 'Login' },
      '/': { kind: 'static', header: 'Dashboard' },
      '/charts': { kind: 'static', header: 'Charts' },
      '/customer/:id/edit': { kind: 'dynamic', header: (params) => `Edit Customer ` },
    };
  
    // Set the header name based on the current route
    const matchingRoute = Object.keys(routeHeaderMap).find((route) => {
      const routePattern = new RegExp(`^${route.replace(/:[^/]+/g, '[^/]+')}$`);
      return routePattern.test(location.pathname);
    });
    setHeaderName(matchingRoute ? (
        routeHeaderMap[matchingRoute].kind === 'dynamic'
          ? (routeHeaderMap[matchingRoute] as DynamicRoute).header({
              id: 'extracted_id',
            })
          : (routeHeaderMap[matchingRoute] as StaticRoute).header
      ) : '');
      
  }, [location.pathname]);
  

  return (
    <Header className='header'>
      <Row >
        <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}>
          <Button
            type="text"
            icon={<MenuOutlined className='header-icon' /> }
            style={ButtonStyle}
            onClick={() => {
              toggleSidebar();
              disableHoverEffect();
            }}
            className='m-2' 
          />
        </Col>
        <Col xs={11} sm={11} md={11} lg={16} xl={16} xxl={16} className='mt-1'>
          <span className='font-semibold text-2xl ' >{headerName}</span>
        </Col>
        <Col xs={11} sm={11} md={11} lg={6} xl={6} xxl={6} className='d-flex justify-end'>
          <span className='font-semibold text-2xl p-3 text-gray-500 font-roboto'>
            {/* Hey {firstName} */}
          </span>
        </Col>
      </Row>
    </Header>
  );
};

export default Navbar;
