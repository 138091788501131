import React, { useEffect, useState } from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { RiAddFill } from 'react-icons/ri';
import Reviews from '../ClientReview/ClientReview';
import ListingsBenefits from './ListingBenefits/ListingBenefits';
import Banner from './Banner';
import { Row, Col, Spin, message } from 'antd';
import axios from 'axios';
import { getAllPackages } from '../../../utils/API';
import { config } from '../../../utils/setting';

const Pricing = () => {
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchPackages = async () => {
    try {
      const response = await getAllPackages();
      setPackages(response);
      setLoading(false);
    } catch (error) {
      message.error("Failed to fetch packages");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPackages();
  }, []);

  const [slidesToShow, setSlidesToShow] = useState(1);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1200) {
        setSlidesToShow(3);
      } else if (window.innerWidth <= 1170 && window.innerWidth >= 1060) {
        setSlidesToShow(2);
      } else if (window.innerWidth >= 768) {
        setSlidesToShow(1);
      } else {
        setSlidesToShow(1);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <div className="relative h-64 md:h-80 lg:h-96 bg-cover bg-center" style={{ backgroundImage: `url('https://gaviaspreview.com/wp/lestin/wp-content/uploads/2023/08/bg-10.jpg')` }}>
        <div className="absolute inset-0 bg-black bg-opacity-25 flex flex-col items-center justify-center px-4">
          <h1 className="text-white text-2xl md:text-5xl lg:text-6xl font-bold text-center">Explore The Worlds</h1>
          <p className="text-white text-sm md:text-lg lg:text-xl mt-2 text-center">People Don't Take, Trips Take People</p>
        </div>
      </div>
      <hr
        className="text-white"
        style={{
          marginLeft: "100px",
          marginRight: "100px",
          marginTop: " 30px",
          marginBottom: "30px",
        }}
      />
      <div className="center  md:pl-8 lg:pl-8 lg:pr-8 xl:pr-36 xl:pl-36 lg md:pr-8 pt-3 pb-2">
        <div className="w-full flex flex-row items-start justify-between pb-3">
          <div className="ml-4">
            <h2 className="font-bold text-yellow-500 ">AFFORDABLE PRICE</h2>
            <h1 className="text-2xl lg:text-5xl xl:text-5xl md:text-3xl  font-bold text-black-500">Choose our packages</h1>
          </div>
          <div className='hidden  sm:block md:block lg:block xl:block 2xl:block'>
            <button className="btn btn-xxl font-semibold d-flex bg-yellow-700 text-black items-center px-4 py-3  " style={{ transform: 'translateX(-30%)' }}>
              Add listing <RiAddFill className="ml-2 d-flex " />
            </button>
          </div>
        </div>

        {loading ? (
          <Spin size="large" />
        ) : (
          <div className="flex flex-wrap justify-center">
            {packages.map((pkg : any, index : any ) => (
              <div key={index} className="m-4 shadow-md rounded-lg flex-1 max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl w-full">
                <div className="bg-yellow-700 p-5 text-center text-black  shadow-lg rounded-t-lg w-full space-y-3">
                  <h1 className="text-1xl font-extrabold tracking-widest">{pkg.packageName || pkg.name}</h1>
                  <p className="text-4xl font-extrabold">
                    {config.currency}{pkg.price} {pkg.originalPrice && <del className="text-lg">{config.currency}{pkg.originalPrice}</del>}<span className="text-base">/{pkg.duraction}</span>
                  </p>
                  <div className="package">
                    <h2 className="font-bold">{pkg.description}</h2>
                  </div>
                </div>
                <div className="p-8 bg-white space-y-3 font-semibold rounded-b-lg w-full">
                 
                  <button className="btn bg-yellow-700 text-black rounded-md px-4 py-3">SUBSCRIBE</button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <Reviews />
      <Banner />
      <ListingsBenefits />
    </>
  );
};

export default Pricing;
