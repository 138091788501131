import { Row , Col } from 'antd'
import React from 'react'
import { BiLogoPlayStore } from 'react-icons/bi'
import { FaApple } from 'react-icons/fa'
import { PiAppleLogo } from 'react-icons/pi'

const DownloadLink = () => {
  return (
    <>
     <div
        style={{
          backgroundImage:
            "url('https://gaviaspreview.com/wp/lestin/wp-content/uploads/2023/07/bg-01.jpg')",
            backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
          backgroundColor: "#f5f5f5",
          height: "auto",
          width: "100%",
        }}
      >
        
        
    {/* <Row  className="md:pt-8 md:ps-8 md:px-8 xl:pt-12 xl:ps-12 xl:px-12 p-6 sm:pt-8 sm:ps-8 sm:px-8 mt-12">
        <Col xs={24} md={24} lg={18} xl={12} xxl={12}  className=' p-4 '>
            <p className='tracking-widest font-bold pb-8 text-yellow' >GET OUR FREE APP</p>
            <p className='text-5xl xs:text-3xl md:text-3xl lg:text-3xl text-white  font-extrabold' >Download our lestin application</p>
            <p className='text-lg font-bold text-gray-500 pt-8 pb-8'>Get our mobile app for easy and convenient usage</p>
            <Row gutter={[16, 16]} className='m-2'>
      <Col xs={24} sm={8} md={8} lg={8} xl={8}>
        <div className="btn btn-lg d-flex align-items-center pl-4 pr-4 bg-yellow-700" >
          {/* <BiLogoPlayStore size={30} style={{ color: "#ffffff" }} className='text-white' /> */}
          {/* <div className='text-white font-bold text-left text-sm pl-2 tracking-widest'>
            <p>GET IN</p>
            <p>GOOGLE PLAY</p>
          </div>
        </div>
      </Col>
      <Col xs={24} sm={8} md={8} lg={8} xl={8}>
        <div className="btn btn-lg d-flex align-items-center pl-4 pr-4" style={{ backgroundColor: "#ffffff", color: "#000000" }}>
          {/* <FaApple size={30} /> */}
          {/* <div className='font-bold text-left text-sm pl-2 tracking-widest'>
            <p>GET IN</p>
            <p>APP STORE</p>
          </div>
        </div>
      </Col>
    </Row>
        </Col>
       
    </Row> */}
    </div>
    </>
  )
}

export default DownloadLink