// src/components/InfoSection.jsx
import React from 'react';
import { Col, Row } from 'antd';
import { DesktopOutlined, LockOutlined, MobileOutlined, CustomerServiceOutlined, DollarOutlined, GlobalOutlined } from '@ant-design/icons';
import { FaShieldAlt, FaUserFriends } from 'react-icons/fa';
import { DiResponsive } from "react-icons/di";
import { MdOutlineSupportAgent } from 'react-icons/md';
import { GiReceiveMoney } from 'react-icons/gi';
import { FaEarthAmericas } from 'react-icons/fa6';

const InfoSection = () => {
  return (
    <div className="bg-gray-100 py-10 fun-facts relative py-12 bg-cover bg-center g:px-0 lg:ps-0 xl:px-36 xl:ps-36  md:px-0 md:ps-0 sm:ps-5 xs:ps-5">
      <div className="container mx-auto text-center">
        <h2 className="text-4xl font-bold mb-4">Build, support, <span className="text-yellow-500">innovate</span></h2>
        <p className="text-gray-600 mb-10 text-2xl">
          By Net4surge, we are committed to providing the best experience for our customers and partners.
          We merge simplicity with innovation to create efficiencies for businesses and solve pressing problems.
        </p>
        <Row gutter={[16, 16]} justify="center">
          <Col xs={24} sm={12} md={8} lg={4}>
            <div className="flex flex-col items-center">
              <DesktopOutlined className="text-6xl text-yellow-500 mb-2" />
              <p className="font-normal text-xl">Cutting-edge technology</p>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={4}>
            <div className="flex flex-col items-center">
              <FaShieldAlt className="text-6xl text-yellow-500 mb-2" />
              <p className="font-normal text-xl">Secure online platform</p>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={4}>
            <div className="flex flex-col items-center">
              <DiResponsive className="text-6xl text-yellow-500 mb-2" />
              <p className="font-normal text-xl">User-friendly &nbsp; products</p>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={4}>
            <div className="flex flex-col items-center">
              <MdOutlineSupportAgent  className="text-6xl text-yellow-500 mb-2" />
              <p className="font-normal text-xl">Active customer support</p>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={4}>
            <div className="flex flex-col items-center">
              <GiReceiveMoney  className="text-6xl text-yellow-500 mb-2" />
              <p className="font-normal text-xl">Cost-effective solutions</p>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={4}>
            <div className="flex flex-col items-center">
              <FaEarthAmericas  className="text-6xl text-yellow-500 mb-2" />
              <p className="font-normal text-xl">Indian firm with global vision</p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default InfoSection;
