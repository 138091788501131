import React, { useContext, useState } from "react";
import { Form, Input, message, Modal, Button } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Auth.css";
import Logo from "../../../assets/backendassets/logo.png";
import { AuthContext } from "../../Auth/AuthContext";
import { loginwithmlm } from "../../../utils/API";

const Mlogin: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  
  const [loginData, setLoginData] = useState<any>(null);
  const { setAuthData } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const onFinish = (values: any) => {
    setIsLoading(true);
    loginwithmlm(values)
      .then((res : any) => {
        if (res.status === 200) {
        setAuthData({ token: res.data.token, roleId: res.data.user.roleId , user : res.data.user });
        localStorage.setItem("token", res.data.token);
        if(res.data.user.roleId === 1){
          navigate("/backend/dashboard");
        } else {
          navigate("/listing");
        }
        }
      })
      .catch((error : any) => {
        setIsLoading(false);
        message.error(error.response?.data?.message || "An error occurred");
      });
  };




  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-2xl border border-blue-500 rounded-lg">
        <div className="sm:mx-auto sm:w-full sm:max-w-xl d-flex justify-center">
         <Link to="/" className="hover:text-orange-500">
         <img
            src={Logo}
            alt=""
            style={{ width: "250px", height: "100px", marginTop: "20px" }}
          /></Link>
        </div>

        <div className="mt-3 sm:mx-auto sm:w-full sm:max-w-xl">
          <Form initialValues={{ remember: true }} onFinish={onFinish} className="m-4">
            <label className="font-bold m-2">Username</label>
            <Form.Item
              name="username"
              rules={[{ required: true, message: "Please enter your username!" }]}
            >
              <Input placeholder="Enter Username" className="antInputClass rounded" />
            </Form.Item>
            <label className="font-bold m-2">Password</label>
            <Form.Item
              className="mt-2 mb-2"
              name="password"
              rules={[{ required: true, message: "Please enter your password!" }]}
            >
              <Input.Password placeholder="Enter Password" size="large" className="antInputClass rounded" />
            </Form.Item>

            <Form.Item>
              <button
                style={{ width: "100%", marginTop: "10px" }}
                disabled={isLoading}
                className="flex w-full p-3 justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm bg-yellow-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {isLoading ? 'Sign in...' : 'Sign in'}
              </button>
            </Form.Item>
          </Form>
          <p className="mb-2 pr-6 text-end">

          </p>
        </div>
      </div>

    </div>
  );
};

export default Mlogin;