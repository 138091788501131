import React, { useState, useEffect, useContext } from 'react';
import { Col, Row, message } from 'antd';
import { Link } from 'react-router-dom';
import MyCard from '../../../Content/Card';
// import { getallproducts, getCustomers, getOrder } from '../../../utils/API';
import { AuthContext } from '../../../Pages/Auth/AuthContext';
import "../../../Styles/Dashboard.css";

const Dashboard: React.FC = () => {  
    const { authData } = useContext(AuthContext);
    const [productCount, setProductCount] = useState<number>(0); // State variable for product count
    const [userCount, setUserCount] = useState<number>(0); // State variable for user count
    const [orderCount, setOrderCount] = useState<number>(0); // State variable for order count

    useEffect(() => {
        if (authData.roleId === 1) {
            fetchProductCount(); // Fetch product count when component mounts
            fetchUserCount(); // Fetch user count when component mounts
        }
        fetchOrderCount(); // Fetch order count when component mounts
    }, [authData.roleId]);

    const fetchOrderCount = async () => {
        try {
            const orderCount = 10; // Get the length of the reversed data
            setOrderCount(orderCount); // Store the count and the orders
        } catch (error) {
            message.error('Error fetching orders');
        }
    };
    
    const fetchProductCount = async () => {
        try {
            setProductCount(10); // Update product count state with active products only
        } catch (error) {
            message.error("Network error. Please try again.");
        }
    };

    const fetchUserCount = async () => {
        try {
            setUserCount(10); // Update user count state with active users only
        } catch (error) {
            message.error("Network error. Please try again.");
        }
    };

    return (
        <>
            <Row gutter={[24, 24]}>
                
            </Row>
        </>
    );
}

export default Dashboard;
