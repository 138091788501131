import React, { useContext, useEffect, useId, useState } from "react";
import {
  Row,
  Col,
  Card,
  Badge,
  Carousel,
  message,
  notification,
  Progress,
  Button,
} from "antd";
import { FaStar, FaThumbsUp } from "react-icons/fa";


import { BiCheck, BiLeftArrow, BiRightArrow } from "react-icons/bi";
import { VscWorkspaceTrusted } from "react-icons/vsc";
import { EnvironmentOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import {
  BusinessVerified,
  createRazorpayOrder,
  getMyBusiness,
  verifyBusinessPayment,
} from "../../../utils/API";
import { AuthContext } from "../../Auth/AuthContext";
import { MdVerified } from "react-icons/md";
import business from "../Listing/business";

const MyBusinesses = () => {
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [businessData, setBusinessData] = useState<any>([]);
  const { authData } = useContext(AuthContext);
  const imageUrl = process.env.REACT_APP_IMAGE_URL + "/businesse/";
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isVerifiedPaymentId, setisVerifiedPaymentId] = useState<string>("");
  const [transactionIdTrusted, setTransactionIdTrusted] = useState<string>("");
  const [isPaymentdoneVerified, setIsPaymentdoneVerified] = useState(false);
  const [isVeirifiedBusinessPaymentdone, setIsVeirifiedBusinessPaymentdone] = useState(false);
  const [isPaymentdoneTrusted, setIsPaymentdoneTrusted] = useState(false);
  const [isTrustedBusinessPaymentdone, setIsTrustedBusinessPaymentdone] = useState(false);
  const userId = authData?.user?._id;
  const navigate = useNavigate();

  const handleGetBusiness = async () => {
    try {
      const res = await getMyBusiness(userId);
      console.log(res);
      setBusinessData(res);
    } catch (error) {
      console.error("Failed to fetch business data", error);
    }
  };

  useEffect(() => {
    handleGetBusiness();
  }, []);

  const NextArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <BiRightArrow
        className={className}
        style={{
          ...style,
          fontSize: "24px",
          color: "#FFF",
          borderTopLeftRadius: "5px",
          borderBottomLeftRadius: "5px",
          position: "absolute",
          right: "0px",
          top: "50%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          opacity: 0.5,
          transform: "translateY(-50%)",
          zIndex: 2,
        }}
        onClick={onClick}
      />
    );
  };

  const PrevArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <BiLeftArrow
        className={`${className} ${showRightArrow ? "block" : "hidden"}`}
        style={{
          ...style,
          fontSize: "24px",
          color: "#FFF",
          borderTopRightRadius: "5px",
          borderBottomRightRadius: "5px",
          position: "absolute",
          left: "0px",
          top: "50%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          opacity: 0.5,
          transform: "translateY(-50%)",
          zIndex: 2,
        }}
        onClick={onClick}
      />
    );
  };

  const handleButtonClick = (business  : any) => {
    navigate(`/business/${business.slug}`);
  };

  const handleNavigate = (id: any) => {
    navigate(`/business/${id}/edit`);
  };
  

  const handlePaymentVerified = async (businessId: any) => {
    setIsLoading(true);

    const paymentMethod = "Razorpay";

    const orderData = {
      amount: 20 * 100,
      currency: "INR",
      receipt: `receipt_verified_${Date.now()}`,
      userId,
      paymentMethod,
      transactionId: isVerifiedPaymentId,
    };

    try {
      const order = await createRazorpayOrder(orderData);
      const { id: order_id, currency, amount } = order;

      const options = {
        key: process.env.RAZOR_PAY_KEY_ID,
        amount: amount.toString(),
        currency: currency,
        name: "Rapidexie",
        description: "Test Transaction for Verified Business",
        order_id: order_id,
        handler: async function (response: any) {
          const paymentData = {
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
          };

          try {
            const verifyResult = await verifyBusinessPayment(paymentData);
            if (verifyResult === "OK") {
              const updateData = {
                isVerifiedPaymentId: response.razorpay_payment_id,
                isVerifiedPaymentStatus: 1,
                isVerified: 1,
              };
              const updateResponse = await BusinessVerified(businessId, updateData);
              if (updateResponse.status === 200) {
                message.success("Payment for Verified Business successful.");
                handleGetBusiness();
              } else {
                message.error("Failed to update business verification status.");
              }
            } else {
              message.error("Payment for Verified Business failed. Please try again.");
            }
          } catch (error) {
            message.error("Error verifying payment for Verified Business. Please try again.");
          }
        },
        prefill: {
          name: "",
          email: "",
          contact: "9999999999",
        },
        theme: {
          color: "#F37254",
        },
      };

      const rzp = new (window as any).Razorpay(options);
      rzp.open();
      setIsLoading(false);
    } catch (error: any) {
      message.error("Error creating Razorpay order for Verified Business:", error);
      notification.open({
        message: "Error",
        icon: <BiCheck style={{ color: "red" }} />,
        description: "Please Login to continue!",
        placement: "topRight",
        duration: 3,
      });
      setIsLoading(false);
    }
  };

  const handlePaymentTrusted = async (businessId: any) => {
    setIsLoading(true);

    const paymentMethod = "Razorpay";

    const orderData = {
      amount: 30 * 100,
      currency: "INR",
      receipt: `receipt_trusted_${Date.now()}`,
      userId,
      deliveryNotes: "Any notes for delivery",
      paymentMethod,
      transactionId: transactionIdTrusted,
    };

    try {
      const order = await createRazorpayOrder(orderData);
      const { id: order_id, currency, amount } = order;

      const options = {
        key: process.env.RAZOR_PAY_KEY_ID,
        amount: amount.toString(),
        currency: currency,
        name: "Rapidexie",
        description: "Test Transaction for Trusted Business",
        order_id: order_id,
        handler: async function (response: any) {
          const paymentData = {
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
          };

          try {
            const verifyResult = await verifyBusinessPayment(paymentData);
            if (verifyResult === "OK") {
              setIsPaymentdoneTrusted(true);
              const updateData = {
                isTrustedPaymentId: response.razorpay_payment_id,
                isTrustedPaymentStatus: 1,
                isTrusted: 1,
              };
              const updateResponse = await BusinessVerified(businessId, updateData);
              if (updateResponse.status === 200) {
                message.success("Payment for Trusted Business successful.");
                handleGetBusiness();
              } else {
                message.error("Failed to update business verification status.");
              }
            } else {
              message.error("Payment for Trusted Business failed. Please try again.");
            }
          } catch (error) {
            message.error("Error verifying payment for Trusted Business. Please try again.");
          }
        },
        prefill: {
          name: "",
          email: "",
          contact: "9999999999",
        },
        theme: {
          color: "#F37254",
        },
      };

      const rzp = new (window as any).Razorpay(options);
      rzp.open();
      setIsLoading(false);
    } catch (error: any) {
      message.error("Error creating Razorpay order for Trusted Business:", error);
      notification.open({
        message: "Error",
        icon: <BiCheck style={{ color: "red" }} />,
        description: "Please Login to continue!",
        placement: "topRight",
        duration: 3,
      });
      setIsLoading(false);
    }
  };

  const calculateProgress = (images: string | any[]) => {
    const maxImages = 4;
    if (images && images.length > 0) {
     

        return (images.length / maxImages) 
        
        * 100;
      } else {
        return 0; // Default to 0% when no images
      }
    };
  
    return (
      <div className="mt-24">
        {businessData.length === 0 ? (
          <p className="text-center text-gray-600 text-xl font-bold">No businesses listed.</p>
        ) : (
          businessData.map((business: any, index: any) => (
            <Card key={index} className="xl:ml-56 pb-4 xl:mr-56 mt-12">
              <Row gutter={8}>
                <Col
                  xs={24}
                  sm={24}
                  md={7}
                  lg={5}
                  xl={5}
                  className="cursor-pointer"
                  onClick={() => handleNavigate(business._id)}
                >
                  <Carousel
                    dots={false}
                    arrows
                    nextArrow={<NextArrow />}
                    prevArrow={<PrevArrow />}
                    draggable
                    beforeChange={(from, to) => {
                      if (from === 1 && to === 2) {
                        setShowRightArrow(true);
                      }
                    }}
                  >
                    {business.galleryImage.map((image: any, imgIndex: any) => (
                      <img
                        key={imgIndex}
                        className="w-44 h-48 xl:h-48 md:h-60 lg:h-48 rounded-lg"
                        src={`${imageUrl}${image}`}
                        alt={`Carousel ${imgIndex}`}
                      />
                    ))}
                  </Carousel>
                </Col>
                <Col xs={24} sm={18} md={12} lg={14} xl={14} xxl={14}>
                  <div className="flex justify-between items-center md:pt-0 lg:pt-0 xl:pt-0 2xl:pt-0">
                    <div className="flex items-center">
                      <FaThumbsUp className="text-yellow-500" size={20} />
                      <p
                        className="text-black pl-2 text-xl xl:text-xl hover:text-yellow-500 font-bold cursor-pointer"
                        onClick={() => handleNavigate(business._id)}
                      >
                        {business.businessName}
                      </p>
                    </div>
                    <div></div>
                  </div>
                  <div className="flex flex-wrap items-center pt-2">
                    <span className="text-sm rounded-md pl-1 pr-1 tracking-widest bg-yellow-700 text-black">
                      4.6
                    </span>
                    <div className="flex pl-2">
                      {[...Array(5)].map((_, i) => (
                        <FaStar
                          key={i}
                          size={20}
                          className={`pr-0.5 ${i < 4 ? "text-yellow-400" : "text-gray-300"}`} // Placeholder rating
                        />
                      ))}
                    </div>
                    <p className="text-black pl-2 text-sm font-semibold">
                      20 ratings
                    </p>
                    <div className="block sm:inline mt-1">
                      {business.isVerified === 2 && (
                        <Badge className="text-md font rounded-md pl-2 ml-2 pr-1 px-2 py-1 bg-green-300 text-black">
                          Verified
                        </Badge>
                      )}
                      {business.isTrusted === 2 && (
                        <Badge className="text-md font rounded-md pl-2 py-1 ml-2 pr-1 px-2 bg-yellow-200 text-black">
                          Trusted
                        </Badge>
                      )}
                    </div>
                  </div>
  
                  <div className="flex items-center pt-3">
                    <EnvironmentOutlined
                      size={20}
                      className="pr-2 text-yellow-500"
                    />
                    <p>{`${business.buildingName}, ${business.streetName} ,${business.city} , ${business.state} , ${business.pincode}`}</p>
                  </div>
                  <div className="flex items-center pt-3">
                    <Badge className="text-sm rounded-md pl-1 pr-1 px-2 py-1 bg-slate-200 text-gray-800">
                      {business.categoryId[0].name
                        ? business.categoryId[0].name
                        : "Category"}
                    </Badge>
                  </div>
                  <div className="flex items-center flex-wrap pt-3">
  <Col
    xs={24}
    sm={24}
    md={24}
    lg={24}
    xl={24}
    xxl={24}
    className="mt-2 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0"
  >
    <div className="flex justify-between space-x-2">
      {/* Verify Button */}
      <div className="relative w-full">
        <button
          className={`flex items-center font-bold justify-center space-x-2 py-2 rounded-md bg-green-500 text-black w-full ${
            business.isVerified === 1 || business.isVerified === 2
              ? "opacity-50 cursor-not-allowed"
              : ""
          }`}
          onClick={() => handlePaymentVerified(business._id)}
          disabled={business.isVerified === 1 || business.isVerified === 2}
        >
          <MdVerified />
          <span className="text-base">
            {business.isVerified === 1 ? "Applied For Verify" : "Verify"}
          </span>
        </button>
        {business.isVerified === 1 && (
          <div className="text-xs absolute inset-x-0 top-full mt-2 flex justify-center items-center text-yellow-500 font-bold">
            Pending from admin's approval
          </div>
        )}
      </div>

      {/* Trusted Button */}
      <div className="relative w-full">
        <button
          className={`flex items-center justify-center font-bold space-x-2 px-4 py-2 rounded-md bg-yellow-700 text-black w-full ${
            business.isTrusted === 1 || business.isTrusted === 2
              ? "opacity-50 cursor-not-allowed"
              : ""
          }`}
          onClick={() => handlePaymentTrusted(business._id)}
          disabled={business.isTrusted === 1 || business.isTrusted === 2}
        >
          <VscWorkspaceTrusted />
          <span className="text-base">Trusted</span>
        </button>
        {business.isTrusted === 1 && (
          <div className="text-xs absolute inset-x-0 top-full mt-2 flex justify-center items-center text-yellow-500 font-bold">
            Pending from admin's approval
          </div>
        )}
      </div>

      {/* My Business Button */}
      <div className="relative w-full">
      <button
        className="flex items-center justify-center font-bold space-x-2 px-4 py-2 rounded-md bg-yellow-700 text-black w-full"
        onClick={() => handleButtonClick(business)}
      >
        <VscWorkspaceTrusted />
        <span className="text-base">Business</span>
      </button>
    </div>
    </div>
  </Col>
</div>

                </Col>
                
                
                <Col xs={24} sm={4} md={5} lg={5} xl={5} xxl={3} className="p-4 md:pt-0 text-center">
                  <Progress
                    percent={calculateProgress(business.galleryImage)}
                    type="circle"
                  />
                  <Button
                    onClick={() => handleNavigate(business._id)}
                    className={`bg-yellow-700 cursor-pointer mt-4 w-full ml-4 ${
                      calculateProgress(business.galleryImage) === 100 ? "hidden" : ""
                    }`}
                  >
                    Edit
                    
                  </Button>
                  {calculateProgress(business.galleryImage) === 100 && (
                    <Button
                      onClick={() => handleNavigate(business._id)}
                      className="bg-yellow-700 cursor-pointer mt-4 w-full ml-4"
                    >
                        View
                      
    
                    </Button>
                  )}
                </Col>
              </Row>
            </Card>
          ))
        )}
      </div>
    );
  };
  
  export default MyBusinesses;
  