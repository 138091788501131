import { ArrowRightStartOnRectangleIcon } from "@heroicons/react/24/outline";
import { Col, Row } from "antd";
import React from "react";
import image1 from "../../../assets/frontendassets/websiteimages/SECTION5circle.jpg";
import image2 from "../../../assets/frontendassets/websiteimages/SECTION5rectangular.jpg";
import image3 from "../../../assets/frontendassets/websiteimages/SECTION5vertical.jpg";


const GetStartedWithUs = () => {
  return (
    <>
      <div>
        <Row  className="p-4 md:pt-12 md:pb-12 lg:pl-48 lg:pr-48 lg:pt-24 lg:pb-24 xl:pl-48 xl:pr-48 xl:pt-24 xl:pb-24 " >
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="hidden md:block"  >
            <Row justify={"center"}>
              <Col span={12} className="d-flex justify-content-center">
                <img
                  src={image3}
                  className="rounded-xl h-128 w-72"
                  alt=""
                />
              </Col>

              <Col span={12}>
                <div className="animate-jump animate-infinite animate-ease-in animate-delay-100" style={{ backgroundImage: "url(https://gaviaspreview.com/wp/lestin/wp-content/themes/lestin/assets/images/line.png)" , backgroundPosition : "bottom right" , backgroundRepeat : "no-repeat" }}>
                <img
                  src={image2}
                  alt=""
                  className="transform  -skew-x-12 w-5/6 h-48 ml-8 rounded-md"
                />
                </div>
                <div className="rounded-full pt-3 pl-7 w-60 mt-4 bg-yellow-700">
                  <img
                    src={image1}
                    alt=""
                    className="rounded-full"
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="md:pl-12 md:pb-12" >
            <p className="tracking-widest font-bold mb-2 text-yellow">
              WHAT WE OFFER
            </p>
            <p className="text-3xl xs:text-3xl md:text-3xl lg:text-3xl  font-extrabold">
            At India's No.1 B2B and B2C Marketplace, we provide an array of services designed to help your business thrive:
            </p>
            <p className="text-2xl font-bold pt-4 pb-3 text-yellow" >
              Daily coming new listings in our directory
            </p>

              <div className="max-h-72 overflow-y-scroll" >
                
            <Row className="pt-10 pb-6">
              <Col
                xs={24}
                sm={24}
                md={24}
                xl={10} xxl={10}
                className="text-xl font-bold d-flex justify-space-between align-items-center "
              >
             <div>
             <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="w-16 h-16 p-1 bg-yellow-700   rounded-full"
                
                >
                     <path d="M9 12l2 2 4-4" />
                </svg>
             </div>
                 <div>
                 <p className="pl-4 text-xl">
                 Free Business <br className="hidden xl:block xxl:block" /> Listing
                </p>
                 </div>
              </Col>
              <Col span={2} className="border-l-2 border-gray-500 hidden xl:block xxl:block"></Col>
              <Col xs={24}
                sm={24}
                md={24}
                xl={12} xxl={12} className="text-lg text-gray-500 md:pt-5 xl:pt-5 xxl:pt-5">
            List your business for free and get noticed by millions of potential customers.
              </Col>
            </Row>
            <Row className="pt-10 pb-6 w-full">
              <Col
                xs={24}
                sm={24}
                md={24}
                xl={10} xxl={10}
                className="text-xl font-bold d-flex justify-space-between align-items-center "
              >
                 <div>
             <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="w-16 h-16 p-1 bg-yellow-700   rounded-full"
                
                >
                     <path d="M9 12l2 2 4-4" />
                </svg>
             </div>
                 <div>
                 <p className="pl-4 text-xl">
                 Easy Listing <br className="hidden xl:block xxl:block" />Process
                </p>
                 </div>
              </Col>
              <Col span={2} className="border-l-2 border-gray-500 hidden xl:block xxl:block"></Col>
              <Col xs={24}
                sm={24}
                md={24}
                xl={12} xxl={12} className="text-lg text-gray-500 md:pt-5 xl:pt-5 xxl:pt-5">
           Simple listing process & dedicated customer support to guide you every step of the way.
              </Col>
            </Row>
            <Row className="pt-10 pb-6 w-full">
              <Col
                xs={24}
                sm={24}
                md={24}
                xl={10} xxl={10}
                className="text-xl font-bold d-flex justify-space-between align-items-center "
              > <div>
              <svg
                   xmlns="http://www.w3.org/2000/svg"
                   viewBox="0 0 24 24"
                   fill="none"
                   stroke="white"
                   strokeWidth="2"
                   strokeLinecap="round"
                   strokeLinejoin="round"
                   className="w-16 h-16 p-1 bg-yellow-700   rounded-full"
                 
                 >
                      <path d="M9 12l2 2 4-4" />
                 </svg>
              </div>
                  <div>
                  <p className="pl-4 text-xl">
                 Enhanced Business  <br className="hidden xl:block xxl:block" /> Growth
                 </p>
                  </div>
               </Col>
               <Col span={2} className="border-l-2 border-gray-500 hidden xl:block xxl:block"></Col>
               <Col xs={24}
                 sm={24}
                 md={24}
                 xl={12} xxl={12} className="text-lg text-gray-500 md:pt-5 xl:pt-5 xxl:pt-5">
             Connect with new clients, suppliers, and partners to expand your business horizons.
               </Col>
             </Row>
             <Row className="pt-10 pb-6 w-full">
               <Col
                 xs={24}
                 sm={24}
                 md={24}
                 xl={10} xxl={10}
                 className="text-xl font-bold d-flex justify-space-between align-items-center "
               > <div>
               <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="w-16 h-16 p-1 bg-yellow-700   rounded-full"
                  
                  >
                       <path d="M9 12l2 2 4-4" />
                  </svg>
               </div>
                   <div>
                   <p className="pl-4 text-xl">
                   Verified  <br className="hidden xl:block xxl:block" /> Listings
                  </p>
                   </div>
                </Col>
                <Col span={2} className="border-l-2 border-gray-500 hidden xl:block xxl:block"></Col>
                <Col xs={24}
                  sm={24}
                  md={24}
                  xl={12} xxl={12} className="text-lg text-gray-500 md:pt-5 xl:pt-5 xxl:pt-5">
              Display the N4S Trust Seal & N4S verification to reassure customers of your authenticity.
                </Col>
              </Row>
              <Row className="pt-10 pb-6 w-full">
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  xl={10} xxl={10}
                  className="text-xl font-bold d-flex justify-space-between align-items-center "
                > <div>
                <svg
                     xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 24 24"
                     fill="none"
                     stroke="white"
                     strokeWidth="2"
                     strokeLinecap="round"
                     strokeLinejoin="round"
                     className="w-16 h-16 p-1 bg-yellow-700   rounded-full"
                   
                   >
                        <path d="M9 12l2 2 4-4" />
                   </svg>
                </div>
                    <div>
                    <p className="pl-4 text-xl">
                    Comprehensive Marketing  <br className="hidden xl:block xxl:block" /> Tools
                   </p>
                    </div>
                 </Col>
                 <Col span={2} className="border-l-2 border-gray-500 hidden xl:block xxl:block"></Col>
                 <Col xs={24}
                   sm={24}
                   md={24}
                   xl={12} xxl={12} className="text-lg text-gray-500 md:pt-5 xl:pt-5 xxl:pt-5">
               Access powerful marketing tools to promote your business effectively.
                 </Col>
               </Row>
               <Row className="pt-10 pb-6 w-full">
                 <Col
                   xs={24}
                   sm={24}
                   md={24}
                   xl={10} xxl={10}
                   className="text-xl font-bold d-flex justify-space-between align-items-center "
                 >
                
                 <div>
             <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="w-16 h-16 p-1 bg-yellow-700   rounded-full"
                
                >
                     <path d="M9 12l2 2 4-4" />
                </svg>
             </div>
                 <div>
                 <p className="pl-4 text-xl">
                 Global  <br className="hidden xl:block xxl:block" />  Reach
                </p>
                 </div>
              </Col>
              <Col span={2} className="border-l-2 border-gray-500 hidden xl:block xxl:block"></Col>
              <Col xs={24}
                sm={24}
                md={24}
                xl={12} xxl={12} className="text-lg text-gray-500 md:pt-5 xl:pt-5 xxl:pt-5">
       Reach both B2B and B2C audiences across the globe, breaking geographical borders.
              </Col>
            </Row>
            <Row className="pt-10 pb-6 w-full">
              <Col
                xs={24}
                sm={24}
                md={24}
                xl={10} xxl={10}
                className="text-xl font-bold d-flex justify-space-between align-items-center "
              >
               <div>
              
             <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="w-16 h-16 p-1  rounded-full   bg-yellow-700 "
              
                >
                     <path d="M9 12l2 2 4-4" />
                </svg>
             </div>
                 <div>
                 <p className="pl-4 text-xl">
                 Industry Trends  <br className="hidden xl:block xxl:block" /> and Insights
                </p>
                 </div>
              </Col>
              <Col span={2} className="border-l-2 border-gray-500 hidden xl:block xxl:block"></Col>
              <Col    xs={24}
                sm={24}
                md={24}
                xl={12} xxl={12} className="text-lg text-gray-500 md:pt-5 xl:pt-5 xxl:pt-5">
              Stay ahead of the curve with the latest industry trends and market insights.
              </Col>
            </Row>

</div>
            <Col xs={24} sm={24} md={24} xl={24} xxl={24} className="p-2  md:pt-5 xl:pt-5 xxl:pt-5">
              <button className=" font-bold btn p-3 rounded-md  text-black bg-yellow-700" >
                Get Started with Us
              </button>
            </Col>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default GetStartedWithUs;
