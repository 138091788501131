import React, { useState, useEffect, useRef } from 'react';
import { Card, Col, Modal, Row, Spin, Switch, Table, message, Image, InputRef, Button, Radio, RadioChangeEvent } from 'antd';
import { useNavigate } from 'react-router-dom';
import { deletebusiness, BusinessVerify, BusinessTrusted, updateBusinessStatus, getnet4surgeBusiness, getAllPackages, Userplan } from '../../../../utils/API';
import { ShoppingCartOutlined } from '@ant-design/icons';
const daysInMonth = 30; // Number of days in a month
interface Customer {
    _id: number;
    id: number;
    businessName: string;
    area: string;
    email: string;
    mobileNumber: string;
    isVerified: boolean;
    galleryImage: string[];
}

const Businesslist: React.FC = () => {
    const [customerData, setCustomerData] = useState<Customer[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [isFeatured, setIsFeatured] = useState(false);
    const [isHome, setIsHome] = useState(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [searchText, setSearchText] = useState<string>('');
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [packageData, setPackageData] = useState<any>([]);
    const [selectedPackage, setSelectedPackage] = useState<string | null>(null);
    const [calculatedPlanAmount, setCalculatedPlanAmount] = useState<number>(0);
    const [selectedDuration, setSelectedDuration] = useState<number | null>(null);
    const [selectedBusinessId, setSelectedBusinessId] = useState<any>({});
    const [userId, setuserId] = useState();
    const imageUrl = process.env.REACT_APP_IMAGE_URL + '/businesse/';
    const navigate = useNavigate();

    const FetchCustomerData = (page: number) => {
        setLoading(true);
        getnet4surgeBusiness(page, "", "")
            .then((response: any) => {
                const filteredCustomerData = response.data.filter((customer: any) => !customer.isDeleted);
                setCustomerData(filteredCustomerData);
                setTotalPages(response.totalPages);
                setLoading(false);
            })
            .catch((error: any) => {
                message.error('Network error. Please try again.');
                setLoading(false);
            });
    };
    const fetchPackageData = async () => {
        try {
            const data = await getAllPackages();

            setPackageData(data);
        } catch (error) {
            message.error("Something went wrong");
        }
    };
    useEffect(() => {
        fetchPackageData();  // This effect will run only once when the component mounts
    }, []);

    useEffect(() => {
        FetchCustomerData(currentPage);
    }, [currentPage]);

    const showDeleteConfirmation = (id: number) => {
        Modal.confirm({
            title: 'Confirm Deletion',
            content: 'Are you sure you want to delete this customer?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: () => handleDeleteConfirmation(id, 1),
            onCancel: () => handleDeleteConfirmation(id, 0),
        });
    };

    const handleDeleteConfirmation = (id: number, flag: number) => {
        if (flag === 1) {
            deletebusiness(id).then(() => {
                message.success('Customer status updated successfully');
                FetchCustomerData(currentPage);
            });
        }
    };

    const handleSearch = (value: string) => {
        setSearchText(value);
    };

    const handleEditRedirect = (record: Customer) => {
        navigate(`/backend/users/${record._id}/edit`);
    };

    const BusinessVerifyd = (record: Customer) => {
        BusinessVerify(record._id)
            .then((response: any) => {
                if (response.status === 200) {
                    message.success(response.data.message);
                    FetchCustomerData(currentPage);
                } else {
                    message.error("Something went wrong");
                }
            })
            .catch((error: any) => {
                message.error(error.response.data.message);
            });
    };

    const Businesstrusted = (record: Customer) => {
        BusinessTrusted(record._id)
            .then((response: any) => {
                if (response.status === 200) {
                    message.success(response.data.message);
                    FetchCustomerData(currentPage);
                } else {
                    message.error("Something went wrong");
                }
            })
            .catch((error: any) => {
                message.error(error.response.data.message);
            });
    };

    const updateIsHome = (record: Customer, checked: boolean) => {
        updateBusinessStatus(record._id, { isHome: checked ? 1 : 0 })
            .then((response: any) => {
                if (response.status === 200) {
                    message.success("Status updated successfully");
                    FetchCustomerData(currentPage);
                } else {
                    message.error("Something went wrong");
                }
            })
            .catch((error: any) => {
                message.error(error.response.data.message);
            });
    };

    const updateIsFeatured = (record: Customer, checked: boolean) => {
        updateBusinessStatus(record._id, { isFeatured: checked ? 1 : 0 })
            .then((response: any) => {
                if (response.status === 200) {
                    message.success("Status updated successfully");
                    FetchCustomerData(currentPage);
                } else {
                    message.error("Something went wrong");
                }
            })
            .catch((error: any) => {
                message.error(error.response.data.message);
            });
    };
    const handleIconClick = (record: any) => {
        setSelectedBusinessId(record._id); // Assuming you have a state variable for this
        setuserId(record.userId._id);
        if (Array.isArray(record.packageID) && record.packageID.length > 0 && record.packageID[0]._id) {
            setSelectedPackage(record.packageID[0]._id); // Pre-select the purchased package in the modal
            setSelectedDuration(record.month); // Pre-set the plan amount based on the package
        } else {
            // Handle the case where there is no package or packageID is empty
            setSelectedPackage(null); // Or some default value
            setCalculatedPlanAmount(0); // Reset or set a default plan amount
        }
        setIsModalVisible(true);
    };

    const handleModalClose = () => {
        setSelectedBusinessId(null);
        setSelectedPackage(null);
        setCalculatedPlanAmount(0);
        setSelectedDuration(null);
        setIsModalVisible(false);
    };

    const handlePackageSelect = (e: RadioChangeEvent) => {
        const selectedPackageId = e.target.value;
        const selectedPackageData = packageData.find((pkg: { _id: any; }) => pkg._id === selectedPackageId);

        if (selectedPackageData) {
            setSelectedPackage(selectedPackageId);
            setCalculatedPlanAmount(selectedPackageData.price); // Set the plan amount based on the selected package
        }
    };
    const handleDurationSelect = (e: any) => {
        setSelectedDuration(e.target.value);
    };

    const handleConfirmSelection = async () => {
        if (selectedPackage && selectedDuration) {
            // Calculate totalAmount based on planAmount and selectedDuration
            const calculatedTotalAmount = calculatedPlanAmount * (selectedDuration * daysInMonth);

            // Construct the userPlanData object
            const userPlanData = {
                businessId: selectedBusinessId,  // Replace this with the actual business_id
                userId: userId,          // Replace this with the actual userId
                packageID: selectedPackage,      // This will be the selected package
                isActive: 1,                     // Default value is 1
                totalAmount: calculatedTotalAmount,  // Use calculated total amount
                planAmount: calculatedPlanAmount,    // Use plan amount for 1 month
                month: selectedDuration          // This will be the selected duration (month)
            };

            try {
                const response = await Userplan(userPlanData);
                if (response.status === 200) {
                    message.success(response.data.message);
                    handleModalClose();
                    FetchCustomerData(currentPage);
                } else {
                    message.error("Unexpected response status: " + response.status);
                }
            } catch (error) {
                message.error('An error occurred while submitting the package selection');
            }
        } else {
            message.error('Please select a package and duration');
        }
    };



    const columns = [
        {
            title: (
                <div style={{ fontWeight: "bold", fontSize: "10px", color: "#a6a6a6" }}>
                    Images
                </div>
            ),
            dataIndex: "galleryImage",
            key: "galleryImage",
            render: (galleryImage: string[]) => (
                <div>
                    {galleryImage && galleryImage.length > 0 && (
                        <Image
                            src={`${imageUrl}${galleryImage[0]}`} // Adjust path as needed
                            alt={`Thumbnail 1`}
                            className='rounded-md'
                            style={{ width: "80px", height: "60px", marginRight: "5px" }}
                        />
                    )}
                </div>
            ),
        },
        {
            title: <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>Name</div>,
            dataIndex: 'businessName',
            key: 'businessName',
        },
        {
            title: <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>Location</div>,
            dataIndex: 'city',
            key: 'city',
            render: (text: any, record: any) => {
                return <div>{record.city} , {record.state}</div>;
            }
        },

        {
            title: <div style={{ fontWeight: 'bold', fontSize: "10px", color: "#a6a6a6" }}>Contact</div>,
            dataIndex: 'mobileNumber',
            key: 'mobileNumber',
        },
        {
            title: <div style={{ fontWeight: 'bold', fontSize: '10px', color: '#a6a6a6' }}>Is Home</div>,
            dataIndex: 'isHome',
            key: 'isHome',
            render: (isHome: boolean, record: any) => {
                return (
                    <Switch
                        checked={isHome}
                        style={{ marginRight: '10px', backgroundColor: isHome ? 'rgb(251, 202, 37)' : 'gray' }}

                        onChange={(checked) => updateIsHome(record, checked)}
                    />
                );
            },
        },
        {
            title: <div style={{ fontWeight: 'bold', fontSize: '10px', color: '#a6a6a6' }}>Is Featured</div>,
            dataIndex: 'isFeatured',
            key: 'isFeatured',
            render: (isFeatured: boolean, record: any) => {
                return (
                    <Switch
                        checked={isFeatured}
                        style={{ marginRight: '10px', backgroundColor: isFeatured ? 'rgb(251, 202, 37)' : 'gray' }}
                        onChange={(checked) => updateIsFeatured(record, checked)}
                    />
                );
            },
        },
        {
            title: <div style={{ fontWeight: 'bold', fontSize: '10px', color: '#a6a6a6' }}>Verified</div>,
            dataIndex: 'isVerified',
            key: 'isVerified',
            render: (isVerified: any, record: any) => {
                if (isVerified === 0) {
                    return (
                        <>
                            Not Applied
                        </>
                    )
                }
                return (
                    <Switch
                        checked={isVerified === 2}
                        disabled={isVerified === 2}
                        style={{ marginRight: '10px', backgroundColor: isVerified === 2 ? 'rgb(251, 202, 37)' : 'gray' }}
                        onChange={() => {
                            BusinessVerifyd(record);
                        }}
                    />
                );
            },
            sorter: (a: any, b: any) => a.isVerified - b.isVerified,
            filters: [
                { text: 'Verified', value: 2 },
                { text: 'Not Verified', value: 0 },
                { text: 'Pending', value: 1 }
            ],
            onFilter: (value: any, record: any) => record.isVerified === value,
        },
        {
            title: <div style={{ fontWeight: 'bold', fontSize: '10px', color: '#a6a6a6' }}>Trusted</div>,
            dataIndex: 'isTrusted',
            key: 'isTrusted',
            render: (isTrusted: any, record: any) => {
                if (isTrusted === 0) {
                    return (
                        <>
                            Not Applied
                        </>
                    )
                }
                return (
                    <Switch
                        checked={isTrusted === 2}
                        disabled={isTrusted === 2}
                        style={{ marginRight: '10px', backgroundColor: isTrusted === 2 ? 'rgb(251, 202, 37)' : 'gray' }}
                        onChange={() => {
                            Businesstrusted(record);
                        }}
                    />
                );
            },
            sorter: (a: any, b: any) => a.isTrusted - b.isTrusted,
            filters: [
                { text: 'Trusted', value: 2 },
                { text: 'Not Trusted', value: 1 }
            ],
            onFilter: (value: any, record: any) => record.isTrusted === value,
        },
        {
            title: (
                <div style={{ fontWeight: "bold", fontSize: "10px", color: "#a6a6a6" }}>
                    Plan
                </div>
            ),
            dataIndex: 'businessId', // Use a more relevant field like 'businessId'
            key: 'businessId',
            render: (text: any, record: any) => {
                const isSubscribed = record.isSubscibe === 1;
                
                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                            color: '#FFC107',
                        }}
                        onClick={() => handleIconClick(record)} // Pass the entire record for context
                    >
                        <ShoppingCartOutlined style={{ fontSize: '18px', marginRight: '8px' }} />
                        <span style={{ fontWeight: '500' }}>
                            {isSubscribed ? 'Upgrade' : 'Buy Plan'}
                        </span>
                    </div>
                );
            },
        }        
    ];

    return (
        <>
            <Row className="m-2" align={"middle"}>
                <Col
                    xs={24}
                    sm={24}
                    md={9}
                    xl={6}
                    xxl={6}
                    className="d-flex justify-content-start font-bold"
                >
                    <h2 className="text-2xl">Businesses</h2>
                </Col>
                <Col xs={24} sm={24} md={24} xl={18} xxl={18} className="">
                </Col>
            </Row>
            <Row>
                <Col
                    style={{ backgroundColor: "#ffffff", borderRadius: "12px" }}
                    xs={24}
                    sm={24}
                    md={24}
                    xl={24}
                    xxl={24}
                >
                    <Card className="bg-white border " style={{ borderRadius: "12px" }}>
                        <Col xs={24} sm={24} md={24} xl={24} xxl={24}>
                            <Spin spinning={loading}>
                                <div style={{ overflowX: 'auto' }}>
                                    <Table
                                        columns={columns}
                                        dataSource={customerData}
                                        scroll={{ x: true }}
                                        pagination={{
                                            current: currentPage,
                                            total: totalPages * 7,
                                            onChange: (page) => setCurrentPage(page),
                                        }}
                                    />
                                </div>
                            </Spin>
                        </Col>
                    </Card>
                </Col>
            </Row>
            <Modal
                title={<h2 className="text-lg font-semibold">Plan Information</h2>}
                visible={isModalVisible}
                onCancel={handleModalClose}
                footer={[
                    <Button key="cancel" onClick={handleModalClose} className="mr-2">
                        Cancel
                    </Button>,
                    <Button
                        key="confirm"
                        type="primary"
                        onClick={handleConfirmSelection}
                        className="bg-yellow-500 hover:bg-yellow-600 text-white"
                    >
                        Submit
                    </Button>
                ]}
                className="p-4 rounded-lg"
            >
                <p className="font-semibold mb-4">Choose The Plan</p>
                <Radio.Group
                    onChange={handlePackageSelect}
                    value={selectedPackage}
                    className="flex flex-col mb-4 space-y-2"
                >
                    {packageData.map((pkg: any) => (
                        <Radio
                            key={pkg._id}
                            value={pkg._id}  // Use the package _id as the value
                            className="p-2 border border-yellow-200 rounded-lg hover:border-yellow-500"
                        >
                            <span className="font-medium">{pkg.packageName}</span>: {pkg.price} Rs. / {pkg.duraction}
                        </Radio>
                    ))}
                </Radio.Group>

                <p className="font-semibold mb-4">Select duration</p>
                <Radio.Group
                    onChange={handleDurationSelect}
                    value={selectedDuration}
                    className="flex flex-col space-y-2"
                >
                    <Radio
                        value={1}
                        className="p-2 border border-gray-200 rounded-lg hover:border-yellow-500"
                    >
                        1 Month
                    </Radio>
                    <Radio
                        value={3}
                        className="p-2 border border-gray-200 rounded-lg hover:border-yellow-500"
                    >
                        3 Months
                    </Radio>
                    <Radio
                        value={6}
                        className="p-2 border border-gray-200 rounded-lg hover:border-yellow-500"
                    >
                        6 Months
                    </Radio>
                    <Radio
                        value={12}
                        className="p-2 border border-gray-200 rounded-lg hover:border-yellow-500"
                    >
                        12 Months
                    </Radio>
                </Radio.Group>
            </Modal>
        </>
    );
};

export default Businesslist;