import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { message } from 'antd';
import { domainfind } from "../../utils/API";
import product1 from '../../assets/product/businessmanhold.jpg';
import product2 from '../../assets/product/stocking.jpg';
import product3 from '../../assets/product/pexels-photo-265087.jpeg';
import { useNavigate } from 'react-router-dom';

// Define types for domain data
interface DomainData {
  header: {
    title: string;
    description: string;
    buttonText: string;
  };
  section: {
    title: string;
    description: string;
  };
}

const fetchdomain = async (domainName: string, setDomainData: React.Dispatch<React.SetStateAction<DomainData | null>>, navigate: ReturnType<typeof useNavigate>) => {
  try {
    const response = await domainfind({ domainName });
    setDomainData(response.data); // Store the fetched data in state
  } catch (error: any) {
    message.error(error?.response?.data?.message || 'An error occurred while fetching data');
    navigate("/"); // Use navigate function for redirection
  }
};

const HeadingSection = ({ domainData }: { domainData: DomainData | null }) => (
  <section className="relative bg-cover bg-center h-screen" style={{ backgroundImage: `url(${product2})` }}>
    <div className="absolute inset-0 bg-black bg-opacity-50"></div>
    <div className="relative container mx-auto text-center text-white flex flex-col items-center justify-center h-full">
      <h1 className="text-4xl font-bold mb-4">{domainData?.header?.title || 'Loading...'}</h1>
      <p className="text-lg mb-8">{domainData?.header?.description || 'Loading...'}</p>
      <button className="bg-yellow-500 text-black px-6 py-3 rounded">{domainData?.header?.buttonText || 'Loading...'}</button>
    </div>
  </section>
);

const AboutSection = ({ domainData }: { domainData: DomainData | null }) => (
  <section className="relative bg-cover bg-center py-16" style={{ backgroundImage: `url(${product1})` }}>
    <div className="absolute inset-0 bg-black bg-opacity-50"></div>
    <div className="relative container mx-auto text-center text-white">
      <h2 className="text-3xl font-bold mb-4">{domainData?.section?.title || 'Loading...'}</h2>
      <p className="text-lg mb-8">{domainData?.section?.description || 'Loading...'}</p>
    </div>
  </section>
);

const ProductsSection = () => {
  const products = [
    { id: 1, name: 'Product 1', description: 'Description for product 1', imageUrl: product1 },
    { id: 2, name: 'Product 2', description: 'Description for product 2', imageUrl: product2 },
    { id: 3, name: 'Product 3', description: 'Description for product 3', imageUrl: product3 },
    // Add more products as needed
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="bg-gray-900 text-white py-16">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-8">Our Products</h2>
        <Slider {...settings}>
          {products.map(product => (
            <div key={product.id} className="p-4">
              <div className="bg-gray-800 p-6 rounded">
                <img src={product.imageUrl} alt={product.name} className="w-full h-40 object-cover rounded mb-4" />
                <h3 className="text-xl font-bold mb-2">{product.name}</h3>
                <p>{product.description}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

const Template = () => {
  const [domainData, setDomainData] = useState<DomainData | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const pathArray = window.location.pathname.split('/');
    const domain = pathArray[pathArray.length - 1];
    fetchdomain(domain, setDomainData, navigate);
  }, [navigate]);

  return (
    <div>
      <HeadingSection domainData={domainData} />
      <AboutSection domainData={domainData} />
      <ProductsSection />
    </div>
  );
};

export default Template;
