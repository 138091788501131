import React, { useState } from "react";
import { Row, Col, Form, Input, Select, Dropdown, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { BiSearch } from "react-icons/bi";
import { FaLocationArrow, FaPlane, FaShoppingBag } from "react-icons/fa";
// import image1 from "../../../assets/frontendassets/NET4SURGE/First Section/firstsection1.jpg";
import image2 from "../../../assets/frontendassets/NET4SURGE/First Section/firstsection2.jpg";
import image3 from "../../../assets/frontendassets/NET4SURGE/First Section/firstsection3.jpg";
import image1 from "../../../assets/frontendassets/websiteimages/SECTION2png.png";
import HomeBanner from "./HomeBanner";
import WebsiteSeo from "../Home/WebsiteSeo"
const { Option } = Select;

const App: React.FC = () => {
  const [borderStyle, setBorderStyle] = useState<string>("none");
  const logo = require("../../../assets/backendassets/Listing.png");
  const placeholderStyle = {
    fontSize: "15px",
  padding: "5px", 
  justifyContent : "center",
    color: "#C0C0C0", // Lighter color for placeholder
    fontWeight: "lighter",
  };

  const items: MenuProps["items"] = [
    {
      label: <a href="https://www.antgroup.com">1st menu item</a>,
      key: "0",
    },
    {
      type: "divider",
    },
    {
      label: <a href="https://www.aliyun.com">2nd menu item</a>,
      key: "1",
    },
    {
      type: "divider",
    },
    {
      label: "3rd menu item",
      key: "3",
    },
  ];
  return (
    <>
      <style>
        {`@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@800&display=swap');`}
  
      </style>
  <HomeBanner />

   
      <div style={{
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    }} 
    className="bg-yellow-700"
    >
   <div>
   <Row className=' pt-8 pb-12 '  align={"middle"} >
          <Col xs={24} sm={24} md={6} lg={12} xl={12} xxl={12} style={{ marginTop: "-45px" }} className=" d-flex justify-center">
            <img src={image1}  className="w-80 h-80" />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className='p-2 md:p-0'>
            <p className=' text-3xl md:text-3xl lg:text-3xl xl:text-5xl text-white font-bold mb-3 xl:justify-start d-flex md:justify-center sm:justify-center w-full'>
            Let’s find anything around you
            </p>
            <p className='text-md md:text-md lg:text-md xl:text-md text-white font-bold mb-3 d-flex xl:justify-start md:justify-center sm:justify-center w-full'>
            Our commitment  is to provide solutions that transcend geographical boundaries.
            </p>
           <div className="w-full d-flex md:justify-center sm:justify-center ">
             <button className='btn btn-lg bg-black text-white font-bold text-xs px-4 ps-4 pt-3 pb-3 d-flex md:m-auto xl:ml-0 '>Discover More</button>
           </div>
          </Col> 
        </Row>
   </div>
    </div>
    </>
  );
};

export default App;
