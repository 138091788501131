import { Card, Col, Form, Input, Row, Select, message, Button, Spin } from 'antd';
import React, { useState, useEffect } from 'react';
import { BiPlus } from 'react-icons/bi';
import { FaArrowLeft } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import { editPackage, updatePackage } from '../../../utils/API';

const { Option } = Select;

const Edit: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [description, setDescription] = useState(['']);
  const [loading, setLoading] = useState(false); // Add loading state
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchPackage = async () => {
      try {
        const response = await editPackage(id);
        const packageData = response;
        setDescription(packageData.descriptions);
        form.setFieldsValue({
          _id: packageData._id,
          packageName: packageData.packageName,
          price: packageData.price,
          duraction: packageData.duraction,
        });
      } catch (error) {
        message.error('Failed to fetch package');
      }
    };

    fetchPackage();
  }, [id, form]);

  const handleAddDescription = () => {
    setDescription([...description, '']);
  };

  const handleFinish = async (values: any) => {
    const id = form.getFieldValue("_id");
    setLoading(true); // Set loading state to true

    try {
      const response = await updatePackage(id, values);
      if (response) {
        message.success('Package updated successfully');
        window.history.back();
      }
    } catch (error) {
      message.error('Failed to update package');
    } finally {
      setLoading(false); // Set loading state to false
    }
  };

  const pricingOptions = [
    'day',
    'week',
    'month',
    'year',
  ];

  return (
    <>
      <div>
        <Row className="m-2" align="middle">
          <Col span={10}>
            <h2 className="text-2xl font-bold">Edit Package</h2>
          </Col>
          <Col span={12}></Col>
          <Col span={2} style={{ textAlign: 'right' }}>
            <Link to="/backend/packages">
              <Button className="d-flex justify-content-center bg-yellow-700 text-black btn align-items-center w-100 border btn-brand">
                <FaArrowLeft className="me-2" />
                <div className="ms-2">Back</div>
              </Button>
            </Link>
          </Col>
        </Row>
        <Form form={form} className="bg-white" onFinish={handleFinish}>
          <Card style={{ backgroundColor: '#ffffff' }}>
            <Row className="border border-warning-2 p-5 bg-white rounded-md" style={{ marginLeft: 0, marginRight: 0 }}>
              <Col span={24}>
                <Row className="bg-white">
                  <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5">
                    <label className="font-bold">
                      Package Name <span className="text-danger">*</span>
                    </label>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="packageName" rules={[{ required: true, message: 'Please Enter package name' }]}>
                      <Input size="large" className="rounded border border-1" placeholder="Package Name" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row className="bg-white">
                  <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5">
                    <label className="font-bold">
                      Price <span className="text-danger">*</span>
                    </label>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="price" rules={[{ required: true, message: 'Please Enter Price' }]}>
                      <Input size="large" className="rounded border border-1" placeholder="Price" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col span={24}>
                <Row className="bg-white">
                  <Col span={4} className="d-flex justify-content-start me-4 bg-white mb-5">
                    <label className="font-bold">
                      Duration <span className="text-danger">*</span>
                    </label>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="duraction" rules={[{ required: true, message: 'Please select a duration' }]}>
                      <Select size="large" className="rounded border border-1" placeholder="Select duration">
                        {pricingOptions.map(option => (
                          <Option key={option} value={option}>
                            {option}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              
              <Col span={24} className="d-flex justify-content-center">
              <Button className={'bg-yellow-700 text-black btn align-items-center  border btn-brand'} type="primary" size="large" htmlType="submit" loading={loading} icon={loading ? <Spin /> : undefined}>
        {
          loading ? 'Updating...' : 'Update Package'
        }
      </Button>
              </Col>
            </Row>
          </Card>
        </Form>
      </div>
    </>
  );
};

export default Edit;
