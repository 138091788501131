import React, { useEffect, useState } from 'react';
import { Image, Table } from 'antd';

const FavouriteBusinesses: React.FC = () => {
  const [wishlist, setWishlist] = useState<any[]>([]);

  useEffect(() => {
    const savedWishlist = JSON.parse(localStorage.getItem('wishlist') || '[]');
    setWishlist(savedWishlist);
  }, []);

  const imageUrl = process.env.REACT_APP_IMAGE_URL + '/businesse/';

  const columns = [
    {
      title: (
        <div style={{ fontWeight: "bold", fontSize: "10px", color: "#a6a6a6" }}>
          Images
        </div>
      ),
      dataIndex: 'galleryImage',
      key: 'galleryImage',
      render: (galleryImage: string[]) => (
        <div>
          {galleryImage && galleryImage.length > 0 && (
            <Image
              src={`${imageUrl}${galleryImage[0]}`}
              alt="Main Gallery"
              className="rounded-md"
              style={{ width: "80px", height: "60px", marginRight: "5px" }}
            />
          )}
        </div>
      ),
    },
    {
      title: (
        <div style={{ fontWeight: "bold", fontSize: "10px", color: "#a6a6a6" }}>
          Business Name
        </div>
      ),
      dataIndex: 'businessName',
      key: 'businessName',
    },
    {
      title: (
        <div style={{ fontWeight: "bold", fontSize: "10px", color: "#a6a6a6" }}>
          Contact Person
        </div>
      ),
      dataIndex: 'contactPersonName',
      key: 'contactPersonName',
    },
    {
      title: (
        <div style={{ fontWeight: "bold", fontSize: "10px", color: "#a6a6a6" }}>
          Mobile Number
        </div>
      ),
      dataIndex: 'mobileNumber',
      key: 'mobileNumber',
    },
    {
      title: (
        <div style={{ fontWeight: "bold", fontSize: "10px", color: "#a6a6a6" }}>
          Email
        </div>
      ),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: (
        <div style={{ fontWeight: "bold", fontSize: "10px", color: "#a6a6a6" }}>
          Address
        </div>
      ),
      dataIndex: 'address',
      key: 'address',
      render: (text: any, record: any) => (
        <div>{record.streetName}, {record.city}, {record.state} - {record.pincode}</div>
      ),
    },
  ];

  return (
    <div className="overflow-x-auto">
      <div className="m-2 flex flex-wrap items-center">
        <div className="w-full md:w-9/12 xl:w-1/4 flex justify-start font-bold pb-2">
          <h2 className="text-2xl">Favourite Businesses</h2>
        </div>
        <div className="w-full md:w-full xl:w-3/4"></div>
      </div>
      <Table
        columns={columns}
        dataSource={wishlist}
        rowKey="id"
        pagination={false}
        locale={{
          emptyText: 'No favourite businesses found.',
        }}
        className="bg-white border border-gray-200 rounded-lg shadow-md"
      />
    </div>
  );
};

export default FavouriteBusinesses;
